export const optionsModel={
    amountActMonth: 0,
    amountCurrencyActMonth: 0,
    amountCurrencyLastMonth: 0,
    amountLastMonth: 0,
    averageActMonth: 0,
    averageCurrencyActMonth: 0,
    averageCurrencyLastMonth: 0,
    averageLastMonth: 0,
    totalItemsActMonth: 0,
    totalItemsLastMonth: 0,
    totalTransActMonth: 0,
    totalTransLastMonth: 0,

}