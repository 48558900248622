import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Paper,
} from "@mui/material/"
import Loader from '../loader/Loader';
import TaTypography from '../typography/TaTypography';
import { makeStyles } from '@mui/styles';

const dialogStyles = makeStyles({
    title: {
        margin: "5px",
        padding: "10px !important",
        backgroundColor: 'var(--main-bg-color1)',
        textAlign: 'center'
    },
    content: {
        margin: props => (props.noStyles) || (props.fullScreen) ? '0px' : "5px",
        padding: props => (props.noStyles) || (props.fullScreen) ? '0px' : "10px !important",
        overflowX: "hidden !important",
    },
    actions: {
        margin: "5px",
        padding: "10px !important",
    },
})

const TaDialog = memo(({ isOpen, onClose, fullWidth, maxWidth, title, content, actions, loading,
    elevation, showLoader, fullScreen, noStyles, onEntered }) => {

    const classes = dialogStyles({ fullScreen, noStyles })

    const fnOnEntered = (element, isAppearing) => isAppearing && onEntered && onEntered()

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth={fullWidth && fullWidth}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            TransitionProps={{
                onEntered: fnOnEntered
            }}
            PaperProps={
                {
                    elevation: elevation,
                    style: {
                        backgroundColor: 'var(--main-bg-color0)',

                    }
                }
            }>
            {title && <DialogTitle className={classes.title}>{<TaTypography color={'var(--main-text-color1)'} isTitle text={title} />}</DialogTitle>}
            {content && <DialogContent className={classes.content}>{content}</DialogContent>}
            {actions && <DialogActions className={classes.actions}>{actions}</DialogActions>}
            {showLoader && <Paper elevation={0} style={{ height: "10px" }}><Loader isLoading={loading} /></Paper>}
        </Dialog>
    )
})

TaDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    fullWidth: PropTypes.bool,
    fullScreen: PropTypes.bool,
    maxWidth: PropTypes.string,
    title: PropTypes.any,
    content: PropTypes.any,
    actions: PropTypes.any,
    elevation: PropTypes.number,
    isTransparent: PropTypes.bool,
    showLoader: PropTypes.bool,
    noStyles: PropTypes.bool,
};

TaDialog.defaultProps = {
    isOpen: false,
    fullWidth: true,
    maxWidth: "sm",
    elevation: 2,
    isTransparent: false,
    showLoader: true,
    noStyles: false
};

export default TaDialog