import { ListItem } from '@mui/material'
import { listItemStyles } from './styles/ListStyles'
import { memo } from 'react'
import PropTypes from 'prop-types'

const TaListItem = memo(({ children, x, onClickItem, selected, height, orderList = false }) => {
    const classes = listItemStyles({ height, orderList })
    const onClick = (x) => onClickItem(x)
    return (
        <ListItem
            classes={{ root: classes.root, selected: classes.selected }}
            selected={selected}
            onClick={onClickItem ? () => onClick(x) : undefined}>
            {children}
        </ListItem >
    )
})

TaListItem.propTypes = {
    children: PropTypes.any.isRequired,
    x: PropTypes.object,
    onClickItem: PropTypes.func,
    selected: PropTypes.bool,
};

TaListItem.defaultProps = {
    selected: false,
};

export default TaListItem