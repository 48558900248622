import { Grid, Paper } from "@mui/material"
import useWindowSize from "helper/UseWindowsSize"
import TAWhite from "projectImages/PNGBlanco/TAWhite.png"


const footerStyles = (height) => ({
    height: '90px',
    backgroundColor: 'var(--main-bg-color1)',
    overflow: 'hidden',
    padding: '0px'
})

const FooterBar = () => {

    const { height, isMobile } = useWindowSize()
    const style = footerStyles(height)


    return (
        <Paper elevation={0} sx={{ height: '90px', backgroundColor: 'var(--main-bg-color1)', overflow: 'hidden', padding: '0px' }} >
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ paddingTop: '12px' }}>
                <Grid align='center' item xs={12} sm={12} md={12} lg={12} lx={12} >
                    <img src={TAWhite} alt='' style={{ width: !isMobile ? '220px' : '175px', height: '100%' }} />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default FooterBar
