import TaButton from "components/button/TaButton"
import { useEffect, useState } from "react"
import { getCurrencyList } from "./ApiPriceButton"

let nextCurrency, nextIndex, limit

const usePriceButton = () => {
    const [currency, setCurrency] = useState()
    const [selectedCurrency, setSelectedCurrency] = useState()

    const fnOnClick = () => {
        nextIndex = (currency.findIndex(x => x.id === selectedCurrency.id) + 1)
        limit = currency.length
        nextCurrency = (nextIndex > -1 && nextIndex < limit) ? currency[nextIndex] : currency[0]
        setSelectedCurrency(nextCurrency)
    }
    const fnGetCurrencyList = async () => {
        try {
            const response = await getCurrencyList()
            if (response.status === 200 && response.data.errorCode === 0) {
                setCurrency(response.data.data)
                setSelectedCurrency(response.data.data.find(x => x.defaultCurrency === true))
            }
        } catch (error) { console.error('cath boton de Monedas', error) }
    }
    useEffect(() => {
        fnGetCurrencyList()
    }, [])

    const PriceButton = () => {
        return <TaButton
            id='btnMultiCurrency'
            size='small'
            topMargin={false}
            minWidth={true}
            label={selectedCurrency?.code ?? ''}
            onClick={fnOnClick}
        />
    }

    return {
        PriceButton,
        selectedCurrency,
    }
}

export default usePriceButton
