import moment from 'moment'
import { transactionLanguages } from './TansactionsLanguages'

const { typeTransactionsLang } = transactionLanguages

export const typeTransactionsModel = (languageState) => [
    {
        id: 'Todos',
        name: typeTransactionsLang[0][languageState]
    },
    {
        id: 'Pago',
        name: typeTransactionsLang[1][languageState]
    },
    {
        id: 'RetIva',
        name: typeTransactionsLang[2][languageState]
    },
    {
        id: 'ISLR',
        name: typeTransactionsLang[3][languageState]
    },
]

export const dataFilterModel = {
    type: 'Todos',
    numDoc: '',
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),

}