import { Card, Grid } from '@mui/material'
import useWindowSize from 'helper/UseWindowsSize';
import TaTypography from 'components/typography/TaTypography';
import { iconType } from './Icons';
import { fnFormatNumb } from 'helper/FormatNumb';

const TaCard = ({ backgroundColor, icon, footerText, currentDate, previousDate }) => {
    const { height } = useWindowSize()
    const icons = iconType(icon, height)

    return (
        <Card sx={{ height: height === 1080 ? '200px' : '128px', backgroundColor: backgroundColor, display: 'flex', paddingLeft:'3px' }}>
            <Grid container justifyContent="flex-start" alignItems='flex-start'>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align={'center'}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems='flex-start' sx={{ padding: '10px 5px 5px 0px' }}>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align={'left'}>
                            <TaTypography color={'var(--main-text-color1)'} fontSize={'15pt'} text={fnFormatNumb(currentDate)} />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align={'left'}>
                            <TaTypography color={'var(--main-text-color1)'} fontSize={'9pt'} fontFamily={'Poppins'} text={'Actual'} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" alignItems='flex-start' sx={{ padding: '5px 5px 5px 0px' }}>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align={'left'}>
                            <TaTypography color={'var(--main-text-color1)'} fontSize={'15pt'} text={fnFormatNumb(previousDate)} />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align={'left'}>
                            <TaTypography color={'var(--main-text-color1)'} fontSize={'9pt'} fontFamily={'Poppins'} text={'Anterior'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'center'}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems='flex-start'>
                        {icons}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} >
                    <TaTypography color={'var(--main-text-color1)'} fontSize={'9pt'} fontFamily={'Poppins'} text={footerText} />
                </Grid>
            </Grid>
        </Card >
    )
}

export default TaCard