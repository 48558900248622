import React, { memo } from 'react'
import propTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import { makeStyles } from '@mui/styles'

export const headerStyles = makeStyles({
  tableCellHeader: {
    maxHeight: '24px',
    backgroundColor: 'var(--main-bg-color1)',
    position: "sticky",
    top: 0,
    zIndex: 10,
    padding: '10px',
    width: props => props.width,
    textAlign: props => props.align,
    fontFamily: "Poppins",
    fontSize: "12pt",
    color: "var(--main-text-color1)",
    display: props => (props.hidden) && 'none'
  },
  tableCellBody: {
    maxHeight: '24px',
    width: props => props.width,
    textAlign: props => props.align,
    padding: '5px',
    fontFamily: "Poppins",
    fontSize: "12pt",
    color: "var(--main-text-color0)",
    display: props => (props.hidden) && 'none'
  }
})

const TaTableCell = memo(({ id, header, width, align, colSpan, onClick, hidden, children }) => {

  const classes = headerStyles({ width, align, hidden })

  return (
    <TableCell
      id={id}
      onClick={onClick ? onClick : undefined}
      colSpan={colSpan}
      variant={(header) ? 'head' : 'body'}
      classes={{ head: classes.tableCellHeader, body: classes.tableCellBody }}>
      {children}
    </TableCell>
  )
})

TaTableCell.propTypes = {
  header: propTypes.bool,
  width: propTypes.string,
  align: propTypes.string,
  onClick: propTypes.func,
}

TaTableCell.defaultProps = {
  header: false,
  width: '100px',
  align: 'left',
  colSpan: 1,
}

export default TaTableCell