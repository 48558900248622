import { TableRow } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { memo } from "react"

const tableRowStyles = makeStyles({
  tableRow: {
    color: 'var(--main-table-color1)'
  }
})

const TaTableRow = memo(({ isSelected = false, id = '', children, onClick }) => {

  const classes = tableRowStyles()

  const fnOnClick = (e) => onClick && onClick(e)

  return (
    <TableRow id={`tableRow: ${id}`} selected={isSelected} onClick={fnOnClick} classes={{ selected: classes.tableRow }}  >
      {children}
    </TableRow>
  )
})

export default TaTableRow