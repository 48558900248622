import { homeLanguages } from "projectComponent/Home/HomeLanguages";

export const userManagementLanguages = {
    ...homeLanguages,
    filterOptionLang: {
        label: {
            ES: "Filtrar por",
            EN: "Filter by",
            CN: "過濾"
        }
    },
    txtCommentLang: {
        label: {
            ES: "Motivo del rechazo*",
            EN: "Reason for rejection*",
            CN: "拒絕理由"
        }
    },
    txtCodSupplierSaintLang: {
        label: {
            ES: "Proveedor Saint*",
            EN: "Saint Provider*",
            CN: "Saint提供者*"
        }
    },
    swithActiveLang: {
        ES: "Activo",
        EN: "Active",
        CN: "積極的"
    },
    optionFilterUserManagementLang: [
        {
            ES: "Usuario por validar",
            EN: "User to validate",
            CN: "用戶驗證"
        },
        {
            ES: "Usuario por aprobar",
            EN: "User to approve",
            CN: "用戶批准"
        },
        {
            ES: "Usuarios aprobados",
            EN: "Approved Users",
            CN: "批准的用戶"
        },
        {
            ES: "Usuarios rechazados",
            EN: "Rejected users",
            CN: "被拒絕的用戶"
        },
        
    ]

}