import React, { createContext, useEffect, useState } from 'react'
// import { dbSimplit, postLocalDB } from 'services/localforage/Localforage'
import { initialState, LOCAL_STORAGE_LANGUAGE } from './LanguageConstants'

const LanguageContext = createContext(initialState)
const LanguageProvider = ({ children }) => {

  const [languageState, setLanguageState] = useState(initialState)

  useEffect(() => {
    const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE)
    if (language !== null) {
      return setLanguageState(language)
    } else if (language === null) localStorage.setItem(LOCAL_STORAGE_LANGUAGE, initialState)
  }, [])

  return (
    <LanguageContext.Provider value={{ languageState, setLanguageState }}>
      {children}
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
