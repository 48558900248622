import { userManagementLanguages } from "./UserManagementLanguages"

export const userApprovedModel = (data) => ({
    id: data?.id ?? '',
    fi: `${data?.codRif}${data?.fi}` ?? '',
    direction: data?.direction,
    phone: data?.phone ?? '',
    contact: data?.contact ?? '',
    aproved: data?.aproved ?? false,
    comment: data?.comment ?? '',
    bussinessName: data?.bussinessName ?? '',
    codSupplierSaint:(data.aproved)? data?.codSupplierSaint : '',
    email: data?.email ?? ''
})


export const userPostChangeEmail = (data) => ({
    fi: `${data?.codRif}${data?.fi}` ?? '',
    bussinessName: data?.bussinessName ?? '',
    email: data?.email ?? ''
})


export const boolOptionsModel = {
    isOpenModalComment: false,
    isDisabledForm: true,
    isOpenModalDeleteRecord:false
}

export const defaultValuesUserManagement = {
    searchUserApprovalList: '',
    name: '',
    codRif: '',
    fi: '',
    bussinessName: '',
    phone: '',
    contact: '',
    direction: '',
    email: '',
    codSupplierSaint: '',
    uniqueKey: '',
    comment: '',
    active: false
}
const { optionFilterUserManagementLang } = userManagementLanguages

export const optionFilterUserManagement = (languageState) => [
    { id: '-2', name: optionFilterUserManagementLang[0][languageState] },
    { id: '-1', name: optionFilterUserManagementLang[1][languageState] },
    { id: '1', name: optionFilterUserManagementLang[2][languageState] },
    { id: '-3', name: optionFilterUserManagementLang[3][languageState] },
]
