import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid } from '@mui/material';
import TaButton from 'components/button/TaButton';
import Loader from 'components/loader/Loader';
import TaPaper from 'components/paper/TaPaper';
import TaAdornedTextField from 'components/textfield/TaAdornedTextField';
import TaTextField from 'components/textfield/TaTextField';
import TaTypography from 'components/typography/TaTypography';
import { LanguageContext } from 'context/Language/LanguageContext';
import { SnackbarContext } from 'context/Snackbar/SnackbarContext';
import { LOGIN } from 'constants/appRoutes';
import { regexCedula, regexPass, regexRif } from 'helper/RegExp';
import { postChangePass, postUserPassword } from './ApiRecoverPassword';
import { recoveryPassLanguage } from './RecoveryPassLanguage';
import { LoaderContext } from 'context/Loader/LoaderContext';


const { txtErrorsRequiredLang, textUserLang,
    textPasswordLang, textVerifyPasswordLang, btnVerifyLang,
    txtFiLang, linkReturnLoginLang } = recoveryPassLanguage

const RecoverPassword = () => {
    const { handleSubmit, register, formState: { errors }, reset, watch, trigger } = useForm({ defaultValues: { fi: '' }, reValidateMode: 'onSubmit' })
    const { handleSubmit: handleSubmit2, register: register2, formState: { errors: errors2 }, control, getValues: getvalues2 } = useForm({ defaultValues: { pass: '', verifyPass: '' }, reValidateMode: 'onSubmit' })
    const { isLoading, setIsLoading } = useContext(LoaderContext)
    const { languageState } = useContext(LanguageContext)
    const { sbDispatch } = useContext(SnackbarContext)
    const history = useHistory()
    const tokenVerifyNewPass = history.location.state
    const [{ passVisibility, confPassVisibility }, setIsVisibility] = useState({ passVisibility: false, confPassVisibility: false })

    const fnIsVisibility = (name) => setIsVisibility(preVal => ({ ...preVal, [name]: !preVal[name] }))

    const fnGoHome = () => history.push(LOGIN)

    const fnPostUserPassword = async (data) => {
        trigger()
        setIsLoading(true)
        try {
            const response = await postUserPassword(data)
            if (response.status === 200 && response.data.errorCode === 0) {

                reset()
                fnGoHome()
            }
            sbDispatch.apiV2(response.status, response.data);
        } catch (error) { console.error('fnPostUserAlias', error) }
        setIsLoading(false)
    }

    const fnPostChangePass = async (data) => {
        setIsLoading(true)
        try {
            const response = await postChangePass(tokenVerifyNewPass, data)
            sbDispatch.apiV2(response.status, response.data);
            if (response.status === 200 && response.data.errorCode === 0) fnGoHome()
        } catch (error) { console.error('fnPostChangePass =>', error) }
        setIsLoading(false)
    }
    return (
        <TaPaper centerDiv={true} overflow={'auto'} maxWidth={509}
            Content={
                <>
                    {tokenVerifyNewPass === undefined ?
                        <form key={'formRifRequest'} onSubmit={handleSubmit(fnPostUserPassword)}>
                            <Grid container justifyContent="center" alignItems="center" sx={{ backgroundColor: 'var(--main-bg-color1)', height: '70px' }} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} >
                                    <TaTypography color={'var(--main-text-color1)'} isTitle={true} text={'Recupera tu cuenta'} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ paddingTop: '35px' }} >
                                <Grid item xs={9} sm={7} md={7} lg={7} xl={7} >
                                    <TaTextField
                                        label={txtFiLang.label[languageState]}
                                        placeholder={textUserLang.placeholder[languageState]}
                                        register={register('fi', {
                                            required: txtErrorsRequiredLang[languageState],
                                            pattern: watch('fi')[0] === 'V' ? regexCedula : regexRif
                                        })}
                                        error={errors['fi']?.message}
                                        disabled={isLoading}
                                        helperText={txtFiLang.helpertext[languageState]} />
                                </Grid>
                                <Grid item xs={9} sm={7} md={7} lg={7} xl={7} align={'center'} >
                                    <TaButton
                                        label={btnVerifyLang[languageState]}
                                        disabled={isLoading}
                                        type={"submit"} />
                                </Grid>
                                <Grid item xs={11} sm={11} md={11} lg={11} xl={11} align={'left'}>
                                    <Link style={{ color: 'var(--main-text-color2)' }} underline={'hover'} to={isLoading ? '' : LOGIN} > {linkReturnLoginLang[languageState]} </Link>
                                </Grid>
                                <Loader isLoading={isLoading} />
                            </Grid>
                        </form >
                        : <form key={'formRecoveryPass'} onSubmit={handleSubmit2(fnPostChangePass)}>
                            <>
                                <Grid container justifyContent="center" alignItems="center" sx={{ backgroundColor: 'var(--main-bg-color1)', height: '70px' }} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                                        <TaTypography color={'var(--main-text-color1)'} isTitle={true} text={'Recupera tu cuenta'} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ paddingTop: '35px' }}>
                                    <Grid item xs={9} sm={7} md={7} lg={7} xl={7}>
                                        <TaAdornedTextField
                                            name={'pass'}
                                            label={`${textPasswordLang.label[languageState]}*`}
                                            control={control}
                                            type={(!passVisibility) ? 'password' : 'text'}
                                            helperText={' '}
                                            disabled={isLoading}
                                            rules={{
                                                required: txtErrorsRequiredLang[languageState],
                                                pattern: regexPass
                                            }}
                                            error={errors2['pass']?.message}
                                            adornmentPosition={'end'}
                                            adornment={(!passVisibility)
                                                ? <VisibilityIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => fnIsVisibility('passVisibility')} />
                                                : <VisibilityOffIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => fnIsVisibility('passVisibility')} />
                                            } />
                                    </Grid>
                                    <Grid item xs={9} sm={7} md={7} lg={7} xl={7}>
                                        <TaAdornedTextField
                                            name={'verifyPass'}
                                            label={`${textVerifyPasswordLang.label[languageState]}*`}
                                            control={control}
                                            type={(!confPassVisibility) ? 'password' : 'text'}
                                            helperText={' '}
                                            disabled={isLoading}
                                            adornmentPosition={'end'}
                                            rules={{
                                                required: txtErrorsRequiredLang[languageState],
                                                pattern: regexPass,
                                                validate: value => (getvalues2('pass') === value) || textVerifyPasswordLang.validate[languageState]
                                            }}
                                            error={errors2['verifyPass']?.message}
                                            adornment={(!confPassVisibility)
                                                ? <VisibilityIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => fnIsVisibility('confPassVisibility')} />
                                                : <VisibilityOffIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => fnIsVisibility('confPassVisibility')} />
                                            } />
                                    </Grid>
                                    <Grid item xs={9} sm={7} md={7} lg={7} xl={7} align={'center'} >
                                        <TaButton
                                            label={btnVerifyLang[languageState]}
                                            disabled={isLoading}
                                            type={"submit"} />
                                    </Grid>
                                    <Grid item xs={10} sm={11} md={11} lg={11} xl={11} align={'left'}>
                                        <Link re style={{ color: 'var(--main-text-color2)' }} underline={'hover'} to={isLoading ? '' : LOGIN} > {linkReturnLoginLang[languageState]} </Link>
                                    </Grid>
                                    <Loader isLoading={isLoading} />
                                </Grid>
                            </>
                        </form >
                    }
                </>
            } />
    )
}

export default RecoverPassword