import { makeStyles } from '@mui/styles'
import { ListItemButton } from '@mui/material'
import PropTypes from 'prop-types'
import { memo } from 'react'

const listButtonStyles = makeStyles({
  root: {
    backgroundColor: props => props.customColor ?? 'var(--main-bg-color1)',
    marginTop: "5px",
    color: props => (props.customColor === 'var(--main-list-color0)') ? "var(--main-text-color0)" : 'var(--main-text-color1)',
    paddingTop: props => props.subMenu && '5px',
    paddingRight: props => props.subMenu && '0px',
    paddingBottom: props => props.subMenu && '5px',
    paddingLeft: props => props.subMenu && '30px',
    borderRadius: props => (props.squareBorder) ? '0px' : '5px',
    '&:hover': {
      backgroundColor: "var(--main-hover-color1)",
      color: "var(--main-bg-color0)",
    }
  },
  selected: {
    backgroundColor: "var(--main-bg-color3) !important",
    color: "var(--main-text-color1) !important"
  },
})

const TaListButton = memo(({ 
  key, data, children, fnOnClickItem,
  selected, subMenu, customColor, squareBorder }) => {

  const onClick = (data, e) => fnOnClickItem(data, e)

  const classes = listButtonStyles({ subMenu, customColor, squareBorder })

  return (
    <ListItemButton key={key} selected={selected} onClick={(e) => onClick(data, e)} classes={{ root: classes.root, selected: classes.selected }} >
      {children}
    </ListItemButton>
  )
})

TaListButton.defaultProps = {
  selected: false,
  fnOnClickItem: () => null,
  squareBorder: false
}

export default TaListButton