import { fnGetUrl, fnPostUrl } from "functions/Api"

export const postRegister = (data) => {
    delete data.codRif
    delete data.codPhone
    delete data.verifyEmail
    delete data.verifyPass
    const apiRequest = {
        endpoint: '/api/ProvWeb/Register',
        body: data,
    }
    return fnPostUrl(apiRequest)
}


export const getReCaptchaV3Register = (token) => {
    const apiRequest = {
        endpoint: `/api/ProvWeb/Register/CaptchaV3?CaptV3Token=${token}`,
    }
    return fnGetUrl(apiRequest)
}
