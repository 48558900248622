import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const FourTileFilter = () => {
    return (
        <SvgIcon viewBox="0 0 6.1 6.1">
            <path 
                className="st0" 
                d="M6,6H0.1V0.1H6V6z M2.7,1.2H1v1.6h1.6V1.2z M5.1,1.2H3.5v1.6h1.6V1.2z M2.7,3.3H1V5h1.6V3.3z M5.1,3.3H3.5V5
                h1.6V3.3z"/>
        </SvgIcon>
    )
}

export default FourTileFilter