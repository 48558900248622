import { makeStyles } from '@mui/styles';

export const listItemStyles = makeStyles({
    root: {
        height: props => props.height ?? undefined,
        backgroundColor: props => props.orderList ? "var(--main-list-color2)" : "var(--main-list-color0)",
        marginTop: "5px",
        marginBottom: "10px",
        padding: "5px",
        paddingLeft: "15px",
        borderRadius: "5px",
        fontFamily: 'Poppins',
        fontSize: "14pt",
        color: "var(--main-text-color0)",
        cursor: 'pointer',
    },
    selected: {
        backgroundColor: props => props.orderList ? "var(--main-bg-color3) !important" : "var(--main-bg-color1) !important",
        color: "var(--main-bg-color0)"
    },
})