export const fnCompareStrings = (value1, value2) => String(value1).toLowerCase() === String(value2).toLowerCase()
export const fnIncludeStrings = (value1, value2) => {
    let newValue = ''
    if (typeof (value1) === 'string') {
        return String(value1).toLowerCase().includes(String(value2).toLowerCase())
    } else if (typeof (value1) === 'object') {
        for (let prop in value1) {
            newValue += value1[prop]
        }
        // console.log({
        //     value1,
        //     value2,
        //     newValue,
        //     result: String(newValue).toLowerCase().includes(String(value2).toLowerCase())
        // })
        return String(newValue).toLowerCase().includes(String(value2).toLowerCase())
    }
}