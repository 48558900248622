import { Divider, Grid } from '@mui/material'
import LoginForm from '../projectComponent/Login/LoginForm'
import useDinamicHeights from '../helper/useDinamicHeights'
import ToolsPROv from 'projectImages/ToolsPROVwebLogo/Negativos/v3.png'
import TaImg from '../components/img/TaImg'
import { stylesLogin } from '../projectComponent/Login/LoginModel'
import TaPaper from 'components/paper/TaPaper'
import useWindowSize from 'helper/UseWindowsSize'
import TaTypography from 'components/typography/TaTypography'
// import { dividerStyles } from 'projectComponent/Login/LoginStyles'


const Login = () => {
    const { paperLoginStyle, imgStyleLogin } = useDinamicHeights(stylesLogin)
    const { height } = useWindowSize()
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: height, backgroundImage: 'var(--bgImage)'}}>
            <TaPaper elevation={2} centerDiv={true} height={'392px'} padding={'0px'} Content={
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'} id='itemHeightLoginView' sx={{ backgroundColor: 'var(--main-bg-color3)'}}>
                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={11} sm={11} md={11} lg={11} xl={11} align={'left'} sx={{ marginTop: '10px', height: '70px' }}>
                                <TaImg url={ToolsPROv} inStyle={imgStyleLogin} />
                            </Grid>
                            <Grid item xs={11} sm={11} md={11} lg={11} xl={11} align={'left'} sx={{ marginTop: '100px' }}>
                                <TaTypography text={'Bienvenido de Vuelta,'} color={'var(--main-text-color1)'} fontSize='16pt' />
                            </Grid>
                            <Grid item xs={12} sm={11} md={11} lg={11} xl={11} align={'left'} id='itemTypographytexto2' sx={{ height: '150px'}}>
                                <TaTypography text={'inicia sesión para acceder a Tools PROV web.'} fontSize='9pt' />
                            </Grid>
                            <Grid item xs={11} sm={11} md={11} lg={11} xl={11} align={'right'} sx={{ height: '40px' }}>
                                <Divider sx={{
                                    '&:before': {
                                        borderTop: '1px solid var(--main-bg-color0)!important',
                                        top: '50%',
                                        position: 'relative'
                                    },
                                    '&:after': {
                                        borderTop: '1px solid var(--main-bg-color0)!important',
                                        top: '50%',
                                        position: 'relative'
                                    }
                                }} textAlign={'right'} >
                                    <TaTypography text={'© TotalAplicaciones 2022'} color={'var(--main-text-color1)'} fontSize='10pt' />
                                </Divider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={11} sm={11} md={11} lg={11} xl={11} align={'center'} >
                                <LoginForm />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            />
        </Grid>
    )
}

export default Login