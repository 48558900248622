import { Grid } from '@mui/material'
import useWindowSize from 'helper/UseWindowsSize'
import RecoverPassword from 'projectComponent/RecoverPassword/RecoverPassword'

const RecoverPass = () => {
  const {height}=useWindowSize()
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: height, backgroundImage: 'var(--bgImage)' }}>
      <RecoverPassword />
    </Grid>
  )
}

export default RecoverPass