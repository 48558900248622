import { fnCalculateHeights } from "helper/fnCalculateHeights"

export const homeStyles = (height, isTablet, isMobile, width) => {
    return {
        paperHeightUserManagement: {
            height: fnCalculateHeights([height, 67])
        },
        titleUserManagement: {
            backgroundColor: 'var(--main-bg-color1)',
            height: '50px',
            color: 'var(--main-text-color1)',
            fontSize: '18pt',
            paddingTop: '10px'
        },
        specialItemHeight: {
            height: '150px',
            justifyContent: "flex-start",
            display: 'flex',
            alignItems: 'flex-start',
        },
        bodyHeight: {
            height: fnCalculateHeights([height, 179])
        }
    }
}