import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import TaTypography from '../typography/TaTypography'
import { iconType } from './Icons'

const TaFabStyle = makeStyles({
  circular: {
    color: props => props.colorIcon,
    margin: props => (props.margin) ? '5px' : '0px',
    '&:hover': {
      backgroundColor: "var(--main-bg-color3)"
    },
    fontSize: "12pt",
    fontFamily: "Poppins",
    minWidth: props => props.extended && "125px",
    backgroundColor: props => props.color,
  },
  extended: {
    height: '40px !important',
    color: props => props.colorIcon,
    margin: props => (props.margin) ? '5px' : '0px',
    '&:hover': {
      backgroundColor: "var(--main-bg-color3)"
    },
    fontSize: "12pt",
    fontFamily: "Poppins",
    minWidth: props => props.extended && "125px",
    backgroundColor: props => props.color,
  }
})

const TaFabColor = (color) => {
  if (color === 'orange') {
    return "var(--main-bg-color7)"
  } else if (color === 'red') {
    return "var(--main-bg-color9)"
  } else if (color === 'green') {
    return "var(--main-bg-color5)"
  } else if (color === 'transparent') {
    return "transparent"
  } else if (color === 'white') {
    return "var(--main-bg-color0)"
  } else if (color === 'lightBlue') {
    return "var(--main-bg-color3)"
  } else {
    return "var(--main-bg-color4)"
  }
}

const fnReturnLabel = (label, textButton, icon) => {
  return (textButton) ? <React.Fragment>
    {iconType(icon)} {textButton}
  </React.Fragment>
    : label
}

const TaFab = React.memo(({ id, icon, size, disabled,
  onClick, textButton, color, noMargin,
  tooltip, index, download, uniqueKey,
  colorIcon, hotkey, type, hidden }) => {

  const [label, setLabel] = useState("")
  const [posLabel] = useState(0)


  useEffect(() => {
    setLabel(textButton === '' ? iconType(icon) : textButton)
  }, [textButton, icon])

  useEffect(() => {
    if (hotkey === "") {
      setLabel(textButton === '' ? iconType(icon) : textButton)
    }
  }, [hotkey, textButton, icon])

  const fabColor = TaFabColor(color)
  const classes = TaFabStyle({ color: fabColor, margin: !(noMargin), colorIcon: colorIcon, extended: (textButton) })
  const finalLabel = fnReturnLabel(label, textButton, icon)
  return (
    !(hidden) ? <Tooltip arrow title={tooltip !== "" ? <TaTypography color="var(--main-text-color1)">{tooltip}</TaTypography> : ""} aria-label={tooltip}>
      <Fab
        id={id}
        variant={(textButton) ? 'extended' : 'circular'}
        aria-label={icon}
        data-id={index}
        download={(download) && download}
        size={(textButton) ? 'large' : size}
        disabled={disabled}
        classes={{ circular: classes.circular, extended: classes.extended }}
        onClick={(onClick !== undefined) ? (event) => onClick(id, uniqueKey, event, index) : null}
        type={(type) && type}>
        {posLabel === 0 ? finalLabel : hotkey === "" ? finalLabel : hotkey}
      </Fab>
    </Tooltip>
      : null
  )
})

TaFab.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  topMargin: PropTypes.bool,
  floatLeft: PropTypes.bool,
  tooltip: PropTypes.string,
  textButton: PropTypes.string,
  color: PropTypes.string,
  hotkey: PropTypes.string,
}

TaFab.defaultProps = {
  disabled: false,
  size: "small",
  floatLeft: false,
  tooltip: '',
  textButton: '',
  color: "",
  colorIcon: "var(--main-bg-color0)",
  hotkey: "",
}

export default TaFab