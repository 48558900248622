import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch } from '@mui/material'
import TaTypography from 'components/typography/TaTypography'
import { Controller } from 'react-hook-form'
import { makeStyles } from '@mui/styles'

const switchStyles = makeStyles({
  switchBase: {
    color: 'var(--main-bg-color2)',
    '&$checked': {
      color: 'var(--main-text-color2)',
    },
    '&$checked + $track': {
      backgroundColor: 'var(--main-bg-color1)',
    },
    "&$disabled": {
      color: 'var(--main-bg-color2) !important'
    },
    "&$disabled + $track": {
      color: 'var(--main-bg-color8)',
      backgroundColor: 'transparent',
    }
  },
  checked: {},
  track: {
    backgroundColor: 'var(--main-bg-color1)'
  },
  disabled: {},
})

const TaSwitch = memo(({ name, control, status, disabled, label, id }) => {

  const classes = switchStyles()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field: { onChange, value, name, ref } }) => (<FormControlLabel
        control={
          <Switch
            id={id}
            name={name}
            inputRef={ref}
            onChange={onChange}
            checked={value}
            disabled={disabled && disabled}
            classes={{ switchBase: classes.switchBase, checked: classes.checked, track: classes.track, disabled: classes.disabled }} />
        }
        label={<TaTypography color={'var(--main-text-color0)'} >{label}</TaTypography>} />)}
    />
  )
})

TaSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
}

TaSwitch.defaultProps = {
  status: false,
  disabled: false,
  label: '',
};

export default TaSwitch