import { Grid } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import TaAdornedTextField from 'components/textfield/TaAdornedTextField'
import TaTextField from 'components/textfield/TaTextField'
import Loader from 'components/loader/Loader'

import { registerLanguages } from 'projectComponent/Register/RegisterLanguages'
import { defaultValueRegisterForm } from 'projectComponent/Register/RegisterModel'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    regexAddress, regexCedula, regexEmail,
    regexPass, regexRif
} from 'helper/RegExp'
import { SnackbarContext } from 'context/Snackbar/SnackbarContext'
import { LanguageContext } from 'context/Language/LanguageContext'
import TaFab from 'components/fab/TaFab';
import { LoginContext } from 'context/Login/LoginContext';
import { postUpdateSupplier } from './ApiUserProfile';
import useCheckIsAuthorized from 'hooks/useCheckIsAuthorized';


const { txtRifLang, txtErrorsRequiredLang, textEmailLang,
    textVerifyEmailLang, textBusinessNameLang, codePhoneLang,
    textPasswordLang, textAddressLang, textContactPersonLang,
    textVerifyPasswordLang } = registerLanguages

const UserProfileForm = () => {
    const { handleSubmit, register, formState: { errors },
        getValues, setValue, control, trigger, watch } = useForm({ defaultValues: defaultValueRegisterForm })
    const { loginState, loginDispatch: { setLogin } } = useContext(LoginContext)

    const { sbDispatch } = useContext(SnackbarContext)
    const { languageState } = useContext(LanguageContext)
    const [isLoading, setIsLoading] = useState(false)
    const [{ passVisibility, confPassVisibility }, setIsVisibility] = useState({ passVisibility: false, confPassVisibility: false })
    const fnIsVisibility = (name) => setIsVisibility(preVal => ({ ...preVal, [name]: !preVal[name] }))
    const fnForceLogout = useCheckIsAuthorized()

    const fnOnSubmit = async (data) => {
        trigger()
        setIsLoading(true)
        try {
            const response = await postUpdateSupplier(data)
            if (response.status === 401) {
                setIsLoading(false)
                return fnForceLogout(response)
            }
            if (response.status === 200 && response.data.errorCode === 0) {
                setLogin({ ...loginState, ...data, email: loginState.email, })
            }
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) { console.error('error en submit de perfil de usuario', error) }
        setIsLoading(false)
    }

    useEffect(() => {
        for (const property in loginState) {
            setValue(property, loginState[property])
        }
        setValue('fi', loginState.fi)
        setValue('bussinessName', loginState.bussinessName)
        setValue('verifyEmail', loginState.email)
    }, [])

    return (
        <form key={'userProfileForm'} onSubmit={handleSubmit(fnOnSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        label={`${txtRifLang[languageState]}*`}
                        register={register('fi', {
                            required: txtErrorsRequiredLang[languageState],
                            pattern:  watch('fi')[0] === 'V' ? regexCedula : regexRif
                        })}
                        error={errors['fi']?.message}
                        helperText={' '}
                        disabled={isLoading}
                        readOnly={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        label={`${textBusinessNameLang.label[languageState]}*`}
                        register={register('bussinessName', {
                            required: txtErrorsRequiredLang[languageState],
                        })}
                        error={errors['bussinessName']?.message}
                        helperText={' '}
                        disabled={isLoading}
                        readOnly={true}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TaTextField
                        label={`${textAddressLang.label[languageState]}*`}
                        register={register('direction', {
                            required: txtErrorsRequiredLang[languageState],
                            pattern: regexAddress
                        })}
                        error={errors['direction']?.message}
                        helperText={' '}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        label={`${textEmailLang.label[languageState]}*`}
                        register={register('email', {
                            required: txtErrorsRequiredLang[languageState],
                            pattern: regexEmail
                        })}
                        error={errors['email']?.message}
                        helperText={' '}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        label={`${textVerifyEmailLang.label[languageState]}*`}
                        register={register('verifyEmail', {
                            required: txtErrorsRequiredLang[languageState],
                            pattern: regexEmail,
                            validate: value => (getValues('email') === value) || textVerifyEmailLang.validate[languageState]
                        })}

                        error={errors['verifyEmail']?.message}
                        helperText={' '}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        label={`${codePhoneLang.label[languageState]}*`}
                        register={register('phone', {
                            required: txtErrorsRequiredLang[languageState],
                        })}
                        type={'number'}
                        error={errors['phone']?.message}
                        helperText={'Ejm. 02123230117'}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaTextField
                        label={`${textContactPersonLang.label[languageState]}*`}
                        register={register('contact', {
                            required: txtErrorsRequiredLang[languageState],
                        })}
                        error={errors['contact']?.message}
                        helperText={' '}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaAdornedTextField
                        name={'password'}
                        label={`${textPasswordLang.label[languageState]}*`}
                        control={control}
                        type={(!passVisibility) ? 'password' : 'text'}
                        helperText={' '}
                        disabled={isLoading}
                        rules={{ pattern: regexPass }}
                        error={errors['password']?.message}
                        adornmentPosition={'end'}
                        adornment={(!passVisibility)
                            ? <VisibilityOffIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => isLoading ? '' : fnIsVisibility('passVisibility')} />
                            : <VisibilityIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => isLoading ? '' : fnIsVisibility('passVisibility')} />
                        } />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TaAdornedTextField
                        name={'verifyPass'}
                        label={`${textVerifyPasswordLang.label[languageState]}*`}
                        control={control}
                        type={(!confPassVisibility) ? 'password' : 'text'}
                        helperText={' '}
                        disabled={isLoading}
                        rules={{
                            pattern: regexPass,
                            validate: value => (getValues('password') === value) || textVerifyPasswordLang.validate[languageState]
                        }}
                        error={errors['verifyPass']?.message}
                        adornmentPosition={'end'}
                        adornment={(!confPassVisibility)
                            ? <VisibilityOffIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => isLoading ? '' : fnIsVisibility('confPassVisibility')} />
                            : <VisibilityIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => isLoading ? '' : fnIsVisibility('confPassVisibility')} />
                        } />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
                    {/* <TaFab id={'btnEditInfo'} icon={'edit'}
                        color={'green'} onClick={fnEditInfo} disabled={isLoading} /> */}
                    <TaFab id={'btnSaveUserInfo'} icon={'save'}
                        type={'submit'} disabled={isLoading} />
                </Grid>
                <Loader isLoading={isLoading} />
            </Grid>
        </form>
    )
}

export default UserProfileForm