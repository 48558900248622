import { Grid } from '@mui/material'
import TaDialog from 'components/dialog/TaDialog'
import TaFab from 'components/fab/TaFab'

const ModalDeleteRecord = ({ onClose, onCallback }) => {

    const fnApprovedModal = () => {
        onCallback()
    }

    return (
        <TaDialog
            isOpen={true}
            maxWidth={"xs"}
            title={'¿Desea eliminar el registro?'}
            content={
                <Grid container alignItems='center' alignContent='flex-start' spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                        <TaFab
                            id='btnCloseModalDeleteRecord'
                            color='red'
                            icon="exit"
                            onClick={() => onClose('isOpenModalDeleteRecord')}
                        />
                        <TaFab
                            id='btnDeletRecord'
                            icon="check"
                            color='green'
                            onClick={fnApprovedModal}
                        />
                    </Grid>
                </Grid>
            }
        />
    )
}

export default ModalDeleteRecord