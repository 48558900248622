import generalLanguages from 'components/generalLanguages/generalLanguages'

const commercialAllyLanguages = {
    ...generalLanguages,
    titleLang: {
      ES: 'Licencia',
      EN: 'License',
      CN: '執照'
    },
    lblWelcomeLang: {
      ES: 'Bienvenido a ToolsProv Web, por favor ingrese su código de Aliado Comercial',
      EN: 'Welcome to ToolsProv Web, please enter your business ally code',
      CN: '歡迎來到ToolsProv Web,請輸入您的商業盟友代碼',
    },
    txtComercialAllyLang: {
      label: {
        ES: 'Aliado comercial',
        EN: 'Business Ally',
        CN: '商業盟友'
      },
      placeHolder: {
        ES: 'Ingrese el código de aliado comercial',
        EN: 'Enter the business ally code',
        CN: '輸入商業盟友代碼'
      },
    },
    btnVerifyLang: {
      ES: 'VERIFICAR',
      EN: 'CHECK',
      CN: '查看'
    },
 
    txtCodeAllyLang: {
      label: {
        ES: "Código de verificación",
        EN: 'Verify code',
        CN: '驗證碼'
      },
      placeHolder: {
        ES: "Ingrese el código",
        EN: 'Enter the verify code',
        CH: '輸入驗證碼'
      },
    },
    btnValidateLang: {
      ES: 'VALIDAR',
      EN: 'VALIDATE',
      CN: '證實'
    },
    businessInforLang: {
      ES: 'Si usted no es un aliado comercial y esta interesando en forma parte de nuestro grupo, por favor ingrese en el siguiente link para realizar su registro',
      EN: 'If you are not an business ally and you want to join our business ally team, please click in the link below',
      CN: '如果您不是商業夥伴,並且想加入我們的商業盟友團隊,請點擊以下鏈接'
    },
  }
  export default commercialAllyLanguages