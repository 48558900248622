import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import useWindowSize from 'helper/UseWindowsSize';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalculateIcon from '@mui/icons-material/Calculate';
import MoneyIcon from '@mui/icons-material/Money';
import InventoryIcon from '@mui/icons-material/Inventory';

export const iconType = (type, height) => {
    const styleIcons = {
        color: 'var(--main-bg-color0)',
        fontSize: height === 1080 ? '70px' : '70px'
    }
    switch (type) {
        case "calculate":
            return <CalculateIcon sx={styleIcons} />
        case "barChart":
            return <BarChartIcon sx={styleIcons} />
        case "money":
            return <MoneyIcon sx={styleIcons} />
        case 'inventory':
            return <InventoryIcon sx={styleIcons} />
        default:
            return null
    }
}
