import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, } from "@mui/material/"
import { Controller } from 'react-hook-form'
import TaTextField from './TaTextField'

const TaListTextField = memo(({
  id, name, label = '', options = [], fullWidth = true,
  disabled = false, control, helperText = '', color,
  rules, errorIcon = false, readOnly = false, theme = 'ordinary',
  onChange, value
}) => {
  return (
    <>
      {control ? <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          return (<TaTextField
            register={{ ...field, value: field.value ?? '' }}
            label={label}
            error={typeof error !== 'undefined' ? error.message : undefined}
            helperText={!errorIcon && typeof error !== 'undefined' ? error.message : helperText}
            color={color}
            errorIcon={errorIcon}
            select={true}
            readOnly={readOnly}
            fullWidth={fullWidth}
            theme={theme}
            disabled={disabled}>
            {
              options.map(option => <MenuItem
                sx={{
                  fontSize: "14pt",
                  fontFamily: "Poppins",
                  color: color !== 'var(--main-bg-color0)' ? 'var(--main-bg-color1)' : 'var(--main-bg-color0)',
                  backgroundColor: color === 'var(--main-bg-color0) !important' ? 'var(--main-bg-color1)' : 'var(--main-bg-color0)'
                }}
                key={option.id}
                value={option.id}>
                {typeof (option.name) === "array"
                  ? option.name["ES"]
                  : option.name}
              </MenuItem>)
            }
          </TaTextField>)
        }}
      />
        : <TaTextField
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          label={label}
          helperText={helperText}
          color={color}
          errorIcon={errorIcon}
          select={true}
          readOnly={readOnly}
          fullWidth={fullWidth}
          theme={theme}
          disabled={disabled}>
          {
            options.map(option => <MenuItem
              sx={{
                fontSize: "14pt",
                fontFamily: "Poppins",
                color: color !== 'var(--main-bg-color0)' ? 'var(--main-bg-color1)' : 'var(--main-bg-color0)',
                backgroundColor: color === 'var(--main-bg-color0) !important' ? 'var(--main-bg-color1)' : 'var(--main-bg-color0)'
              }}
              key={option.id}
              value={option.id}>
              {typeof (option.name) === "array"
                ? option.name["ES"]
                : option.name}
            </MenuItem>)
          }
        </TaTextField>
      }
    </>
  )
})

TaListTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  control: PropTypes.object,
}

export default TaListTextField