import { LOGIN } from 'constants/appRoutes'
import { useHistory } from 'react-router'
import { createContext, useState } from 'react'
import { initialStateModel } from "./LoginContextModel"

const LoginContext = createContext(initialStateModel)

const LoginProvider = ({ children }) => {

    const history = useHistory()

    const [loginState, setLoginState] = useState(initialStateModel)

    const loginReset = () => {
        setLoginState(initialStateModel)
        history.push(LOGIN)

    }

    const setLogin = async (data) => {
        setLoginState(preVal => {
            return {
                ...preVal,
                id:data.id,
                fi: data.fi,
                bussinessName: data.bussinessName,
                direction: data.direction,
                email: data.email,
                contact: data.contact,
                phone: data.phone,
                validate: data.validate,
                codSupplierSaint: data.codSupplierSaint,
                isAdmin: data.isAdmin,
                comment: data.comment
            }
        })
    }


    // const setLogout = () => {
    //     loginReset()
    // }

    const loginDispatch = {
        setLogin: setLogin,
        // setLogout: setLogout,
        loginReset: loginReset,
        setLoginState: setLoginState,
    }

    return (
        <LoginContext.Provider value={{ loginState, loginDispatch }}>
            {children}
        </LoginContext.Provider>
    )
}

export { LoginContext, LoginProvider }
