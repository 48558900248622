import { memo } from 'react'
import { Badge } from '@mui/material'
import propTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import badgeIcons from './BadgeIcons'

const badgeStyles = makeStyles({
  standard: {
    width: '20px',
    minWidth: '20px',
    padding: '2px',
    top: '75%',
    right: '5%',
    color: '#666666',
    backgroundColor: '#FFF',
  },
})

const TaBadge = memo(({ id, children, icon, invisible }) => {

  const content = badgeIcons(icon)

  const classes = badgeStyles()

  return (
    <Badge
      id={id}
      badgeContent={content}
      variant={'standard'}
      classes={{ standard: classes.standard }}
      invisible={invisible}>
      {children}
    </Badge>
  )
})

TaBadge.propTypes = {
  children: propTypes.object.isRequired,
  content: propTypes.any,
  invisible: propTypes.bool,
  id: propTypes.string
}

TaBadge.defaultProps = {
  id:'',
  invisible: false,
  icon: 'setting'
}

export default TaBadge