import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement, Title } from 'chart.js'
import { memo, useState } from 'react'
import { fnFormatNumb } from 'helper/FormatNumb';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  ArcElement,
  Title
)

const TaDoughnutChart = memo(({ labels = [], datasets = [], id = '', titleLabel = '' }) => {

  // const [chartText, setChartText] = useState('')
  // const fnOnHover = (event, elements = [], chart) => {
  //   if (elements.length > 0) {
  //     // console.log({ elements, data: elements[0], dsIndex: elements[0]?.datasetIndex, dataIndex: elements[0]?.index, currentDataSet: datasets[0].data[elements[0]?.datasetIndex] })
  //     setChartText(datasets[elements[0]?.datasetIndex].data[elements[0]?.index])
  //   }
  // }

  return (
    <Doughnut
      id={id}
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: `${titleLabel} ${fnFormatNumb(datasets[0]?.data?.reduce((val, data) => val + +data, 0) ?? 0)}`,
            position: 'bottom',
            font: {
              size: '16pt',
              family: 'Poppins',
            }
          }
        },
        // onHover: fnOnHover,
        circumference: 180,
        aspectRatio: 2,
        rotation: 270,
      }}

      data={{ labels, datasets }}
    />
  )
})

export default TaDoughnutChart