import { Grid } from '@mui/material'
import TaCard from 'components/card/TaCard'
import { LoginContext } from 'context/Login/LoginContext'
import { memo, useContext, useEffect, useState } from 'react'
import { getTAmountMonth } from './ApiIndicator'
import { optionsModel } from './IndicatorModel'

const IndicatorPanel = memo(({ selectedCurrency }) => {
    const [{ amountActMonth, amountCurrencyActMonth, amountCurrencyLastMonth,
        amountLastMonth, averageActMonth, averageCurrencyActMonth,
        averageCurrencyLastMonth, averageLastMonth,
        totalItemsActMonth, totalItemsLastMonth,
        totalTransActMonth, totalTransLastMonth, }, setOptions] = useState(optionsModel)

        const { loginState: { isAdmin } } = useContext(LoginContext)

    const fnGetTAmountMonth = async () => {
        try {
            const response = await getTAmountMonth()
            setOptions({ ...response.data.data })
        } catch (error) { console.error('cath IndicatorPanel', error) }
    }

    useEffect(() => {
        fnGetTAmountMonth()
    }, [])

    return (
        <>
            <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} lx={2.5} align='center'>
                <TaCard
                    backgroundColor={'var(--main-indicator-color3)'}
                    icon={'money'}
                    footerText={isAdmin?'COMPRAS DEL MES':'VENTAS DEL MES'}
                    currentDate={(selectedCurrency?.defaultCurrency) ? amountActMonth : amountCurrencyActMonth}
                    previousDate={(selectedCurrency?.defaultCurrency) ? amountLastMonth : amountCurrencyLastMonth}
                />
            </Grid>
            <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} lx={2.5} align='center'>
                <TaCard
                    backgroundColor={'var(--main-indicator-color4)'}
                    icon={'barChart'}
                    footerText={'TRANSACCIONES POR MES'}
                    currentDate={totalTransActMonth}
                    previousDate={totalTransLastMonth} />
            </Grid>
            <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} lx={2.5} align='left'>
                <TaCard
                    backgroundColor={'var(--main-indicator-color1)'}
                    icon={'calculate'}
                    footerText={'PROMEDIO POR MES'}
                    currentDate={(selectedCurrency?.defaultCurrency) ? averageActMonth : averageCurrencyActMonth}
                    previousDate={(selectedCurrency?.defaultCurrency) ? averageLastMonth : averageCurrencyLastMonth}
                />
            </Grid>
            <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} lx={2.5} align='center'>
                <TaCard
                    backgroundColor={'var(--main-indicator-color2)'}
                    icon={'inventory'}
                    footerText={'UNIDADES POR MES'}
                    currentDate={totalItemsActMonth}
                    previousDate={totalItemsLastMonth} />
            </Grid>
        </>
    )
})

export default IndicatorPanel