import React from 'react'
import TaTableCell from "components/table/TaTableCell";
import { v4 as uuid } from 'uuid'
import { TableRow } from '@mui/material'
import { TaSearch } from 'helper/SearchFilter';
import { TaIsEmpty } from 'helper/DataValidation';
import { fnIncludeStrings } from 'helper/CompareValues';
import modalSearchLanguages from './ModalSearchLang'

export const tableHeader = (languageState, type, isHiddenExist = false) => {
  const { tableColumnLang: { id, name, customerClass, existence } } = modalSearchLanguages
  let header = []
  switch (type) {
    case 'customer':
      header = [
        {
          id: 0,
          label: id[languageState],
          width: '100px',
          align: 'left',
          hidden: false,
          uniqueKey: uuid()
        },
        {
          id: 1,
          label: name[languageState],
          width: '250px',
          align: 'left',
          hidden: false,
          uniqueKey: uuid()
        },
        {
          id: 2,
          label: customerClass[languageState],
          width: '100px',
          align: 'left',
          hidden: false,
          uniqueKey: uuid()
        },
      ]
      break
    case 'storage':
      header = [
        {
          id: 0,
          label: id[languageState],
          align: 'left',
          width: '100px',
          hidden: false,
          uniqueKey: uuid()
        },
        {
          id: 1,
          label: name[languageState],
          align: 'left',
          width: '300px',
          hidden: false,
          uniqueKey: uuid()
        }
      ]
      break
    case 'seller':
      header = [
        {
          id: 0,
          label: id[languageState],
          align: 'left',
          width: '100px',
          hidden: false,
          uniqueKey: uuid()
        },
        {
          id: 1,
          label: name[languageState],
          align: 'left',
          width: '300px',
          hidden: false,
          uniqueKey: uuid()
        }
      ]
      break
    case 'item':
      header = [
        {
          id: 0,
          label: id[languageState],
          align: 'left',
          width: '100px',
          hidden: false,
          uniqueKey: uuid()
        },
        {
          id: 1,
          label: name[languageState],
          align: 'left',
          width: '300px',
          hidden: false,
          uniqueKey: uuid()
        },
        {
          id: 2,
          label: existence[languageState],
          align: 'left',
          width: '50px',
          hidden: isHiddenExist,
          uniqueKey: uuid()
        }
      ]
      break
    default:
      header = [
        {
          id: 0,
          label: id[languageState],
          align: 'left',
          width: '100px',
          hidden: false,
          uniqueKey: uuid()
        },
        {
          id: 1,
          label: name[languageState],
          align: 'left',
          width: '300px',
          hidden: false,
          uniqueKey: uuid()
        }
      ]
      break
  }
  return header
}

export const tableColumn = (header) => {
  return header.filter(x => x.hidden === false).map(head => (<TaTableCell key={head.uniqueKey} header={true} align={head.align} width={head.width}>{head.label}</TaTableCell>))
}

export const tableBody = (
  data, column, search, currentPage,
  rowsPerPage, fnOnClickTable, searchType, loader,
  filterTrigger, getValues,
) => {
  let newData = (search === '') ? data : (searchType === 'custom') ? TaSearch(data, search, true) : data
  newData = filterTrigger ? newData.filter(x => fnIncludeStrings(x, getValues('search'))) : newData
  let body = newData.length > 0 ? newData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage) : []
  if (body.length > 0) {
    return (
      body.map(x => {
        if (x?.selected === true) {
          const currentRow = window.document.getElementById(`rowModalSearch${x.uniqueKey}`)
          if (!TaIsEmpty(currentRow)) {
            currentRow.scrollIntoView()
          }
        }
        return (
          <TableRow
            id={`row-${x.uniqueKey}`}
            key={`row-${x.uniqueKey}`}
            selected={x.selected}
            autoFocus={true}
            onClick={() => !(loader) ? fnOnClickTable(x) : ''}>
            <TaTableCell key={`col1-${x.uniqueKey}`} align={column[0].align} width={column[0].width}>{x.id}</TaTableCell>
            <TaTableCell key={`col2-${x.uniqueKey}`} align={column[1].align} width={column[1].width}>{x.name}</TaTableCell>
            {searchType === 'item' && !column[2]?.hidden && <TaTableCell label={x.exist} width={column[2]?.width} align={column[2]?.align} />}
            {searchType === 'customer' && <TaTableCell label={x.customerClass ?? ""} width={column[2]?.width} align={column[2]?.align} />}
          </TableRow>
        )
      })
    )
  }
  return []
}