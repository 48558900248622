import { Grid, Paper } from '@mui/material'
import TaTypography from 'components/typography/TaTypography'

const TaTitle = ({ titleName }) => {
    return (
        <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} sx={{
                height: '69px',
                fontFamily: 'PoppinsMedium',
                fontSize: "18pt",
                justifyContent: "center",
                display: 'flex',
                alignItems: 'center',
                color: "var(--main-text-color1)",
                backgroundColor: 'var(--main-bg-color1)',
                margin: '10px'
            }}>
                {titleName}
            </Grid>
        </Grid >
        // <Paper id={'paperTitle'}
        //     square={true}
        //     elevation={0}
        //     sx={{
        //         height: '60px',
        //         backgroundColor: 'var(--main-bg-color1)',

        //     }}>
        //     {titleName}
        // </Paper>
    )
}

export default TaTitle