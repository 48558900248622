import { useEffect, useMemo, useState, useContext } from "react"
import { useForm } from "react-hook-form"
import { Grid } from "@mui/material"
import TaDialog from "components/dialog/TaDialog"
import TaFab from "components/fab/TaFab"
import TaTable from "components/table/TaTable"
import TaTextField from "components/textfield/TaTextField"
import modalSearchLanguages from "./ModalSearchLang"
import { tableParamettersModel } from "./ModalSearchModels"
import { tableBody, tableColumn, tableHeader } from "./ModalSearchTable"
import useDinamicHeights from "helper/useDinamicHeights"
import { SnackbarContext } from "context/Snackbar/SnackbarContext"
import { LanguageContext } from "context/Language/LanguageContext"
import { ModalSearchContext } from "context/ModalSearch/ModalSearchContext"
import { getSupplierSaintNoAsig } from "projectComponent/Home/component/UserManagement/ApiUserManagement"

const modalSearchStyles = (height) => ({ height: '282px' })

const ModalSearch = () => {

  const { txtSearchLang, errorCatchApiLang } = modalSearchLanguages

  const { register, handleSubmit, getValues, reset } = useForm({ defaultValues: { search: '' } })
  const {
    modalSearchState: { title, searchType, isHiddenExist, search, callback, data, isLoading, isOpenModalSearch },
    modalSearchDispatch: { modalSearchReset, setModalSearchState, setModalSearchData }
  } = useContext(ModalSearchContext)
  const { sbDispatch } = useContext(SnackbarContext)
  const { languageState } = useContext(LanguageContext)

  const { height } = useDinamicHeights(modalSearchStyles)

  const [tableParametters, setTableParametters] = useState(tableParamettersModel)
  const { currentPage, rowsPerPage, rowsPerPageOptions, count } = tableParametters
  const [filterTrigger, setFilterTrigger] = useState(false)

  const fnOnClickTable = (data) => {
    try {
      if (callback !== undefined) {
        reset()
        callback(data)
        modalSearchReset()
      }
    } catch (error) {
      console.error('fnOnClickTable => ', error)
    }
  }

  const fnGetData = async () => {
    setModalSearchState(preVal => ({ ...preVal, isLoading: true }))
    try {
      let response
      // let searchValue = getValues('search')
      if (searchType !== '') {
        switch (searchType) {
          // case 'customer':
          //   response = await getCustomer(searchValue, 1)
          //   break
          // case 'category':
          //   response = getOfflineData('category', 1, searchValue)
          //   break
          // case 'item':
          //   response = connectionType === 0 ? getOfflineData('item', 1, searchValue) : await getItem(searchValue, 1)
          //   break
          // case 'formats':
          //   response = await getFormats(languageState)
          //   break
          // case 'printers':
          //   response = await getPrinters()
          //   break
          // case 'storage':
          //   response = getOfflineData('storage', 1, searchValue)
          //   break
          case 'supplier':
            response = await getSupplierSaintNoAsig()
            break
          // case 'seller':
          //   response = getOfflineData('seller', 1, searchValue)
          //   break
          // case 'operationType':
          //   response = getOfflineData('operationType', 1, searchValue)
          //   break
          // case 'custom':
          //   break
          default:
            break
        }
      }
      setModalSearchData(response?.data.data ?? response)
      sbDispatch.apiV2(response.status, response.data)
    } catch (error) {
      console.error(errorCatchApiLang[languageState], error)
    }
    setModalSearchState(preVal => ({ ...preVal, isLoading: false }))
  }
  const fnOnClose = () => modalSearchReset()

  const fnOnChangePage = (e, page) => setTableParametters(preVal => ({ ...preVal, currentPage: page }))

  const fnOnClickPagination = (e) => ''

  const fnOnSearch = (data) => {
    try {
      if (searchType === 'customer' || searchType === 'item') {
        fnGetData()
      } else {
        setFilterTrigger(true)
      }
    } catch (error) {
      console.error('fnOnSearch => ', error)
    }
  }

  const fnOnKeyDown = (e) => {
    try {
      if (e.key !== 'Enter') return setFilterTrigger(false)
    } catch (error) {
      console.error('fnOnKeyDown => ', error)
    }
  }

  const modalSearchTableHeader = useMemo(() => tableHeader(languageState, searchType, isHiddenExist)
    , [languageState, searchType, isHiddenExist])

  const modalSearchColumnName = useMemo(() => tableColumn(modalSearchTableHeader)
    , [modalSearchTableHeader])

  const modalSearchTableBody = useMemo(() => tableBody(data, modalSearchTableHeader, search,
    currentPage, rowsPerPage, fnOnClickTable, searchType, isLoading, filterTrigger, getValues)
    , [data, modalSearchTableHeader, search,
      currentPage, rowsPerPage, searchType, isLoading, filterTrigger, getValues])

  useEffect(() => data.length > 0 && setTableParametters(preVal => ({ ...preVal, count: data.length })), [data])
  useEffect(() => isOpenModalSearch && fnGetData(), [isOpenModalSearch])

  return <>
    {isOpenModalSearch && <TaDialog
      title={title}
      onClose={fnOnClose}
      isOpen={true}
      maxWidth={'md'}
      showLoader={true}
      loading={isLoading}
      content={
        <form key={`ModalSearch => ${searchType}`} onSubmit={handleSubmit(fnOnSearch)} >
          <Grid container alignContent='flex-start' alignItems='center' spacing={2}>
            <Grid item xs={8} sm={9} md={10} lg={11} xl={11}>
              <TaTextField
                register={register('search')}
                label={txtSearchLang.label[languageState]}
                placeholder={txtSearchLang.placeholder[languageState]}
                disabled={isLoading}
                onKeyDown={fnOnKeyDown}
                helperText={''}
                type={'search'} />
            </Grid>
            <Grid item xs={4} sm={3} md={2} lg={1} xl={1} align='right'>
              <TaFab id={`btnSearch => ${searchType}`} icon={"search"} type={'submit'} disabled={isLoading} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='right'>
              <TaTable
                column={modalSearchColumnName}
                body={modalSearchTableBody}
                usePagination={true}
                count={count}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                currentPage={currentPage}
                handleOnChangePage={fnOnChangePage}
                handleonClickPagination={fnOnClickPagination}
                height={height} />
            </Grid>
          </Grid>
        </form>
      }
      actions={
        <Grid container alignContent='flex-start'>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
            <TaFab id={'btnCloseModalSearch'} icon="clear" color={'red'} onClick={fnOnClose} disabled={isLoading} />
          </Grid>
        </Grid>
      }
    />}
  </>
}

export default ModalSearch
