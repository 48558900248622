import { fnGetUrl, fnPostUrl } from "functions/Api"
import { userApprovedModel, userPostChangeEmail } from "./UserManagementModels"


export const postApproved = (data) => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/Supplier/Approved',
        body: userApprovedModel(data),
        useBasic: true
    }
    return fnPostUrl(apiRequest)
}

export const postChangeEmail = (data) => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/Supplier/ChangeEmail',
        body: userPostChangeEmail(data),
        useBasic: true,
    }
    return fnPostUrl(apiRequest)
}
export const getSupplierSaintNoAsig = () => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/Supplier/SupplierSaintNoAsig',
        useBasic: true
    }
    return fnGetUrl(apiRequest)
}
export const getSupplierAll = () => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/Supplier/SupplierAll',
        useBasic: true
    }
    return fnGetUrl(apiRequest)
}

export const getSupplierExcel = () => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/Supplier/SupplierExcel',
        useBasic: true
    }
    return fnGetUrl(apiRequest)
}
export const postActiveInactiveStatus = (data) => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/Supplier/ActiveInactiveStatus',
        body: data,
        useBasic: true
    }
    return fnPostUrl(apiRequest)
}
export const postDeleteSupplier = (data) => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/Supplier/DeleteSupplier',
        body: { id: data },
        useBasic: true
    }
    return fnPostUrl(apiRequest)
}

