import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import logo from './logo.svg';
import './App.css';
import Login from 'views/Login';
import Register from 'views/Register';
import Home from 'views/Home';
import RecoverPass from 'views/RecoverPass';
import UserProfile from 'views/UserProfile';
import { LanguageProvider } from 'context/Language/LanguageContext';
import { SnackbarProvider } from 'context/Snackbar/SnackbarContext';
import { QueryParamProvider } from 'use-query-params'
import TaSnackbar from 'components/snackbar/TaSnackbar';
import ModalSearch from 'projectComponent/ModalSearch/ModalSearch';
import { ModalSearchProvider } from 'context/ModalSearch/ModalSearchContext';
import { LoginProvider } from 'context/Login/LoginContext';
import {
  EMAIL_VERIFICATION,
  HOME,
  LOGIN,
  RECOVER_PASSWORD,
  REGISTER,
  USER_PROFILE
} from './constants/appRoutes';
import EmailVerification from 'views/EmailVerification';
import { LoaderProvider } from 'context/Loader/LoaderContext';

const App = () => {
  return (
    <Router>
      <LoaderProvider>
        <LanguageProvider>
          <SnackbarProvider>
            <LoginProvider>
              <ModalSearchProvider>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <Switch>
                    <Route exact path={LOGIN} component={Login} />
                    <Route exact path={REGISTER} component={Register} />
                    <Route exact path={HOME} component={Home} />
                    <Route exact path={RECOVER_PASSWORD} component={RecoverPass} />
                    <Route exact path={USER_PROFILE} component={UserProfile} />
                    <Route exact path={USER_PROFILE} component={UserProfile} />
                    <Route exact path={EMAIL_VERIFICATION} component={EmailVerification} />
                  </Switch>
                  <TaSnackbar />
                  <ModalSearch />
                </QueryParamProvider>
              </ModalSearchProvider>
            </LoginProvider>
          </SnackbarProvider>
        </LanguageProvider>
      </LoaderProvider>
    </Router>

  );
}

export default App;
