import generalLanguages from 'lang/Lang'

const modalSearchLanguages = {
  ...generalLanguages,
  txtSearchLang: {
    label: {
      ES: 'Buscar',
      EN: 'Search',
      CN: '搜索'
    },
    placeholder: {
      ES: 'Ingrese el código o descripción',
      EN: 'Enter code or description',
      CN: '輸入代碼或說明'
    }
  },
  tableColumnLang: {
    id: {
      ES: 'Código',
      EN: 'ID',
      CN: 'ID'
    },
    name: {
      ES: 'Nombre',
      EN: 'Name',
      CN: '芋头'
    },
    customerClass: {
      ES: 'Clase',
      EN: 'Class',
      CN: '班级'
    },
    existence: {
      ES: 'Existencia',
      EN: 'Existence',
      CN: '存在'
    },
  }
}

export default modalSearchLanguages
