import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const MultiTileFilter = () => {
    return (
        <SvgIcon viewBox="0 0 6.6 6.7">
            <path 
                className="st0" 
                d="M4.3,6H0.1V0.1H6v4.1 M1.5,1H0.8v0.8h0.8V1z M2.9,1H2.1v0.8h0.8V1z M4.2,1H3.4v0.8h0.8V1z M5.4,1H4.6v0.8h0.8V1
                z M1.5,2.4H0.8v0.8h0.8V2.4z M2.9,2.4H2.1v0.8h0.8V2.4z M4.2,2.4H3.4v0.8h0.8V2.4z M5.4,2.4H4.6v0.8h0.8V2.4z M1.5,3.8H0.8v0.8h0.8
                V3.8z M2.9,3.8H2.1v0.8h0.8V3.8z M4.2,3.8H3.4v0.8h0.8V3.8z M5.4,3.8H4.6v0.8h0.8V3.8z M6.4,5.5H5.8V4.9H5.4v0.7H4.7v0.4h0.7v0.7
                h0.4V5.9h0.7V5.5z"/>
        </SvgIcon>
    )
}

export default MultiTileFilter