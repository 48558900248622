import { Grid, Paper } from '@mui/material'
import TaImg from 'components/img/TaImg'
import TaPaper from 'components/paper/TaPaper'
import useDinamicHeights from 'helper/useDinamicHeights'
import useWindowSize from 'helper/UseWindowsSize'
import RegisterForm from 'projectComponent/Register/RegisterForm'
import { loginStylesRegisterUser } from 'projectComponent/Register/RegisterStyles'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ToolsPROv from 'projectImages/ToolsPROVwebLogo/v2.png'


const Register = () => {
    const { registerPaperStyles, registerContainerStyles } = useDinamicHeights(loginStylesRegisterUser)
    const { height } = useWindowSize()
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: height, backgroundImage: 'var(--bgImage)' }}>
            {/* <Grid item xs={11} sm={11} md={9} lg={9} xl={8} align={'center'}> */}
            <TaPaper id={'registerPaper'} centerDiv={true} elevation={3}
                Content={
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} align={'center'}>
                            <TaImg inStyle={{ width: '24vw' }} url={ToolsPROv} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                            <GoogleReCaptchaProvider reCaptchaKey="6Les6m4gAAAAANEa_iIrcBANGjn4Gu6fdutbkAYs">
                                <RegisterForm />
                            </GoogleReCaptchaProvider>
                        </Grid>
                    </Grid>
                } />
            {/* </Grid> */}

            {/* <Grid item xs={11} sm={12} md={12} lg={12} xl={12} align={'center'}></Grid>
            <Grid item xs={11} sm={12} md={10} lg={8} xl={8} align={'center'}> */}
            {/* <Paper id={'registerPaper'} elevation={3} sx={registerPaperStyles.height}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        {/* <Grid item xs={12} sm={12} md={10} lg={10} xl={10} align={'center'}>
                            <TaImg inStyle={{ width: '20%' }} url={ToolsPROv} />
                        </Grid> */}
            {/* <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <GoogleReCaptchaProvider reCaptchaKey="6Les6m4gAAAAANEa_iIrcBANGjn4Gu6fdutbkAYs">
                                <RegisterForm />
                            </GoogleReCaptchaProvider>
                        </Grid>
                    </Grid>
                </Paper> */}
            {/* // </Grid> */}
        </Grid>

    )
}

export default Register