const generalLanguages = {
    txtErrorsRequiredLang: {
        ES: 'Campo requerido',
        EN: 'Required field',
        CN: '必填項目'
    },
    currencyListLang: {
        label: {
            ES: "Moneda",
            EN: "Currency",
            CN: "貨幣"
        },
    },
    textPasswordLang: {
        label: {
            ES: "Contraseña",
            EN: "Password",
            CN: "密碼"
        },
        placeholder: {
            ES: 'Ingrese la contraseña',
            EN: 'Enter a password',
            CN: '輸入密碼',
        }
    },
    textUserLang: {
        label: {
            ES: "Usuario",
            EN: "User",
            CN: "用户",
        },
        placeholder: {
            ES: 'Ingrese el usuario',
            EN: 'Enter an user',
            CN: '輸入用戶',
        }
    },
    txtDateIniLang: {
        label: {
            ES: "Fecha desde",
            EN: "Since date",
            CN: "自日期",

        },
    },
    txtDateEndLang: {
        label: {
            ES: "Fecha hasta",
            EN: "Until date",
            CN: "截止日期",

        },
    },
    txtIniTimeLang: {
        label: {
            ES: "Hora de inicio",
            EN: "Start time",
            CN: "开始时间",

        },
    },
    txtEndTimeLang: {
        label: {
            ES: "Hora final",
            EN: "Final hour",
            CN: "最后一小时",

        },
    },
    txtDateLang: {
        label: {
            ES: "Fecha",
            EN: "Date",
            CN: "截止日期",

        },
    },
    txtCustomerLang: {
        label: {
            ES: 'Cliente',
            EN: 'Customer',
            CN: '顧客',
        },
        helperText: {
            ES: '',
            EN: '',
            CN: '',
        },
    },
    txtDepositLang: {
        label: {
            ES: 'Deposito',
            EN: 'Deposit',
            CN: '訂金'
        }
    },
    authorizeReturnHomeLang: {
        ES: '¿Desea regresar al Home?',
        EN: 'want to return to Home?',
        CN: '想回家吗？',
    }
}
export default generalLanguages
