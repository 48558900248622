import { Grid } from '@mui/material'
import TaFab from 'components/fab/TaFab'
import { HOME } from 'constants/appRoutes'
import HeaderBar from 'projectComponent/HeaderBar/HeaderBar'
import UserProfileForm from 'projectComponent/UserProfile/UserProfileForm'
import { useHistory } from 'react-router-dom'

const UserProfile = () => {
  const history = useHistory()

  const fnModule = () => history.push(HOME)

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <HeaderBar
          rightlinks={<TaFab id={'btnLogOut'} icon={'clear'} color={'red'} onClick={fnModule} />}
        />
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
        <UserProfileForm />
      </Grid>
    </Grid>
  )
}

export default UserProfile