import { useContext } from 'react'
import { SnackbarContext } from 'context/Snackbar/SnackbarContext'
import { useHistory } from 'react-router-dom'
import { LOGIN } from 'constants/appRoutes'
import { LoginContext } from 'context/Login/LoginContext'

const useCheckIsAuthorized = () => {
    const { sbDispatch } = useContext(SnackbarContext)
    const { loginDispatch: { loginReset } } = useContext(LoginContext)


    const fnForceLogout = (response) => {
        sbDispatch.error((response.data.message) ? response.data.message : 'Su sesión a expirado, debe ingresar nuevamente')
        loginReset()
    }

    return fnForceLogout
}

export default useCheckIsAuthorized