import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js'
import { memo } from 'react'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend
)

const TaLineChart = memo(({ labels = [], datasets = [], id = '', fontSize = 26, height = '20vh' }) => {
  return (
    <Line
      id={id}
      options={{
        responsive: true,
        font: {
          size: '12pt',
          family: 'Poppins',
        },
        plugins: {
          legend: {
            position: 'top',
            labels: {
              font: {
                size: '12pt',
                family: 'Poppins',
              }
            },
          },
        },

      }}
      data={{ labels, datasets }}
      height={height}
    />
  )
})

export default TaLineChart