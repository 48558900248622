


import { fnGetUrl } from "functions/Api"

export const getPDFARCVDocument = (data) => {
    const apiRequest = {
        endpoint: `/api/ProvWeb/Supplier/PDFARCVDocument?fiscalPeriod=${data.fiscalPeriod}`,
        useBasic: true
    }
    return fnGetUrl(apiRequest)
}
