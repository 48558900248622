import Print from '@mui/icons-material/Print';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Toc from '@mui/icons-material/Toc';
import Key from '@mui/icons-material/VpnKey';
import RestoreIcon from '@mui/icons-material/Restore';
import CollectionsIcon from '@mui/icons-material/Collections';
import GridOnIcon from '@mui/icons-material/GridOn';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Sync from '@mui/icons-material/Sync';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AddLocation from '@mui/icons-material/AddLocation';
import GridOn from '@mui/icons-material/GridOn';
import Receipt from '@mui/icons-material/Receipt';
import MergeType from '@mui/icons-material/MergeType';
import CallMade from '@mui/icons-material/CallMade';
import OpenInNew from '@mui/icons-material/OpenInNew';
import LockOpen from '@mui/icons-material/LockOpen';
import NotInterested from '@mui/icons-material/NotInterested';
import Message from '@mui/icons-material/Message';
import Telegram from "@mui/icons-material/Telegram";
import MoneyIcon from '@mui/icons-material/Money';
import CommentIcon from '@mui/icons-material/Comment';
import File from '@mui/icons-material/Description'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Dehaze';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/SettingsSharp';
import Storage from '@mui/icons-material/Storage';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import Totalize from '@mui/icons-material/Done';
import Home from '@mui/icons-material/Home';
import Upload from '@mui/icons-material/Unarchive';
import CashIcon from '@mui/icons-material/MonetizationOn';
import CardIcon from '@mui/icons-material/CreditCard';
import List from '@mui/icons-material/ListAlt';
import Star from '@mui/icons-material/Star';
import FileCopy from '@mui/icons-material/FileCopy';
import SystemUpdate from '@mui/icons-material/SystemUpdate';
import CloudDownload from '@mui/icons-material/CloudDownload';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
// //COMPONENTS
// import EraseIcon from './icons/EraseIcon'
// import EditImageIcon from './icons/EditImageIcon'
// import ImageIcon from './icons/ImagesIcon'
// import PercentIcon from './icons/PercentIcon'
// import PriceVisorIcon from './icons/PriceVisorIcon'
// import ExactPayment from './icons/ExactPayment'
// import BarcodeScan from './icons/BarcodeScan'
// import FE from './icons/FE'
// import OneTileFilter from './icons/OneTileFilter'
// import FourTileFilter from './icons/FourTileFilter'
// import MultiTileFilter from './icons/MultiTileFilter'
// import AccountCash from './icons/AccountCash'
// import PrinterPos from './icons/PrinterPos'
// import DownloadDoc from './icons/DownloadDocument'

const badgeIcons = (iconType) => {
  switch (iconType) {
      case "add":
          return <AddIcon sx={{height: '18px'}} />
      case "backArrow":
          return <ArrowBackIcon sx={{height: '18px'}} />
      case "forwardArrow":
          return <ArrowForwardIcon sx={{height: '18px'}} />
      case "clear":
      case "exit":
          return <ClearIcon sx={{height: '18px'}} />
      case "delete":
          return <DeleteIcon sx={{height: '18px'}} />
      case "done":
          return <DoneIcon sx={{height: '18px'}} />
      case "edit":
          return <EditIcon sx={{height: '18px'}} />
      case "erase":
        break

          // return <EraseIcon sx={{height: '18px'}} />
      case "info":
          return <InfoIcon sx={{height: '18px'}} />
      case "menu":
          return <MenuIcon sx={{height: '18px'}} />
      case "percent":
        break
          // return <PercentIcon sx={{height: '18px'}} />
      case "pricevisor":
        break
          // return <PriceVisorIcon sx={{height: '18px'}} />
      case "remove":
          return <RemoveIcon sx={{height: '18px'}} />
      case "save":
          return <SaveIcon sx={{height: '18px'}} />
      case "search":
          return <SearchIcon sx={{height: '18px'}} />
      case "setting":
          return <SettingsIcon sx={{height: '18px'}} />
      case "send":
          return <SendIcon sx={{height: '18px'}} />
      case "storage":
          return <Storage sx={{height: '18px'}} />
      case "arrow":
      case "totalarrow":
          return <ArrowIcon sx={{height: '18px'}} />
      case "total":
      case "check":
          return <Totalize sx={{height: '18px'}} />
      case "home":
          return <Home sx={{height: '18px'}} />
      case "upload":
          return <Upload sx={{height: '18px'}} />
      case "cash":
          return <CashIcon sx={{height: '18px'}} />
      case "card":
          return <CardIcon sx={{height: '18px'}} />
      case "list":
          return <List sx={{height: '18px'}} />
      case "favorite":
          return <Star sx={{height: '18px'}} />
      case "favoriteB":
      case "editFavsSelected":
          return <StarBorderOutlined sx={{height: '18px'}} />
      case "image":
          break
          // return <ImageIcon sx={{height: '18px'}} />
      case "editImage":
        break

          // return <EditImageIcon sx={{height: '18px'}} />
      case "editImageSelected":
        break

          // return <EditImageIcon sx={{height: '18px'}} />
      case "systemUpdate":
          return <SystemUpdate sx={{height: '18px'}} />
      case "cloudDownload":
          return <CloudDownload sx={{height: '18px'}} />
      // case "exactPayment":
          // return <ExactPayment sx={{height: '18px'}} />
      case "FileCopy":
          return <FileCopy sx={{height: '18px'}} />
      case "camera":
          return <PhotoCamera sx={{height: '18px'}} />
      case 'user':
          return <AccountCircle sx={{height: '18px'}} />
      case 'disableUser':
          return <AccountCircle sx={{height: '18px'}} />
      case 'printer':
          return <Print sx={{height: '18px'}} />
      // case 'barcodeScan':
          // return <BarcodeScan sx={{height: '18px'}} />
      case '...':
          return <MoreVertIcon sx={{height: '18px'}} />
      case 'visibility':
          return <Visibility sx={{height: '18px'}} />
      case 'visibilityOff':
          return <VisibilityOff sx={{height: '18px'}} />
      // case 'oneTileFilter':
          // return <OneTileFilter sx={{height: '18px'}} />
      // case 'fourTileFilter':
          // return <FourTileFilter sx={{height: '18px'}} />
      // case 'multiTileFilter':
          // return <MultiTileFilter sx={{height: '18px'}} />
      case 'multiSelect':
          return <Toc sx={{height: '18px'}} />
      case 'key':
          return <Key sx={{height: '18px'}} />
      // case 'FE':
          // return <FE sx={{height: '18px'}} />
      // case 'accountCash':
          // return <AccountCash sx={{height: '18px'}} />
      // case 'printerPos':
          // return <PrinterPos sx={{height: '18px'}} />
      case 'restore':
          return <RestoreIcon sx={{height: '18px'}} />
      case 'mapType':
          return <CollectionsIcon sx={{height: '18px'}} />
      case 'listMode':
          return <GridOnIcon sx={{height: '18px'}} />
      case 'mergeTables':
          return <LibraryAddIcon sx={{height: '18px'}} />
      case 'clearTable':
          return <DeleteSweepIcon sx={{height: '18px'}} />
      case 'sync':
          return <Sync sx={{height: '18px'}} />
      case 'disable':
          return <NotInterestedIcon sx={{height: '18px'}} />
      // case 'downloadDoc':
          // return <DownloadDoc sx={{height: '18px'}} />
      case 'addLocation':
          return <AddLocation sx={{height: '18px'}} />
      case 'polygon':
          return <GridOn sx={{height: '18px'}} />
      case "merge":
          return <MergeType sx={{height: '18px'}} />
      case "document":
          return <Receipt sx={{height: '18px'}} />
      case "moveDoc":
          return <CallMade sx={{height: '18px'}} />
      case "openTable":
          return <OpenInNew sx={{height: '18px'}} />
      case "freeTable":
      case "lockOpen":
          return <LockOpen sx={{height: '18px'}} />
      case "cancel":
          return <NotInterested sx={{height: '18px'}} />
      case "message":
          return <Message sx={{height: '18px'}} />
      case "telegram":
          return <Telegram sx={{height: '18px'}} />
      case 'money':
          return <MoneyIcon sx={{height: '18px'}} />
      case 'comment':
          return <CommentIcon sx={{height: '18px'}} />
      case 'file':
          return <File sx={{height: '18px'}} />
      case 'backupRestore':
          return <SettingsBackupRestoreIcon sx={{height: '18px'}} />
      case 'arrowUp':
          return <ArrowUpwardIcon sx={{height: '18px'}} />
      case 'arrowDown':
          return <ArrowDownwardIcon sx={{height: '18px'}} />
      default:
          return null
  }
}

export default badgeIcons