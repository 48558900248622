import { fnGetUrl } from 'functions/Api'

export const getPaymentsByMonth = () => {
  const apiRequest = {
    endpoint: `/api/ProvWeb/Supplier/TPayByMonth`,
    useBasic: true,
  }
  return fnGetUrl(apiRequest)
}

export const getBalance = () => {
  const apiRequest = {
    endpoint: `/api/ProvWeb/Supplier/Balance`,
    useBasic: true,
  }
  return fnGetUrl(apiRequest)
}