export const TaSearch = (data, filter, generic = false) => {
  let newData = data === undefined ? [] : data
  let typeSearch
  try {
      // typeSearch = getUserConfig().configSp.isSaintSearch PENDIENTE POR MIGRAR DESDE REDUX
  } catch (error) {
      typeSearch = false
  }

  if (filter !== '') {
      const newFilter = String(filter.toLowerCase()).replace(/\*/g, ".*")
      const newMatch = typeSearch === false ? new RegExp('^.*' + newFilter + '.*$') : new RegExp('^' + newFilter + '.*$')

      if (generic === false) {
          return newData.filter(x => (newMatch.test(String(x.id).toLowerCase()) === true ||
              newMatch.test(String(x.name + (x.descrip2 !== undefined ? x.descrip2 : '') + (x.descrip3 !== undefined ? x.descrip3 : '')).toLowerCase()) === true ||
              newMatch.test(String(x.refere !== undefined ? String(x.refere) : '').toLowerCase()) === true) && x.active === 1).slice(0, 100)
      } else {
          return newData.filter(x => (newMatch.test(String(x.id).toLowerCase()) === true ||
              newMatch.test(String(x.name).toLowerCase()) === true)).slice(0, 100)
      }

  } else {
      return newData
  }

}