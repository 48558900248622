export const transactionLanguages = {
    tableTransactionsLang: [
        {
            ES: 'Fecha',
            EN: 'Date',
            CN: '截止日期',

        },
        {
            ES: "Tipo",
            EN: "Type",
            CN: "類型"
        },
        {
            ES: "Descripción",
            EN: "Description",
            CN: "描述"
        },
        {
            ES: "Documento",
            EN: "Document",
            CN: "文檔"
        },
        {
            ES: "Monto",
            EN: "Amount",
            CN: "數量"
        },
    ], 
    typeTransactionsLang:[
        {
            ES: "Todos",
            EN: "All",
            CN: "全部"
        },
        {
            ES: "Pago",
            EN: "Payment",
            CN: "支付"
        },
        {
            ES: "RetIva",
            EN: "Withholding tax",
            CN: "預扣稅"
        },
        {
            ES: "ISLR",
            EN: "ISRL",
            CN: "代扣代繳"
        },
    ]
}