import generalLanguages from "lang/Lang";

export const recoveryPassLanguage = {
    ...generalLanguages,
    btnVerifyLang: {
        ES: "Verificar",
        EN: "Verify",
        CN: "核實"
    },
    linkBackLoginLang:{
        ES: "Regresar al login",
        EN: "Back to login",
        CN: "回到登入"

    },
    textVerificationInfoLang:{
        ES: "Verificando su información, por favor espere",
        EN: "Verifying your information, please wait",
        CN: "正在驗證您的信息，請稍候"

    },
}