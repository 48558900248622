import { Grid, Paper } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { registerLanguages } from './RegisterLanguages'
import { codRifModel, defaultValueRegisterForm, mockDataRegister } from './RegisterModel'
import { LanguageContext } from 'context/Language/LanguageContext'
import TaTextField from 'components/textfield/TaTextField'
import {
    regexAddress, regexCedula, regexEmail,
    regexPass,
    regexRifRegister
} from "helper/RegExp"
import TaAdornedTextField from 'components/textfield/TaAdornedTextField'
import TaListTextField from 'components/textfield/TaListTextField'
import { getReCaptchaV3Register, postRegister } from './ApiRegister'
import { isOK } from 'helper/RestStatus'
import { SnackbarContext } from 'context/Snackbar/SnackbarContext'
import TaTypography from 'components/typography/TaTypography'
import { LOGIN } from 'constants/appRoutes'
import Loader from 'components/loader/Loader'
import TaButton from 'components/button/TaButton'
import { Link } from 'react-router-dom';
import { LoaderContext } from 'context/Loader/LoaderContext';
import TaPaper from 'components/paper/TaPaper';
import useWindowSize from 'helper/UseWindowsSize';
import { fnCalculateHeights } from 'helper/fnCalculateHeights';

const { txtRifLang, txtErrorsRequiredLang, textEmailLang,
    textVerifyEmailLang, textBusinessNameLang, stateListLang,
    codePhoneLang, textPasswordLang,
    textAddressLang, textContactPersonLang,
    textVerifyPasswordLang, linkReturnLoginLang, btnRegisterLang } = registerLanguages

const RegisterForm = () => {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const { handleSubmit, register, formState: { errors, isSubmitSuccessful },
        getValues, control, watch, reset } = useForm({ defaultValues: defaultValueRegisterForm })
    const watchCodRif = watch('codRif')

    const { isLoading, setIsLoading } = useContext(LoaderContext)
    const { sbDispatch } = useContext(SnackbarContext)
    const { languageState } = useContext(LanguageContext)
    const [{ passVisibility, confPassVisibility }, setIsVisibility] = useState({ passVisibility: false, confPassVisibility: false })
    const fnIsVisibility = (name) => setIsVisibility(preVal => ({ ...preVal, [name]: !preVal[name] }))
    const { height } = useWindowSize()

    const fnOnPost = async (data) => {
        setIsLoading(true)
        try {
            const response = await postRegister({ ...data, fi: `${data.codRif}${data.fi}` })
            sbDispatch.apiV2(response.status, response.data)
            if (response.data.errorCode === 0) reset()
        } catch (error) { console.error('error fnOnPost', error) }
        setIsLoading(false)
    }
    const fnExecuteRecaptchaV3 = useCallback(async () => {
        setIsLoading(true)
        if (isSubmitSuccessful) {
            try {
                if (!executeRecaptcha) {
                    setIsLoading(false)
                    return;
                }
                const tokenCapchaV3 = await executeRecaptcha();
                const response = await getReCaptchaV3Register(tokenCapchaV3)
                if (isOK(response.status)) {
                    fnOnPost(getValues())
                } else {
                    sbDispatch.api(response.status, response.data);
                }
            } catch (error) { console.error('Error de fnExecuteRecaptchaV3', error) }
        }
        setIsLoading(false)
    }, [isSubmitSuccessful])

    return (
        <form key={'RegisterForm'} onSubmit={handleSubmit(fnExecuteRecaptchaV3)}>
            <Grid container justifyContent={'center'} alignItems='center'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TaPaper id={'registerForm'} squareBorder={true} elevation={0} margin='5px'overflow='auto' height={350}
                        Content={
                            <Grid container justifyContent={'center'} alignItems='center' spacing={1}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <TaAdornedTextField
                                        name={'fi'}
                                        label={`${txtRifLang[languageState]}*`}
                                        control={control}
                                        type={'number'}
                                        helperText={'Ejm. J 000000000'}
                                        disabled={isLoading}
                                        rules={{
                                            required: txtErrorsRequiredLang[languageState],
                                            validate: value => 
                                                watchCodRif==='V'?regexCedula.value.test(`${watchCodRif}${value}`) || regexCedula.message
                                                :regexRifRegister.value.test(`${watchCodRif}${value}`) || regexRifRegister.message,
                                        }}
                                        error={errors['fi']?.message}
                                        adornment={<TaListTextField
                                            name={'codRif'}
                                            control={control}
                                            options={codRifModel}
                                            disabled={isLoading}
                                            error={errors['codRif']?.message}
                                        />} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <TaTextField
                                        label={`${textBusinessNameLang.label[languageState]}*`}
                                        register={register('bussinessName', {
                                            required: txtErrorsRequiredLang[languageState],
                                        })}
                                        error={errors['bussinessName']?.message}
                                        helperText={' '}
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TaTextField
                                        label={`${textAddressLang.label[languageState]}*`}
                                        register={register('direction', {
                                            required: txtErrorsRequiredLang[languageState],
                                            pattern: regexAddress
                                        })}
                                        error={errors['direction']?.message}
                                        helperText={' '}
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <TaTextField
                                        label={`${textEmailLang.label[languageState]}*`}
                                        register={register('email', {
                                            required: txtErrorsRequiredLang[languageState],
                                            pattern: regexEmail
                                        })}
                                        error={errors['email']?.message}
                                        helperText={'Ejm. ejemplo@gmail.com'}
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <TaTextField
                                        label={`${textVerifyEmailLang.label[languageState]}*`}
                                        register={register('verifyEmail', {
                                            required: txtErrorsRequiredLang[languageState],
                                            pattern: regexEmail,
                                            validate: value => (getValues('email') === value) || textVerifyEmailLang.validate[languageState]
                                        })}

                                        error={errors['verifyEmail']?.message}
                                        helperText={' '}
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <TaTextField
                                        label={`${codePhoneLang.label[languageState]}*`}
                                        register={register('phone', {
                                            required: txtErrorsRequiredLang[languageState],
                                        })}
                                        type={'number'}
                                        error={errors['phone']?.message}
                                        helperText={'Ejm. 02123230117'}
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <TaTextField
                                        label={`${textContactPersonLang.label[languageState]}*`}
                                        register={register('contact', {
                                            required: txtErrorsRequiredLang[languageState],
                                        })}
                                        error={errors['contact']?.message}
                                        helperText={' '}
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{height: '18px'}}>
                                    <TaAdornedTextField
                                        name={'password'}
                                        label={`${textPasswordLang.label[languageState]}*`}
                                        control={control}
                                        type={(!passVisibility) ? 'password' : 'text'}
                                        helperText={'Debe contener al menos un número, una letra en mayúscula y 6 caracteres'}
                                        disabled={isLoading}
                                        rules={{
                                            required: txtErrorsRequiredLang[languageState],
                                            pattern: regexPass
                                        }}
                                        error={errors['password']?.message}
                                        adornmentPosition={'end'}
                                        adornment={(!passVisibility)
                                            ? <VisibilityOffIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => isLoading ? '' : fnIsVisibility('passVisibility')} />
                                            : <VisibilityIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => isLoading ? '' : fnIsVisibility('passVisibility')} />
                                        } />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{height: '18px'}}>
                                    <TaAdornedTextField
                                        name={'verifyPass'}
                                        label={`${textVerifyPasswordLang.label[languageState]}*`}
                                        control={control}
                                        type={(!confPassVisibility) ? 'password' : 'text'}
                                        helperText={' '}
                                        disabled={isLoading}
                                        rules={{
                                            required: txtErrorsRequiredLang[languageState],
                                            pattern: regexPass,
                                            validate: value => (getValues('password') === value) || textVerifyPasswordLang.validate[languageState]
                                        }}
                                        error={errors['verifyPass']?.message}
                                        adornmentPosition={'end'}
                                        adornment={(!confPassVisibility)
                                            ? <VisibilityOffIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => isLoading ? '' : fnIsVisibility('confPassVisibility')} />
                                            : <VisibilityIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => isLoading ? '' : fnIsVisibility('confPassVisibility')} />
                                        } />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    < GoogleReCaptcha onVerify={fnExecuteRecaptchaV3} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}></Grid>
                            </Grid>
                        } />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id='itemlinkAndBtn'>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} align={'left'}>
                            <Link style={{ color: 'var(--main-text-color2)' }} underline={'hover'} to={isLoading ? '' : LOGIN} > {linkReturnLoginLang[languageState]} </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} align={'right'}>
                            <TaButton
                                label={btnRegisterLang[languageState]}
                                type={"submit"}
                                disabled={isLoading}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Loader isLoading={isLoading} />
            </Grid>
        </form>
    )
}

export default RegisterForm