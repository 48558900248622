import { Grid } from "@mui/material";
import TaDialog from "components/dialog/TaDialog";
import { useForm } from "react-hook-form";
import TaTypography from "components/typography/TaTypography";
import TaTextField from "components/textfield/TaTextField";
import TaButton from "components/button/TaButton";
import { useState, useContext } from "react";
import { postCommercialAlly, postValidateAllyCode } from "./CommercialAllyApi"
import { isOK } from "helper/RestStatus";
import { regexVeryfCode } from "helper/RegExp"
import commercialAllyLanguages from "./commercialAllyLanguages";
import { LanguageContext } from "context/Language/LanguageContext";
import { LoaderContext } from "context/Loader/LoaderContext";
import { SnackbarContext } from "context/Snackbar/SnackbarContext";
import { Link } from "react-router-dom";

const { lblWelcomeLang, txtComercialAllyLang, txtCodeAllyLang,
    txtErrorsRequiredLang, businessInforLang, btnVerifyLang,
    btnValidateLang, titleLang } = commercialAllyLanguages

const CommercialAlly = ({ onClose }) => {

    const { handleSubmit, register, formState: { errors }, watch, reset } = useForm({ defaultValues: { allyCode: '' } })
    const watchAllyCode = watch('allyCode')
    const { handleSubmit: handleSubmit2, register: register2, formState: { errors: errors2 }, reset: reset2 } = useForm({ defaultValues: { keyCode: '' } })
    const { languageState } = useContext(LanguageContext)
    const { sbDispatch } = useContext(SnackbarContext)
    const [isLoading, setIsLoading] = useState(false)

    const [isKeyCode, setIsKeyCode] = useState(false)

    const fnOnSubmit = async (data) => {
        setIsLoading(true)
        try {
            const response = await postCommercialAlly(data.allyCode)
            if (response.status === 200 && response.data.errorCode === 0) setIsKeyCode(true)
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) { console.error('fnOnSubmit => ', error) }
        setIsLoading(false)
    }
    //------------------------------------------------------------------------------------
    const fnOnSubmit2 = async (data) => {
        setIsLoading(true)
        try {
            const response = await postValidateAllyCode(data.keyCode, watchAllyCode)
            if (response.status === 200 && response.data.errorCode === 0) {
                reset()
                reset2()
                onClose(false)}
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) { console.error('fnOnSubmit2 => ', error) }
        setIsLoading(false)
    }
    return (
        <TaDialog
            isOpen={true}
            onClose={onClose}
            title={titleLang[languageState]}
            content={
                <>
                    <form key={'allyCodeForm'} onSubmit={handleSubmit(fnOnSubmit)}>
                        <Grid container direction="row" justifyContent="center" alignItems={'center'}  >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                                <TaTypography text={lblWelcomeLang[languageState]} align={'center'} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ marginTop: '30px' }}>
                                <TaTextField
                                    register={register('allyCode', {
                                        required: txtErrorsRequiredLang[languageState]
                                    })}
                                    label={txtComercialAllyLang.label[languageState]}
                                    placeholder={txtComercialAllyLang.placeHolder[languageState]}
                                    disabled={isLoading || isKeyCode}
                                    helperText={' '}
                                    error={errors['allyCode']?.message} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} align={'left'}>
                                <TaButton
                                    id={'btnVerify'}
                                    label={btnVerifyLang[languageState]}
                                    disabled={isLoading || isKeyCode}
                                    size={'small'}
                                    type={'submit'}>
                                </TaButton>
                            </Grid>
                        </Grid>
                    </form>
                    <form key={'verificationCodeForm'} onSubmit={handleSubmit2(fnOnSubmit2)}>
                        <Grid container direction="row" justifyContent="center" alignItems={'center'} sx={{ marginTop: '20px' }}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TaTextField
                                    register={register2('keyCode', {
                                        required: txtErrorsRequiredLang[languageState],
                                        pattern: regexVeryfCode
                                    })}
                                    label={txtCodeAllyLang.label[languageState]}
                                    placeholder={txtCodeAllyLang.placeHolder[languageState]}
                                    required={txtErrorsRequiredLang[languageState]}
                                    disabled={isLoading || !isKeyCode}
                                    helperText={' '}
                                    error={errors2['keyCode']?.message} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} align={'left'}>
                                <TaButton
                                    id={'btnValidate'}
                                    label={btnValidateLang[languageState]}
                                    disabled={isLoading || !isKeyCode}
                                    size={'small'}
                                    type={'submit'}>
                                </TaButton>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} sx={{ marginTop: '20px' }}>
                                <TaTypography text={businessInforLang[languageState]} align={'center'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} sx={{ marginTop: '15px' }}>
                                <TaTypography color={'var(--main-text-color2)'} align={'center'} text-align='center'>
                                    <Link rel={'noopener noreferrer'} style={{ color: 'var(--main-text-color2)' }} underline={'hover'} to={isLoading ? '' : "http://totalcoin.totalaplicaciones.com/registro/"} >http://totalcoin.totalaplicaciones.com</Link>
                                </TaTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                            </Grid>
                        </Grid>
                    </form>
                </>
            }
            loading={isLoading}
        />
    )
}
export default CommercialAlly