import { fnPostUrl } from "functions/Api"

export const postUserPassword = (data) => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/Login/UserPassword',
        body: {fi:data.fi}
    }
    return fnPostUrl(apiRequest)
}

export const postChangePass = (token, data) => {
    delete data.verifyPass
    const apiRequest = {
        endpoint: `/api/ProvWeb/Login/ChangePass`,
        body: {
            newPass: data.pass,
            tokenPass: token,
        }
    }
    return fnPostUrl(apiRequest)
}

