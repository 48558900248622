import { Grid, Link } from '@mui/material'
import TaTable from 'components/table/TaTable'
import TaTableCell from 'components/table/TaTableCell'
import TaTableRow from 'components/table/TaTableRow'
import { fnCalculateHeights } from 'helper/fnCalculateHeights'
import useDinamicHeights from 'helper/useDinamicHeights'
import React, { memo, useContext } from 'react'
import moment from 'moment'
import { fnFormatNumb } from 'helper/FormatNumb'
import { getPDFDocument } from './ApiReportTransactions'
import { fnDownloadFile } from 'functions/Api'
import { SnackbarContext } from 'context/Snackbar/SnackbarContext'
import { transactionLanguages } from './TansactionsLanguages'
import useCheckIsAuthorized from 'hooks/useCheckIsAuthorized'

const { tableTransactionsLang } = transactionLanguages
const itemTableColumns = (languageState) => [
    {
        id: 0,
        label: tableTransactionsLang[0][languageState],
        align: 'center',
        width: '200px',
        hidden: false,
    },
    {
        id: 1,
        label: tableTransactionsLang[1][languageState],
        align: 'center',
        width: '150px',
        hidden: false,
    },
    {
        id: 2,
        label: tableTransactionsLang[2][languageState],
        align: 'center',
        width: '400px',
        hidden: false,
    },
    {
        id: 3,
        label: tableTransactionsLang[3][languageState],
        align: 'center',
        width: '400px',
        hidden: false,
    },
    {
        id: 4,
        label: tableTransactionsLang[4][languageState],
        align: 'right',
        width: '300px',
        hidden: false,
    },
]



const fnTableBody = (data, columns, fnOnClickItem) => data.map((x, i) => {

    return <TaTableRow key={`docTransactionTableRow:${x.uniqueKey}`} id={`${x.uniqueKey}`}>
        <TaTableCell width={columns[0].width} align={columns[0].align}>{moment(x.date).format("DD/MM/YYYY")}</TaTableCell>
        <TaTableCell width={columns[1].width} align={columns[1].align}>{x.type}</TaTableCell>
        <TaTableCell width={columns[2].width} align={columns[2].align}>{x.descrip}</TaTableCell>
        <TaTableCell width={columns[3].width} align={columns[3].align}><Link onClick={() => fnOnClickItem(x)}>{x.numberDoc}</Link></TaTableCell>
        <TaTableCell width={columns[4].width} align={columns[4].align}>{fnFormatNumb(x.amount)}</TaTableCell>
    </TaTableRow>
})


const itemTableHeights = (height) => ({
    table: {
        height: fnCalculateHeights([height, 285])
    }
})


const TableReportTransaction = memo(({ data, setIsLoading, isLoading, languageState }) => {
    const { sbDispatch } = useContext(SnackbarContext)
    const fnForceLogout = useCheckIsAuthorized()

    const fnOnClickItem = async (data) => {
        if (isLoading) {
            return sbDispatch.warning('Su consulta esta en proceso por favor espere')
        }
        setIsLoading(true)
        try {
            const response = await getPDFDocument(data)
            if (response.status === 401) {
                setIsLoading(false)
                return fnForceLogout(response)
            }
            if (response.data.errorCode === 0) {
                fnDownloadFile(response.data.data, `Reporte de ${data.type} ${moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}`);
            }
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) { console.error('Catch de la función para imprimir el reporte de las transacciones', error) }
        setIsLoading(false)
    }
    const { table } = useDinamicHeights(itemTableHeights)
    const itemTableHeader = itemTableColumns(languageState).map(x => <TaTableCell key={x.id} header={true} align={'center'} width={x.width} hidden={x.hidden}>{x.label}</TaTableCell>)
    const itemTableBody = fnTableBody(data, itemTableColumns(languageState), fnOnClickItem)
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaTable
                    column={itemTableHeader}
                    body={itemTableBody}
                    usePagination={false}
                    height={table.height} />
            </Grid>
        </Grid>
    )
})

export default TableReportTransaction