import generalLanguages from "lang/Lang"

const loginLanguages = {
    ...generalLanguages,
        btnLoginLang:{
            ES:"Ingresar",
            EN:"Login",
            CN:"登錄"
        },
        
        txtRegisterLang:{
            ES:"Registrese",
            EN:"Register",
            CN:"登記"
        },
        linkJoinNowLang:{
            ES:"aquí",
            EN:"here",
            CN:"這裡"
        },
        linkRecoverPasswordLang:{
            ES:"¿Olvidó su contraseña?",
            EN:"Forgot your password?",
            CN:"忘記密碼了嗎？"
        },
        titleLanguageSelectLang:{
            ES:"Idioma",
            EN:"Language",
            CN:"語"
        },
        txteModalVerificationWLoginLang:{
            ES:"Verificando su cuenta por favor espere",
            EN:"Verifying your account please wait",
            CN:"正在驗證您的帳戶，請稍候"

        },
    }
export default loginLanguages