import { memo } from 'react'
import propTypes from 'prop-types'
import { Table, TableBody, TableHead, TableRow, TablePagination, Paper } from '@mui/material/'
import TaTableRow from './TaTableRow';

const TaTable = memo(({ column, body, height,
  rowsPerPage, rowsPerPageOptions, currentPage,
  onClickPagination, usePagination, count,
  handleOnChangePage }) => {
  return (<>
    <Paper elevation={0} square={true} sx={{ height: height, overflow: 'auto', backgroundColor: 'var(--main-bg-color0)' }} >
      <Table>
        <TableHead>
          <TaTableRow>
            {column}
          </TaTableRow>
        </TableHead>
        <TableBody>
          {body}
        </TableBody>
      </Table>
    </Paper>
    {usePagination && <TablePagination
      component={'div'}
      count={count}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage=''
      showFirstButton={true}
      showLastButton={true}
      size={'small'}
      rowsPerPageOptions={rowsPerPageOptions}
      page={(currentPage)}
      SelectProps={{
        native: true,
      }}
      sx={{
        fontFamily: 'Poppins',
        fontSize: "11pt",
        color: "var(--main-text-color0)",
      }}
      onPageChange={handleOnChangePage}
      onRowsPerPageChange={onClickPagination} />}
  </>)
});

TaTable.propTypes = {
  column: propTypes.array,
  body: propTypes.array,
  height: propTypes.any,
  rowsPerPage: propTypes.number,
  currentPage: propTypes.number,
  rowsPerPageOptions: propTypes.array,
  onClickPagination: propTypes.func,
  usePagination: propTypes.bool,
}

TaTable.defaultProps = {
  column: [],
  body: [],
  height: '300px',
  rowsPerPage: 25,
  rowsPerPageOptions: [25],
  currentPage: 1,
  usePagination: true,
}

export default TaTable