import { fnGetUrl } from "functions/Api"

export const getTransactions = (data) => {
    const apiRequest = {
        endpoint: `/api/ProvWeb/Supplier/Transactions?dateFrom=${data.dateFrom}&dateTo=${data.dateTo}&type=${data.type}&numDoc=${data?.numDoc}&descrip=${data?.descrip}`,
        useBasic: true
    }
    return fnGetUrl(apiRequest)
}

export const getPDFDocument = (data) => {
    let newUrl = `/api/ProvWeb/Supplier/PDFDocument?numDoc=${data.numberDoc}&type=${data.type}`
    if (data.type === 'Pago') {
        newUrl= `/api/ProvWeb/Supplier/PDFDocument?type=${data.type}&uniqueNumb=${data.uniqueNumb}`
    }
    const apiRequest = {
        endpoint:newUrl,
        useBasic: true
    }
    return fnGetUrl(apiRequest)
}
