import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { v4 as uuid } from 'uuid'
import TaList from 'components/list/TaList'
import TaTextField from 'components/textfield/TaTextField'
import TaFab from 'components/fab/TaFab'
import TaPaper from 'components/paper/TaPaper'
import TaAdornedTextField from 'components/textfield/TaAdornedTextField'
import TaListTextField from 'components/textfield/TaListTextField'
import TaSwitch from 'components/switch/TaSwitch'
import { LanguageContext } from 'context/Language/LanguageContext'
import { SnackbarContext } from 'context/Snackbar/SnackbarContext'
import { ModalSearchContext } from 'context/ModalSearch/ModalSearchContext'
import { fnIncludeStrings } from 'helper/CompareValues'
import useDinamicHeights from 'helper/useDinamicHeights'
import fnDownloadFile from 'functions/FileDownload'
import { regexAddress, regexCedula, regexEmail, regexRif, regexRifRegister } from 'helper/RegExp'
import useWindowSize from 'helper/UseWindowsSize'
import { homeStyles } from '../../HomeStyles'
import ConfigUserApprovalListItem from './ConfigUserApprovalListItem'
import { getSupplierAll, getSupplierExcel, getSupplierSaintNoAsig, postActiveInactiveStatus, postApproved, postChangeEmail, postDeleteSupplier } from './ApiUserManagement'
import { boolOptionsModel, defaultValuesUserManagement, optionFilterUserManagement } from './UserManagementModels'
import ModalCommentReject from './ModalCommentReject'
import { codRifModel } from 'projectComponent/Register/RegisterModel'
import moment from 'moment'
import { LoaderContext } from 'context/Loader/LoaderContext'
import { userManagementLanguages } from './UserManagementLanguages'
import Loader from 'components/loader/Loader'
import useCheckIsAuthorized from 'hooks/useCheckIsAuthorized'
import ModalDeleteRecord from './ModalDeleteRecord'

const { filterOptionLang, txtErrorsRequiredLang, txtFiLang, textBusinessNameLang,
    textAddressLang, codePhoneLang, textContactPersonLang,
    txtSearchUserLang, textEmailLang, txtCommentLang, swithActiveLang, txtCodSupplierSaintLang } = userManagementLanguages

const UserManagement = () => {
    const { handleSubmit, register, watch, control, formState: { errors },
        getValues, setValue, reset, trigger, clearErrors } = useForm({
            defaultValues: defaultValuesUserManagement, mode: 'onChange'
        })
    const watchSearchUserApprovalList = watch('searchUserApprovalList')

    const { languageState } = useContext(LanguageContext)
    const { sbDispatch } = useContext(SnackbarContext)
    const { modalSearchDispatch: { fnOpenModalSearch } } = useContext(ModalSearchContext)
    const { isLoading, setIsLoading } = useContext(LoaderContext)

    const [usersList, setUsersList] = useState([])
    const [filterOption, setFilterOption] = useState('')
    const [helperTextSupplierSaint, setHelperTextSupplierSaint] = useState('')

    const [{ isDisabledForm, isOpenModalComment, isOpenModalDeleteRecord }, setBoolOptions] = useState(boolOptionsModel)
    const fnSetBoolOptions = (key, value) => setBoolOptions(preVal => ({ ...preVal, [key]: value ?? !preVal[key] }))

    const { paperHeightUserManagement, specialItemHeight } = useDinamicHeights(homeStyles)
    const { height } = useWindowSize()
    const fnForceLogout = useCheckIsAuthorized()

    const isDisabled = isLoading || isDisabledForm || (filterOption === '-2')
    const isReadOnlyAproved = isLoading || isDisabledForm || (filterOption === '1') || (filterOption === '-1') || (filterOption === '-3')

    const fnOnClickListUser = (data) => {
        try {
            for (const property in data) { setValue(property, data[property]) }
            [...data.fi].forEach(x => {
                if (codRifModel.find(z => z.id === x) !== undefined) {
                    setValue('codRif', codRifModel.find(z => z.id === x).id)
                    setValue('fi', data.fi.split(codRifModel.find(z => z.id === x).id)[1])
                }
            })
            setValue('status', data.validate === '1')
            fnSetBoolOptions('isDisabledForm', false)
        } catch (error) { console.error('Error en la fnOnClickListUser') }
    }

    const fnOnChangeFilterOption = (name, value) => {
        fnSetBoolOptions('isDisabledForm', true)
        setFilterOption(value)
    }

    const fnPostApproval = async (data) => {
        setIsLoading(true)
        try {
            if (data.aproved === false || helperTextSupplierSaint !== '') {
                const response = await postApproved(data)
                if (response.status === 401) {
                    setIsLoading(false)
                    return fnForceLogout(response)
                }
                if (response.data.errorCode === 0) {
                    setHelperTextSupplierSaint('')
                    reset()
                    fnSetBoolOptions('isDisabledForm', true)
                    fnGetSupplierAll()
                }
                sbDispatch.apiV2(response.status, response.data)
            } else {
                sbDispatch.warning('Debe asignar un codigo de proveedor SAINT')
            }

        } catch (error) {
            console.error('Error en el post', error)
            sbDispatch.error('No se a podido realizar el proceso aprobación')
        }
        setIsLoading(false)
    }
    const fnPostChangeEmail = async (data) => {
        setIsLoading(true)
        try {
            const response = await postChangeEmail(data)
            if (response.status === 401) {
                setIsLoading(false)
                return fnForceLogout(response)
            }
            reset()
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) {
            console.error('Error en el post ChangeEmail', error)
            sbDispatch.error('No se a podido realizar el reenvío de correo')
        }
        setIsLoading(false)
    }

    const fnPostActiveInactiveStatus = async (data) => {
        setIsLoading(true)
        try {
            const response = await postActiveInactiveStatus({ id: data.id, status: data.status })
            if (response.status === 401) {
                setIsLoading(false)
                return fnForceLogout(response)
            }
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) { console.error('Error en el post fnPostActiveInactiveStatus', error) }
        setIsLoading(false)
    }

    const fnOnSubmit = (formData) => {
        try {
            switch (filterOption) {
                case '-1':
                    fnPostApproval({ ...formData, aproved: true })
                    break;
                case '-2':
                    fnPostChangeEmail(formData)
                    break;
                case '1':
                    fnPostActiveInactiveStatus(formData)
                    break;
                default:
                    break;
            }
        } catch (error) { console.error('Error en funSubmit', error) }
    }

    const fnOnCallBackReject = (commentModal) => {
        try {
            fnPostApproval({
                ...getValues(), aproved: false, comment: commentModal
            })
            setHelperTextSupplierSaint('')
            reset()
        } catch (error) { console.error('error en el fnOnCallBackReject', error) }
    }

    const fnOpenModalCommentReject = () => {
        (filterOption === '-3')
            ? fnSetBoolOptions('isOpenModalDeleteRecord')
            : fnSetBoolOptions('isOpenModalComment')
    }

    const fnGetSupplierExcel = async () => {
        setIsLoading(true)
        try {
            const response = await getSupplierExcel()
            if (response.status === 401) {
                return fnForceLogout(response)
            }
            if (response.data.errorCode === 0) {
                fnDownloadFile(response.data.data, `Listado de proveedores ${moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}`);
            }
        } catch (error) { console.error('Catch de la función para imprimir el reporte de los provedores registrados', error) }
        setIsLoading(false)
    }

    const fnGetSupplierAll = async () => {
        setIsLoading(true)
        try {
            const response = await getSupplierAll()
            if (response.status === 401) {
                setIsLoading(false)
                return fnForceLogout(response)
            }
            if (response.status === 200 && response.data.errorCode === 0) {
                let newData = response.data.data.map(x => ({ ...x, validate: String(x.validate), uniqueKey: uuid() }))
                if (filterOption === '1') {
                    setUsersList(newData.filter(x => x.validate === '1' || x.validate === '0'))
                } else {
                    setUsersList(newData.filter(x => (filterOption === String(x.validate))))
                }
            } else { sbDispatch.apiV2(response.status, response.data) }
        } catch (error) { console.error('error en fnGetPendingAprov', error) }
        setIsLoading(false)
    }

    const fnOnCallBackModalSearch = (data) => {
        try {
            clearErrors('codSupplierSaint')
            setHelperTextSupplierSaint(data.name)
            setValue('codSupplierSaint', data.fi)
        } catch (error) { console.error('error en el catch fnOnCallBackModalSearch', error) }
    }

    const fnOnSearchSupplierSaint = () => fnOpenModalSearch('Proveedores', 'supplier', fnOnCallBackModalSearch)

    const fnOnKeyDownSupplierSaint = async (event) => {
        try {
            if (helperTextSupplierSaint !== '') setHelperTextSupplierSaint('')
            switch (event.key) {
                case 'Backspace':
                    setHelperTextSupplierSaint('')
                    break
                case 'Tab':
                    break
                case 'Enter':
                    setIsLoading(true)
                    try {
                        trigger('codSupplierSaint')
                        const response = await getSupplierSaintNoAsig()
                        if (response.status === 401) {
                            setIsLoading(false)
                            return fnForceLogout(response)
                        }
                        let newValue = response.data.data.find(x => x.fi === event.target.value)
                        if (newValue !== undefined) {
                            setValue('codSupplierSaint', newValue.fi)
                            setHelperTextSupplierSaint(newValue.descrip)
                        } else {
                            sbDispatch.error('El código de proveedor ingresado no existe')
                        }
                    } catch (error) {
                        sbDispatch.error('El código de proveedor ingresado no existe')
                        console.error('error onKeyDown-Enter', error)
                    }
                    setIsLoading(false)
                    break
                default:
                    break
            }
        } catch (error) { console.error('fnOnKeyDown => ', error) }
    }
    const fnPostDeleteSupplier = async () => {
        setIsLoading(true)
        try {
            const response = await postDeleteSupplier(getValues('id'))
            if (response.status === 200 && response.data.errorCode === 0) {
                fnSetBoolOptions('isDisabledForm', true)
                fnSetBoolOptions('isOpenModalDeleteRecord', false)
                fnGetSupplierAll()
                reset()
            }
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) { console.error('error en el catch fnGetEquis =>', error) }
        setIsLoading(false)
    }

    useEffect(() => {
        reset()
        setUsersList([])
        setHelperTextSupplierSaint('')
        fnGetSupplierAll()
    }, [filterOption])

    return (
        <form key={'formUserManagement'} onSubmit={handleSubmit(fnOnSubmit)} onKeyPress={(e) => (e.key === "Enter" && e.preventDefault())}>
            <TaPaper id={'paperUserApproval'}
                elevation={1} square={true}
                // height={paperHeightUserManagement.height}
                squareBorder={true}
                Content={
                    <Grid container spacing={2} justifyContent="center" >
                        <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
                            <Grid container direction={'row'} justifyContent='flex-start' alignItems={'flex-start'} spacing={1} >
                                <Grid item xs={9} sm={9} md={6} lg={6} xl={6} id={'txtFilterOption'} align={'left'} sx={{ marginTop: '10px', marginLeft: '5px' }}>
                                    <TaListTextField
                                        name={'filterOption'}
                                        label={filterOptionLang.label[languageState]}
                                        onChange={fnOnChangeFilterOption}
                                        value={filterOption}
                                        options={optionFilterUserManagement(languageState)}
                                    />
                                </Grid>
                                <Grid item xs={9} sm={9} md={6} lg={6} xl={6} align={'left'} sx={{ marginTop: '10px', marginLeft: '5px' }}>
                                    <TaTextField
                                        label={txtSearchUserLang[languageState]}
                                        register={register('searchUserApprovalList')}
                                        type={'search'}
                                        fullWidth={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TaList elevation={0} id={'usersApprovalList'} height={height - 290}>
                                        {
                                            usersList.filter(x => fnIncludeStrings(x, watchSearchUserApprovalList)).map(x => {
                                                return (<ConfigUserApprovalListItem key={x.uniqueKey} x={x}
                                                    isSelectedUser={x.uniqueKey === getValues('uniqueKey')} name={'usersList'}
                                                    onClickItem={fnOnClickListUser} />)
                                            })
                                        }
                                    </TaList>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
                            <Grid container direction={'row'} justifyContent="flex-start" alignItems={'center'} spacing={1} sx={{ paddingTop: '20px' }}>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <TaAdornedTextField
                                        name={'fi'}
                                        label={txtFiLang.label[languageState]}
                                        control={control}
                                        type={'number'}
                                        helperText={' '}
                                        disabled={isDisabled}
                                        readOnly={isReadOnlyAproved}
                                        error={errors['fi']?.message}
                                        adornment={<TaListTextField
                                            name={'codRif'}
                                            control={control}
                                            options={codRifModel}
                                            disabled={isDisabled}
                                            readOnly={isReadOnlyAproved}
                                            error={errors['codRif']?.message}
                                        />} />
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <TaTextField
                                        id={'txtBussinessName'}
                                        label={`${textBusinessNameLang.label[languageState]} * `}
                                        register={register('bussinessName', {
                                            required: txtErrorsRequiredLang[languageState],
                                        })}
                                        error={errors['bussinessName']?.message}
                                        helperText={' '}
                                        disabled={isDisabled}
                                        readOnly={isReadOnlyAproved}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <TaTextField
                                        id={'txtPhone'}
                                        label={codePhoneLang.label[languageState]}
                                        register={register('phone', {
                                            required: txtErrorsRequiredLang[languageState],
                                        })}
                                        error={errors['phone']?.message}
                                        helperText={' '}
                                        disabled={isDisabled}
                                        readOnly={isReadOnlyAproved}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <TaTextField
                                        id={'txtContact'}
                                        label={`${textContactPersonLang.label[languageState]} * `}
                                        register={register('contact', {
                                            required: txtErrorsRequiredLang[languageState],
                                        })}
                                        error={errors['contact']?.message}
                                        helperText={' '}
                                        disabled={isDisabled}
                                        readOnly={isReadOnlyAproved}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TaTextField
                                        id={'txtDirection'}
                                        label={`${textAddressLang.label[languageState]} * `}
                                        register={register('direction', {
                                            required: txtErrorsRequiredLang[languageState],
                                            pattern: regexAddress
                                        })}
                                        error={errors['direction']?.message}
                                        helperText={' '}
                                        disabled={isDisabled}
                                        readOnly={isReadOnlyAproved}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={filterOption === '-1' && specialItemHeight}>
                                    <TaTextField
                                        id={'txtEmail'}
                                        label={`${textEmailLang.label[languageState]} * `}
                                        register={register('email', {
                                            required: txtErrorsRequiredLang[languageState],
                                            pattern: regexEmail,
                                        })}
                                        error={errors['email']?.message}
                                        helperText={' '}
                                        disabled={isReadOnlyAproved ? isLoading || isDisabledForm : isLoading || isDisabledForm || filterOption !== '-2'}
                                        readOnly={isReadOnlyAproved}
                                    />
                                </Grid>
                                {(filterOption === '-3') && <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <TaTextField
                                        id={'txtComment'}
                                        label={txtCommentLang.label[languageState]}
                                        register={register('comment',)}
                                        error={errors['comment']?.message}
                                        helperText={' '}
                                        disabled={isLoading || isDisabledForm}
                                        readOnly={true}
                                    />
                                </Grid>}
                                {(filterOption === '1') && <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
                                    <TaSwitch
                                        id='swithActive'
                                        name={'status'}
                                        label={swithActiveLang[languageState]}
                                        control={control}
                                        disabled={isLoading || isDisabledForm}
                                    />
                                </Grid>}
                                {(filterOption === '-1') &&
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={specialItemHeight} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <TaTextField
                                                    id={'txtCodSupplierSaint'}
                                                    label={txtCodSupplierSaintLang.label[languageState]}
                                                    register={register('codSupplierSaint', {
                                                        required: txtErrorsRequiredLang[languageState]
                                                    })}
                                                    error={errors['codSupplierSaint']?.message}
                                                    helperText={helperTextSupplierSaint !== '' ? helperTextSupplierSaint : ' '}
                                                    disabled={isDisabled}
                                                    onKeyDown={fnOnKeyDownSupplierSaint}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <TaFab
                                                    id={'btnSearchSupplierSaint'}
                                                    icon={'search'}
                                                    disabled={isDisabled}
                                                    onClick={fnOnSearchSupplierSaint}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="right">
                            <TaFab icon={'cloudDownload'}
                                id={'printerproviders'}
                                onClick={fnGetSupplierExcel}
                                disabled={isLoading} />
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} align="right">
                            {(filterOption === '-1' || '-3') &&
                                <TaFab icon={'clear'}
                                    id={'removeUser'}
                                    color={'red'}
                                    disabled={isDisabled}
                                    onClick={fnOpenModalCommentReject}
                                />
                            }
                            {filterOption !== '-3' &&
                                <TaFab icon={'check'}
                                    id={'approveUser'}
                                    color={'green'}
                                    type={'submit'}
                                    disabled={isLoading || isDisabledForm} />}
                        </Grid>
                        <Loader isLoading={isLoading} />
                        {isOpenModalComment && <ModalCommentReject onClose={fnSetBoolOptions} onCallback={fnOnCallBackReject} />}
                        {isOpenModalDeleteRecord && <ModalDeleteRecord onClose={fnSetBoolOptions} onCallback={fnPostDeleteSupplier} />}
                    </Grid >
                }
            />
        </form >
    )
}
export default UserManagement