import Print from '@mui/icons-material/Print';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Toc from '@mui/icons-material/Toc';
import Key from '@mui/icons-material/VpnKey';
import RestoreIcon from '@mui/icons-material/Restore';
import CollectionsIcon from '@mui/icons-material/Collections';
import GridOnIcon from '@mui/icons-material/GridOn';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Sync from '@mui/icons-material/Sync';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AddLocation from '@mui/icons-material/AddLocation';
import GridOn from '@mui/icons-material/GridOn';
import Receipt from '@mui/icons-material/Receipt';
import MergeType from '@mui/icons-material/MergeType';
import CallMade from '@mui/icons-material/CallMade';
import OpenInNew from '@mui/icons-material/OpenInNew';
import LockOpen from '@mui/icons-material/LockOpen';
import NotInterested from '@mui/icons-material/NotInterested';
import Message from '@mui/icons-material/Message';
import Telegram from "@mui/icons-material/Telegram";
import MoneyIcon from '@mui/icons-material/Money';
import CommentIcon from '@mui/icons-material/Comment';
import File from '@mui/icons-material/Description'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Dehaze';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/SettingsSharp';
import Storage from '@mui/icons-material/Storage';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import Totalize from '@mui/icons-material/Done';
import Home from '@mui/icons-material/Home';
import Upload from '@mui/icons-material/Unarchive';
import CashIcon from '@mui/icons-material/MonetizationOn';
import CardIcon from '@mui/icons-material/CreditCard';
import List from '@mui/icons-material/ListAlt';
import Star from '@mui/icons-material/Star';
import FileCopy from '@mui/icons-material/FileCopy';
import SystemUpdate from '@mui/icons-material/SystemUpdate';
import CloudDownload from '@mui/icons-material/CloudDownload';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { ClearAll } from '@mui/icons-material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

//COMPONENTS
import EraseIcon from './icons/EraseIcon'
import EditImageIcon from './icons/EditImageIcon'
import ImageIcon from './icons/ImagesIcon'
import PercentIcon from './icons/PercentIcon'
import PriceVisorIcon from './icons/PriceVisorIcon'
import ExactPayment from './icons/ExactPayment'
import BarcodeScan from './icons/BarcodeScan'
import FE from './icons/FE'
import OneTileFilter from './icons/OneTileFilter'
import FourTileFilter from './icons/FourTileFilter'
import MultiTileFilter from './icons/MultiTileFilter'
import AccountCash from './icons/AccountCash'
import PrinterPos from './icons/PrinterPos'
import DownloadDoc from './icons/DownloadDocument'

export const iconType = (type) => {
  switch (type) {
      case "add":
          return <AddIcon />
      case "backArrow":
          return <ArrowBackIcon />
      case "forwardArrow":
          return <ArrowForwardIcon />
      case "clear":
      case "exit":
          return <ClearIcon />
      case "delete":
          return <DeleteIcon />
      case "done":
          return <DoneIcon />
      case "edit":
          return <EditIcon />
      case "erase":
          return <EraseIcon />
      case "info":
          return <InfoIcon />
      case "menu":
          return <MenuIcon />
      case "percent":
          return <PercentIcon />
      case "pricevisor":
          return <PriceVisorIcon />
      case "remove":
          return <RemoveIcon />
      case "save":
          return <SaveIcon />
      case "search":
          return <SearchIcon />
      case "setting":
          return <SettingsIcon />
      case "send":
          return <SendIcon />
      case "storage":
          return <Storage />
      case "arrow":
      case "totalarrow":
          return <ArrowIcon />
      case "total":
      case "check":
          return <Totalize />
      case "home":
          return <Home />
      case "upload":
          return <Upload />
      case "cash":
          return <CashIcon />
      case "card":
          return <CardIcon />
      case "list":
          return <List />
      case "favorite":
          return <Star />
      case "favoriteB":
      case "editFavsSelected":
          return <StarBorderOutlined />
      case "image":
          return <ImageIcon />
      case "editImage":
          return <EditImageIcon />
      case "editImageSelected":
          return <EditImageIcon />
      case "systemUpdate":
          return <SystemUpdate />
      case "cloudDownload":
          return <CloudDownload />
      case "cloudUpload":
          return <CloudUploadOutlinedIcon />
      case "exactPayment":
          return <ExactPayment />
      case "FileCopy":
          return <FileCopy />
      case "camera":
          return <PhotoCamera />
      case 'user':
          return <AccountCircle />
      case 'disableUser':
          return <AccountCircle />
      case 'printer':
          return <Print />
      case 'barcodeScan':
          return <BarcodeScan />
      case '...':
          return <MoreVertIcon />
      case 'visibility':
          return <Visibility />
      case 'visibilityOff':
          return <VisibilityOff />
      case 'oneTileFilter':
          return <OneTileFilter />
      case 'fourTileFilter':
          return <FourTileFilter />
      case 'multiTileFilter':
          return <MultiTileFilter />
      case 'multiSelect':
          return <Toc />
      case 'key':
          return <Key />
      case 'FE':
          return <FE />
      case 'accountCash':
          return <AccountCash />
      case 'printerPos':
          return <PrinterPos />
      case 'restore':
          return <RestoreIcon />
      case 'mapType':
          return <CollectionsIcon />
      case 'listMode':
          return <GridOnIcon />
      case 'mergeTables':
          return <LibraryAddIcon />
      case 'clearTable':
          return <DeleteSweepIcon />
      case 'sync':
          return <Sync />
      case 'disable':
          return <NotInterestedIcon />
      case 'downloadDoc':
          return <DownloadDoc />
      case 'addLocation':
          return <AddLocation />
      case 'polygon':
          return <GridOn />
      case "merge":
          return <MergeType />
      case "document":
          return <Receipt />
      case "moveDoc":
          return <CallMade />
      case "openTable":
          return <OpenInNew />
      case "freeTable":
      case "lockOpen":
          return <LockOpen />
      case "cancel":
          return <NotInterested />
      case "message":
          return <Message />
      case "telegram":
          return <Telegram />
      case 'money':
          return <MoneyIcon />
      case 'comment':
          return <CommentIcon />
      case 'file':
          return <File />
      case 'backupRestore':
          return <SettingsBackupRestoreIcon />
      case 'arrowUp':
          return <ArrowUpwardIcon />
      case 'arrowDown':
          return <ArrowDownwardIcon />
      case 'clearAll':
          return <ClearAll />
      default:
          return null
  }
}