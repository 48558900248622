import { createContext, useEffect, useState } from "react"
import { v4 as uuid } from 'uuid'

const MODALSEARCH_INITIALSTATE = {
  isOpenModalSearch: false,
  title: undefined,
  search: '',
  data: [],
  searchType: '',
  isHiddenExist: false,
  callback: undefined,
  isLoading: false,
}

const ModalSearchContext = createContext(MODALSEARCH_INITIALSTATE)

const ModalSearchProvider = ({ children }) => {

  const [modalSearchState, setModalSearchState] = useState(MODALSEARCH_INITIALSTATE)

  const fnReset = () => setModalSearchState({ ...MODALSEARCH_INITIALSTATE })

  const fnSetIsOpen = (title, searchType, callback) => setModalSearchState(preVal => ({
    ...preVal,
    title: title,
    searchType: searchType,
    callback: callback,
    isOpenModalSearch: true
  }))

  const fnSetData = (data) => setModalSearchState(preVal => ({ ...preVal, data: data.map((x, i) => ({ ...x,
    id:x.fi??x.id, 
    name:x.descrip??x.name, 
    uniqueKey: uuid() })) }))

  const modalSearchDispatch = {
    modalSearchReset: fnReset,
    fnOpenModalSearch: fnSetIsOpen,
    setModalSearchState: setModalSearchState,
    setModalSearchData: fnSetData
  }

  useEffect(() => fnReset(), [])

  return (
    <ModalSearchContext.Provider value={{ modalSearchState, modalSearchDispatch }}>
      {children}
    </ModalSearchContext.Provider >
  )
}

export { ModalSearchContext, ModalSearchProvider }
