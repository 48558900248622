export const regexCompanyId = {
    value: /^[A-Z0-9-]{3,25}$/i,
    message: "Id inválido"
}

export const regexDocumentId = {
    value: /^[0-9]{1,15}$/i,
    message: "Documento inválido"
}

export const regexCompanyName = {
    value: /^[A-Z0-9 -.,]{3,40}$/i,
    message: "Razon social inválida"
}

export const regexName = {
    value: /^[A-Z ]{2,40}$/i,
    message: "Nombre inválido"
}

export const regexLastName = {
    value: /^[A-Z ]{2,40}$/i,
    message: "Apellido inválido"
}

export const regexEmail = {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: "Correo inválido"
}

export const regexPass = {
    value: /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
    message: "Contraseña inválida, debe contener al menos un número, una letra en mayúscula y 6 caracteres"
}

export const regexSimplePass = {
    value: /^[0-9a-zA-Z]{6,}$/,
    message: "Contraseña inválida"
}

export const regexDate = {
    value: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
    message: "Fecha inválida"
}

export const regexPhone = {
    value: /^(\+\d{1,3}[- ]?)?[0]?\d{10}$/,
    message: "Teléfono inválido",
}

export const regexAddress = {
    value: /^[#.0-9a-zA-Z \s,-]{2,120}$/,
    message: "Dirección inválida",
}

export const regexEmail1 = {
    value: /^[A-Z0-9._%+-]{2,40}$/i,
    message: "Correo usuario inválido"
}

export const regexEmail2 = {
    value: /^[A-Z0-9.-]{2,30}\.[A-Z]{2,4}$/i,
    message: "Correo dominio inválido"
}

export const regexZipCode = {
    value: /^[0-9]{4}$/,
    message: "Código postal inválido",
}

export const regexPayAllPhone = {
    value: /^\d{7}$/,
    message: "Teléfono inválido",
}

export const regexPayAllPhoneTv = {
    value: /^\d{8}$/,
    message: "Teléfono inválido",
}

export const regexZNumber = {
    value: /^[0-9]{1,6}$/,
    message: "Número de Z inválido",
}

export const regexIP = {
    value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
    message: "Direccion IP inválido",
}

export const regexPort = {
    value: /^[0-9]{2,5}$/,
    message: "Puerto inválido",
}

export const regexVeryfCode={
    value: /^[0-9]{6}$/,
    message: "El código debe ser de 6 dígitos"
}

export const regexRif={
    value: /^[V|E|J|P|G][0-9]{9}$/,
    message: "Se debe ingresar un rif valido"  
}
export const regexCedula={
    value: /^[V][0-9]{7,10}$/,
    message: "Se debe ingresar una cédula o rif valido"  
}
export const regexRifRegister={
    value: /^[V|E|J|P|G][0-9]{9}$/,
    message: "Se debe ingresar un rif valido"  
}