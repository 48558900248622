import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Grid, } from '@mui/material/'
import { makeStyles } from '@mui/styles';

const loaderStyles = makeStyles({
    colorPrimary: {
        backgroundColor: 'var(--main-bg-color1)',
    },
    bar: {
        borderRadius: 5,
        backgroundColor: 'var(--main-bg-color3)',
    },
})

const Loader = memo(({ isLoading }) => {

    const classes = loaderStyles()

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id={'itemLoaderComponet'} sx={{height: '8px'}}>
            {isLoading && <LinearProgress classes={{colorPrimary: classes.colorPrimary, bar: classes.bar}} />}
        </Grid>
    );
});

Loader.propTypes = {
    isLoading: PropTypes.bool,
};

Loader.defaultProps = {
    isLoading: false
}

export default Loader;