import { Grid } from '@mui/material'
import TaFab from 'components/fab/TaFab'
import TaPaper from 'components/paper/TaPaper'
import TaListTextField from 'components/textfield/TaListTextField'
import TaTextField from 'components/textfield/TaTextField'
import TaTitle from 'components/title/TaTitle'
import { LanguageContext } from 'context/Language/LanguageContext'
import useDinamicHeights from 'helper/useDinamicHeights'
import { homeLanguages } from 'projectComponent/Home/HomeLanguages'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { homeStyles } from '../../HomeStyles'
import { getTransactions } from './ApiReportTransactions'
import { dataFilterModel, typeTransactionsModel } from './ReportTransactionsModel'
import TableReportTransaction from './TableReportTransaction'
import { v4 as uuid } from 'uuid'
import { SnackbarContext } from 'context/Snackbar/SnackbarContext'
import Loader from 'components/loader/Loader'
import { LoaderContext } from 'context/Loader/LoaderContext'
import useCheckIsAuthorized from 'hooks/useCheckIsAuthorized'

const { txtErrorsRequiredLang, txtDateIniLang, txtDateEndLang,
    txtTypeDocLang, txtNumDocLang, titleReportTransactionsLang, txtDescriptLang } = homeLanguages


const ReportTransactions = () => {
    const { handleSubmit, register, control, formState: { errors } } = useForm({
        defaultValues: dataFilterModel
    })

    const { languageState } = useContext(LanguageContext)
    const { sbDispatch } = useContext(SnackbarContext)
    const { isLoading, setIsLoading } = useContext(LoaderContext)
    const [dataReportTransList, setDataReportTransList] = useState([])

    const fnForceLogout = useCheckIsAuthorized()
    const { paperHeightUserManagement } = useDinamicHeights(homeStyles)

    const fnOnSubmit = async (data) => {
        setIsLoading(true)
        try {
            const response = await getTransactions(data)
            if (response.status === 401) {
                setIsLoading(false)
                return fnForceLogout(response)
            }
            if (response.status === 200 && response.data.errorCode === 0 && response.data.data !== null) {
                setDataReportTransList(response.data.data.map(x => ({ ...x, uniqueKey: uuid() })))
            } else {
                setDataReportTransList([])
                sbDispatch.warning(response.data.message)
            }
        } catch (error) { console.error('error en post del modal transactions', error) }
        setIsLoading(false)
    }

    return (
        <TaPaper id={'paperTransactions'}
            elevation={1} square={true}
            height={paperHeightUserManagement.height}
            squareBorder={true}
            Content={
                <>
                    <form key={'formReportTransactions'} onSubmit={handleSubmit(fnOnSubmit)} onKeyPress={(e) => (e.key === "Enter" && e.preventDefault())}>
                        <Grid container spacing={2} justifyContent="center" sx={{padding:'0px 0px 15px 0px'}}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <TaTitle titleName={titleReportTransactionsLang[languageState]} />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                                <TaTextField
                                    label={txtDateIniLang.label[languageState]}
                                    register={register('dateFrom', {
                                        required: txtErrorsRequiredLang[languageState]
                                    })}
                                    error={errors['dateFrom']?.message}
                                    type='date'
                                    helperText={' '}
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={9} sm={2} md={2} lg={2} xl={2} sx={{ minHeight: "65px" }}>
                                <TaTextField
                                    label={txtDateEndLang.label[languageState]}
                                    register={register('dateTo', {
                                        required: txtErrorsRequiredLang[languageState]
                                    })}
                                    error={errors['dateTo']?.message}
                                    type='date'
                                    helperText={' '}
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                                <TaTextField
                                    label={txtNumDocLang[languageState]}
                                    register={register('numDoc')}
                                    error={errors['numDoc']?.message}
                                    helperText={' '}
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                                <TaTextField
                                    label={txtDescriptLang[languageState]}
                                    register={register('descrip')}
                                    error={errors['descrip']?.message}
                                    helperText={' '}
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                                <TaListTextField
                                    name={'type'}
                                    label={txtTypeDocLang[languageState]}
                                    control={control}
                                    error={errors['type']?.message}
                                    options={typeTransactionsModel(languageState)}
                                    helperText={' '}
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={9} sm={1} md={1} lg={1} xl={1} align={'center'} sx={{ minHeight: "65px" }}>
                                <TaFab
                                    id={'btnSearchFilter'}
                                    icon={'search'}
                                    type={'submit'}
                                    disabled={isLoading}
                                />
                            </Grid>
                        </Grid>
                    </form >
                    <Grid container spacing={0} justifyContent="center" >
                        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                            <TableReportTransaction data={dataReportTransList}
                                setIsLoading={setIsLoading} isLoading={isLoading} languageState={languageState} />
                        </Grid>
                        <Loader isLoading={isLoading} />
                    </Grid>
                </>
            }
        />
    )
}

export default ReportTransactions