import { memo } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import TaTextField from './TaTextField'

const TaAdornedTextField = memo(({ name, label, control, fullWidth = true,
  error, type, disabled, autoFocus,
  placeholder = "", rules, adornment, helperText = ' ', adornmentPosition, readOnly= false}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => <TaTextField
        label={label}
        register={field}
        isAdorned={true}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        type={type}
        error={typeof error !== 'undefined' ? error.message : undefined}
        helperText={typeof error !== 'undefined' ? error.message : helperText}
        autoComplete={type === 'password' ? 'new-password' : 'off'}
        disabled={disabled}
        adornment={adornment}
        readOnly={readOnly}
        adornmentPosition={adornmentPosition}
      />
      }
    />
  )
})

TaAdornedTextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object,
  adornment: PropTypes.any,
  error: PropTypes.object,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default TaAdornedTextField