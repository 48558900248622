import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { helperTextStyles } from './TextField'
import moment from 'moment'
import { Stack, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { fnDefineTheme, fnDefineThemeLabel } from './ThemeDefinition'

const onFocus = (e) => {
  try {
    e.target.select()
  } catch (error) {
    // console.error('onFocus => ', error)
  }
}

const TaTextField = memo(({
  label = '', helperText = '', fullWidth = true, type = 'text',
  type2, disabled = false, autoFocus = false, placeholder = '',
  fontSize, color, error, register,
  select, rules, children, onKeyDown,
  adornment, maxLength, textAlign, adornmentPosition = '',
  id = '', customInputProps, customLowKeyInputProps, limitPastDate = '1900-01-01',
  name = '', value = '', onChange, readOnly = false,
  onBlur, errorIcon = false, theme = 'ordinary'
}) => {

  const [isOpenTooltip, setIsOpenTooltip] = useState(false)
  const textfieldClasses = fnDefineTheme(theme)
  const textfieldLabelClasses = fnDefineThemeLabel(theme)
  const helperTextClasses = helperTextStyles()

  const fnOnKeyDown = (event) => onKeyDown && onKeyDown(event)

  const fnOnBlur = (event) => onBlur && onBlur(event)

  const fnOnChange = (e) => onChange && onChange(e.target.name, e.target.value)

  if (register !== undefined) register = ({ ...register, onBlur: fnOnBlur })

  return (
    <>
      {register ? <TextField
        {...register}
        id={id}
        variant='standard'
        label={label}
        helperText={!errorIcon && typeof error === 'string' ? error : helperText}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        type={type}
        onBlur={onBlur && register.onBlur}
        autoComplete={type === 'password' ? 'new-password' : 'off'}
        disabled={disabled}
        SelectProps={(error !== undefined && errorIcon) ? { IconComponent: '' } : undefined}
        error={typeof (error) === 'string'}
        placeholder={placeholder}
        InputProps={(customInputProps !== undefined) ? customInputProps : {
          classes: { input: textfieldClasses.input, underline: textfieldClasses.underline },
          startAdornment: adornmentPosition !== 'end' && <Stack direction='row' spacing={1}>{adornment}</Stack>,
          endAdornment: errorIcon
            ? error !== undefined && <Tooltip open={isOpenTooltip} sx={{
              fontFamily: 'Archivo',
              fontSize: '11pt',
              color: 'var(--main-text-color0)'
            }} onClose={() => setIsOpenTooltip(false)} title={error ?? ''}>
              <InfoIcon
                onMouseEnter={() => setIsOpenTooltip(preVal => !preVal)}
                onClick={() => setIsOpenTooltip(preVal => !preVal)}
                sx={{ color: 'var(--main-bg-color9)' }}
              />
            </Tooltip>
            : adornmentPosition === 'end' && <div>{adornment}</div>,
        }}
        inputProps={customLowKeyInputProps
          ? customLowKeyInputProps
          : {
            maxLength: maxLength ?? 524288,
            min: type === 'number'
              ? '0'
              : type === 'date'
                ? limitPastDate
                : undefined,
            max: type === 'date'
              ? moment(new Date()).format('YYYY-MM-DD')
              : type2 === 'numberZip'
                ? '9999'
                : type2 === 'docId'
                  ? '9999999999'
                  : undefined,
            step: type === 'number'
              ? '1'
              : undefined,
            readOnly: readOnly,
          }}
        InputLabelProps={{
          classes: { root: textfieldLabelClasses.root, focused: textfieldLabelClasses.focused, },
          shrink: true,
        }}
        FormHelperTextProps={{
          classes: { root: helperTextClasses.root, disabled: helperTextClasses.disabled }
        }}
        select={select ?? false}
        rules={rules}
        onKeyDown={fnOnKeyDown}
      >
        {children}
      </TextField>
        : <TextField
          id={id}
          name={name}
          value={value}
          variant='standard'
          label={label}
          helperText={error ?? helperText}
          fullWidth={fullWidth}
          autoFocus={autoFocus}
          type={type}
          onBlur={onBlur && onBlur}
          autoComplete={type === 'password' ? 'new-password' : 'off'}
          disabled={disabled}
          onFocus={onFocus}
          error={typeof (error) === 'string'}
          placeholder={placeholder}
          onChange={onChange && fnOnChange}
          InputProps={(customInputProps !== undefined) ? customInputProps : {
            classes: { input: textfieldClasses.input, underline: textfieldClasses.underline },
            startAdornment: adornmentPosition !== 'end' && <Stack direction='row' spacing={1}>{adornment}</Stack>,
            endAdornment: errorIcon
              ? error !== undefined && <Tooltip open={isOpenTooltip} sx={{
                fontFamily: 'Archivo',
                fontSize: '11pt',
                color: 'var(--main-text-color0)'
              }} onClose={() => setIsOpenTooltip(false)} title={error ?? ''}>
                <InfoIcon
                  onMouseEnter={() => setIsOpenTooltip(preVal => !preVal)}
                  onClick={() => setIsOpenTooltip(preVal => !preVal)}
                  sx={{ color: 'var(--main-bg-color9)' }}
                />
              </Tooltip>
              : adornmentPosition === 'end' && <div>{adornment}</div>,
          }}
          inputProps={customLowKeyInputProps
            ? customLowKeyInputProps
            : {
              maxLength: maxLength ?? 524288,
              min: type === 'number'
                ? '0'
                : type === 'date'
                  ? limitPastDate
                  : undefined,
              max: type === 'date'
                ? moment(new Date()).format('YYYY-MM-DD')
                : type2 === 'numberZip'
                  ? '9999'
                  : type2 === 'docId'
                    ? '9999999999'
                    : undefined,
              step: type === 'number'
                ? '1'
                : undefined,
              readOnly: readOnly,
            }}
          InputLabelProps={{
            classes: { root: textfieldLabelClasses.root, focused: textfieldLabelClasses.focused, },
            shrink: true,
          }}
          FormHelperTextProps={{
            classes: { root: helperTextClasses.root, disabled: helperTextClasses.disabled }
          }}
          select={select ?? false}
          rules={rules}
          onKeyDown={onKeyDown && fnOnKeyDown}
        >
          {children}
        </TextField>
      }
    </>
  )
})

TaTextField.propTypes = {
  label: PropTypes.string,
  register: PropTypes.object,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default TaTextField 