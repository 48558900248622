import moment from "moment"

export const periodModel = [
    {
        id: moment().subtract(1, 'year').format('YYYY'),
        name: moment().subtract(1, 'year').format('YYYY')
    },
    {
        id: moment().subtract(2, 'year').format('YYYY'),
        name: moment().subtract(2, 'year').format('YYYY')
    },

]