import { LanguageContext } from 'context/Language/LanguageContext'
import { LoginContext } from 'context/Login/LoginContext'
import { useContext, useEffect, useState } from 'react'
import { homeLanguages } from '../HomeLanguages'

const { optArcLang, optTransactionsLang, optUserManagement } = homeLanguages

const fnHomeMenu = (languageState, isAdmin) => {
    return [
        {
            id: 1,
            name: optTransactionsLang[languageState],
            expand: false,
            nextPath: 'isReport',
            visible: (isAdmin !== 1),
        },
        {
            id: 2,
            name: optUserManagement[languageState],
            expand: false,
            nextPath: 'isUserManagement',
            visible: (isAdmin === 1),
        },
        {
            id: 0,
            name: optArcLang[languageState],
            expand: false,
            nextPath: 'isARCV',
            visible: (isAdmin !== 1),
        },
    ]
}




const useMenuButton = (location) => {
    const [menuArray, setMenuArray] = useState([])
    const { loginState: { isAdmin } } = useContext(LoginContext)
    const { languageState } = useContext(LanguageContext)

    useEffect(() => {
        let array = []
        switch (location) {
            case 'home':
                array = fnHomeMenu(languageState, isAdmin)
                break
            default:
                break
        }
        setMenuArray(array)
    }, [])
    return menuArray
}

export default useMenuButton