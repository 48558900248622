import { Grid } from '@mui/material'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import TaDialog from 'components/dialog/TaDialog'
import TaFab from 'components/fab/TaFab'
import TaListTextField from 'components/textfield/TaListTextField'
import { periodModel } from './ReportArcvModel'
import { SnackbarContext } from 'context/Snackbar/SnackbarContext'
import { getPDFARCVDocument } from './ApiReportArcv'
import { fnDownloadFile } from 'functions/Api'
import moment from 'moment'
import { LoaderContext } from 'context/Loader/LoaderContext'
import { homeLanguages } from 'projectComponent/Home/HomeLanguages'
import { LanguageContext } from 'context/Language/LanguageContext'
import useCheckIsAuthorized from 'hooks/useCheckIsAuthorized'


const { titleArcvModalLang, listFiscalPeriodLang } = homeLanguages

const ReportArcvModal = ({ onClose }) => {
    const { control, handleSubmit, formState: { errors }, watch } = useForm({ defaultValues: { fiscalPeriod: '' } })
    const watchFiscalPeriod= watch('fiscalPeriod')
    const { sbDispatch } = useContext(SnackbarContext)
    const { languageState } = useContext(LanguageContext)
    const { isLoading, setIsLoading } = useContext(LoaderContext)
    const fnForceLogout = useCheckIsAuthorized()

    const fnOnSubmitk = async (data) => {
        setIsLoading(true)
        try {
            const response = await getPDFARCVDocument(data)
            if (response.status === 401) {
                setIsLoading(false)
                return fnForceLogout(response)
            }
            if (response.data.errorCode === 0) {
                fnDownloadFile(response.data.data, `Reporte ARCV ${moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}`);
            }
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) { console.error('error en post del modal ARV', error) }
        setIsLoading(false)
    }
    return (
        <TaDialog
            isOpen={true}
            loading={isLoading}
            maxWidth={"xs"}
            title={titleArcvModalLang[languageState]}
            content={
                <form key={'formReportARCV'} onSubmit={handleSubmit(fnOnSubmitk)}>
                    <Grid container direction={'row'} justifyContent='center' alignItems={'center'} spacing={2} >
                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9} align={'left'} sx={{ marginTop: '10px', marginLeft: '5px' }}>
                            <TaListTextField
                                name={'fiscalPeriod'}
                                label={listFiscalPeriodLang.label[languageState]}
                                control={control}
                                error={errors['fiscalPeriod']?.message}
                                options={periodModel}
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9} align="right">
                            <TaFab
                                id='btnCloseModalARCV'
                                color='red'
                                icon="exit"
                                onClick={() => onClose('isARCV')}
                                disabled={isLoading} />
                            <TaFab
                                id='btnCloudDownloadArcv'
                                icon="cloudDownload"
                                type={'submit'}
                                disabled={isLoading||watchFiscalPeriod===''} />
                        </Grid>
                    </Grid>
                </form>

            }
        />
    )
}

export default ReportArcvModal