import { Grid, Paper } from '@mui/material'
import TaDoughnutChart from 'components/doughnutChart/TaDoughnutChart'
import TaLineChart from 'components/lineChart/TaLineChart'
import { useState, useEffect, useContext } from 'react'
import { getBalance, getPaymentsByMonth } from './ApiChartPanel'
import { SnackbarContext } from 'context/Snackbar/SnackbarContext'
import { LoaderContext } from 'context/Loader/LoaderContext'
import { LoginContext } from 'context/Login/LoginContext'

const ChartPanel = (({ selectedCurrency }) => {

  const { sbDispatch } = useContext(SnackbarContext)
  const { setIsLoading } = useContext(LoaderContext)
  const { loginState: { isAdmin } } = useContext(LoginContext)

  const [{ lineChartLabels, lineChartDataSet, doughtnutChartLabels,
    doughtnutChartDataSet, doughtnutChartHidden, lineData, doughnutData }, setData] = useState({
      lineChartLabels: [],
      lineChartDataSet: [],
      doughtnutChartLabels: [],
      doughtnutChartDataSet: [],
      doughtnutChartHidden: false,
      lineData: undefined,
      doughnutData: undefined
    })


  useEffect(() => {
    const fnGetPaymentByMonth = async () => {
      setIsLoading(true)
      try {
        let response
        response = await getPaymentsByMonth()
        if (response.status === 200 && response.data.errorCode === 0) {
          setData(preVal => ({
            ...preVal,
            lineData: response.data.data,
            lineChartLabels: response.data.data.indicatorsPurchases.map(x => x.date),
            lineChartDataSet: [
              {
                label: isAdmin ? 'Compras' : 'Ventas', // La etiqueta correspondiente a cada tipo de dato.
                data: response.data.data.indicatorsPurchases.map(x => selectedCurrency.defaultCurrency ? x.amount : x.amountCurrency), // NUMERIC ARRAY Para los valores de cada dataset  
                backgroundColor: '#2b334a',
                borderColor: '#44c0ec',
                borderWidth: 3,
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 8,
              },
              {
                label: isAdmin ? 'Pagos realizados' : 'Pagos Recibidos', // La etiqueta correspondiente a cada tipo de dato.
                data: response.data.data.indicatorsToPay.map(x => selectedCurrency.defaultCurrency ? x.amountToPay : x.amountCurrencyToPay), // NUMERIC ARRAY Para los valores de cada dataset  
                backgroundColor: '#2b334a',
                borderColor: '#31B700',
                borderWidth: 3,
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 8
              }
            ],
          }))
        }
        sbDispatch.apiV2(response.status, response.data)
        response = await getBalance()
        if (response.status === 200 && response.data.errorCode === 0) {
          let newDataArray = []
          for (const key in response.data.data) {
            if (!key.includes('Currency')) {
              newDataArray = [...newDataArray, response.data.data[key]]
            }
          }
          setData(preVal => ({
            ...preVal,
            doughnutData: response.data,
            doughtnutChartHidden: newDataArray.every(x => x <= 0),
            doughtnutChartLabels: ['Por vencer', '0 - 30', '31 - 60', '61 - 90', '90 + '],
            doughtnutChartDataSet: [
              {
                label: 'Saldo', // La etiqueta correspondiente a cada tipo de dato.
                data: newDataArray, // NUMERIC ARRAY Para los valores de cada dataset  
                backgroundColor: ['#0258B6', '#3F4888', '#7C385B', '#B9282D', '#F71900'],
                borderColor: '#2b334a',
                borderWidth: 1,
                hoverOffset: 10
              },
            ],
          }))
        }
        sbDispatch.apiV2(response.status, response.data)
      } catch (error) {
        console.error('fnGetPaymentByMonth => ', error)
      }
      setIsLoading(false)
    }
    if (selectedCurrency !== undefined) {
      fnGetPaymentByMonth()
    }
  }, [selectedCurrency])

  return (
    <Grid container spacing={2} alignItems={'flex-start'} justifyContent={'flex-start'}>
      <Grid item xs={12} sm={12} md={12} lg={12} lx={12}>
        <TaLineChart
          id={'lineChartPaymentByMonth'}
          labels={lineChartLabels}
          datasets={lineChartDataSet}
          fontSize={12}
          height={60} />
      </Grid>
      <Grid item xs={5} sm={5} md={5} lg={5} lx={5} align="center" hidden={doughtnutChartHidden}>
        <Paper elevation={0} square={true}>
          <TaDoughnutChart
            id={'doughnutChart'}
            labels={doughtnutChartLabels}
            datasets={doughtnutChartDataSet}
            titleLabel={isAdmin ? 'Total a pagar' : 'Total a cobrar'}
            fontSize={12} />
        </Paper>
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7} lx={7} />
    </Grid>
  )
})

export default ChartPanel