import generalLanguages from "../../lang/Lang"


export const registerLanguages = {
    ...generalLanguages,
    txtRifLang: {
        ES: "RIF",
        EN: "RIF",
        CN: "RIF"
    },
    textBusinessNameLang: {
        label: {
            ES: "Razón Social",
            EN: "Company name",
            CN: "公司名"
        }
    },
    textAddressLang: {
        label: {
            ES: "Dirección",
            EN: "address",
            CN: "地址"
        },
    },
    textEmailLang: {
        label: {
            ES: "Correo electronico",
            EN: "Email",
            CN: "電子郵件"
        },
    },
    textVerifyEmailLang: {
        label: {
            ES: "Verificar correo electronico",
            EN: "Verify email",
            CN: "驗證郵件"
        },
        validate: {
            ES: "Los correos deben coincidir",
            EN: "Emails must match",
            CN: "電子郵件必須匹配"
        }
    },
    stateListLang: {
        label: {
            ES: "Estado",
            EN: "State",
            CN: "狀態"
        },
    },
    codePhoneLang: {
        label: {
            ES: "Telefono",
            EN: "Phone",
            CN: "電話"
        },
    },
    textContactPersonLang: {
        label: {
            ES: "Persona de contacto",
            EN: "Contact person",
            CN: "聯絡人"
        },
    },
    linkReturnLoginLang: {
        ES: "🡠 Volver al Inicio",
        EN: "🡠 Back to login",
        CN: "🡠 回到登入"
    },
    btnRegisterLang: {
        ES: "Registrarse",
        EN: "Sign in",
        CN: "登入"
    },
    modalTitleVerificationLang: {
        ES: "Verificación",
        EN: "Verification",
        CN: "確認"

    }

}