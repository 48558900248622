import { Grid, Paper } from '@mui/material'
import useWindowSize from 'helper/UseWindowsSize'
import { useContext, useState } from 'react'
import UserManagement from './component/UserManagement/UserManagement'
import { boolOptionsModel } from './HomeModels'
import FooterBar from './FooterBar'
import { LoginContext } from 'context/Login/LoginContext'
import useMenuButton from './hooks/useMenuButton'
import HeaderBar from 'projectComponent/HeaderBar/HeaderBar'
import TaMenuItem from 'components/menuitem/TaMenuItem'
import TaFab from 'components/fab/TaFab'
import ReportArcvModal from './component/ReportARCV/ReportArcvModal'
import ReportTransactions from './component/Transactions/ReportTransactions'
import { LoaderContext } from 'context/Loader/LoaderContext'
import { homeStyles } from './HomeStyles'
import useDinamicHeights from 'helper/useDinamicHeights'
import IndicatorPanel from './component/IndicatorPanel/IndicatorPanel'
import TaPaper from 'components/paper/TaPaper'
import usePriceButton from 'projectComponent/PriceButton/usePriceButton'
import ChartPanel from './component/ChartPanel'
import TaTypography from 'components/typography/TaTypography'

const HomeComponent = () => {
    const { loginState: { isAdmin }, loginDispatch: { loginReset } } = useContext(LoginContext)
    const { isLoading } = useContext(LoaderContext)

    const [{ isARCV, isReport,
        isUserManagement, isHome }, setBoolOptions] = useState(boolOptionsModel)

    const fnSetBoolOptions = (key, value) => setBoolOptions(preVal => ({
        ...boolOptionsModel, isHome: key === 'isHome' || key === 'isARCV', [key]: value ?? !preVal[key]
    }))

    const { height, width, isMobile } = useWindowSize()
    const optionsArray = useMenuButton('home')
    const { bodyHeight } = useDinamicHeights(homeStyles)
    const { PriceButton, selectedCurrency } = usePriceButton()
    const fnModule = () => (isHome) ? loginReset() : fnSetBoolOptions('isHome', true)

    const fnOnClickItem = (data) => fnSetBoolOptions(data.nextPath, true)
    return (
        <Paper id={'homePaperBase'} square={true} elevation={0} sx={{ height: height, backgroundColor: 'var(--main-bg-color1)' }}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent='flex-start' alignItems='flex-start'>
                    <HeaderBar
                        isHome={isHome}
                        isLoading={isLoading}
                        leftButton={<TaMenuItem
                            id={'btnHomeMenu'}
                            isDisabled={isLoading}
                            onClickOption={fnOnClickItem}
                            options={optionsArray}
                        />}
                        rightlinks={<TaFab id={'btnLogOut'} icon={'clear'} color={'red'} disabled={isLoading} onClick={fnModule} />}
                    />
                </Grid>
                {isHome &&
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TaPaper id={'homePaperProv'} elevation={0} squareBorder={true} backgroundColor={'var(--main-bg-color0)'} Content={
                                <Grid container spacing={2} justifyContent='center' alignItems={'flex-start'} sx={{ height: bodyHeight.height, paddingTop: '25px' }}>
                                    {/* <Grid item xs={2} sm={2} md={2} lg={2} xl={2} align='center'>
                                        <PriceButton />
                                    </Grid> */}
                                    <IndicatorPanel selectedCurrency={selectedCurrency} />
                                    <Grid item xs={11} sm={11} md={11} lg={11} lx={11}>
                                        <ChartPanel selectedCurrency={selectedCurrency} />
                                    </Grid>
                                </Grid>
                            } />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} lx={12} sx={{ backgroundColor: 'var(--main-bg-color0)' }} align={'right'} >
                            <TaTypography color={'var(--main-title-color0)'} fontSize={'10pt'} text={'Los montos expresados son en USD dólares americanos'} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} lx={12} sx={{ height: '90px' }}>
                            <FooterBar />
                        </Grid>
                    </>
                }
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id={'itemsHomeComponent'}>
                    {isAdmin && isUserManagement ? <UserManagement /> : null}
                    {isReport && <ReportTransactions />}
                </Grid>
                {isARCV && <ReportArcvModal onClose={fnSetBoolOptions} />}
            </Grid >
        </Paper >
    )
}

export default HomeComponent