import { AccountCircle } from "@mui/icons-material"
import { Grid, Paper } from "@mui/material"
import TaTypography from "components/typography/TaTypography"
import { memo, useContext } from "react"
import Clock from 'react-live-clock'
import ToolsPROv from 'projectImages/ToolsPROVwebLogo/v3.png'
import customerImage01 from 'projectImages/ToolsPROVweb/customerImage01.png'
import { LoginContext } from "context/Login/LoginContext"
import { Link } from "react-router-dom"
import { USER_PROFILE } from "constants/appRoutes"
import generalLanguages from "lang/Lang"
import { LanguageContext } from "context/Language/LanguageContext"

const { welcomeTitleLang } = generalLanguages
const HeaderBar = memo(({ leftButton, rightlinks, isHome, isLoading = false }) => {
  const { loginState: { bussinessName } } = useContext(LoginContext)
  const { languageState } = useContext(LanguageContext)
  return (
    <Paper sx={{ margin: "0px 0px 7px 0px", height: '67px' }} elevation={2} square={true}>
      <Grid container spacing={1} alignItems={'center'} sx={{ height: '67px' }}>
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1} align={'center'}>
          {leftButton && leftButton}
        </Grid>
        {/* <Grid item xs={2} sm={2} md={3} lg={2} xl={2} align={'center'} sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }}>
          <img alt={''} src={customerImage01} style={{ height: '55px', margin: '0px', padding: '0px' }} />
        </Grid> */}
        <Grid item xs={8} sm={8} md={7} lg={6} xl={5}>
          <Grid container spacing={0} alignContent={'center'} alignItems={'center'} sx={{ paddingTop: '5px' }}>
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1} align={'center'} >
              <AccountCircle sx={{ color: 'var(--main-bg-color3)' }} />
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={11} xl={11} >
              <TaTypography>
                <Link to={isLoading ? null : USER_PROFILE} style={{ color: 'var(--main-text-color0)' }} > {welcomeTitleLang[languageState]} {bussinessName} </Link>
              </TaTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} sm={3} md={3} lg={2} xl={2} align='left' sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
          <TaTypography align={'center'} color={'var(--main-text-color0)'} fontSize={'12pt'}> <Clock ticking={true} format={'DD/MM/YYYY h:mm:ssa'} /> </TaTypography>
        </Grid>
        <Grid item xs={2} sm={4} md={3} lg={2} xl={3} align={'center'} sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }}>
          <img alt={''} src={ToolsPROv} style={{ height: '55px', margin: '0px', padding: '0px' }} />
        </Grid>
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1} align='center'>
          {rightlinks && rightlinks}
        </Grid>
      </Grid>
    </Paper>
  )
})

export default HeaderBar
