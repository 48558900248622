export const stylesLogin = (height, isTablet, isMobile, width) => ({
    paperLoginStyle: {
        height: height - 50,
        overflow: 'auto',
        marginTop: '5%'
    },
    imgStyleLogin: {
        width: isMobile ? '75%' : '55%'
    }
})


export const defaultValuesLogin = {
    fi: '',
    pass: '',
}