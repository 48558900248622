import { Grid } from '@mui/material'
import TaImg from 'components/img/TaImg'
import TaPaper from 'components/paper/TaPaper'
import useDinamicHeights from 'helper/useDinamicHeights'
import useWindowSize from 'helper/UseWindowsSize'
import { stylesLogin } from 'projectComponent/Login/LoginModel'
import ToolsPROv from 'projectImages/ToolsPROVwebLogo/v1.png'
import { useHistory } from 'react-router-dom'

const EmailVerification = () => {
    const { imgStyleLogin } = useDinamicHeights(stylesLogin)
    const history = useHistory()
    const messageModal = history.location.state

    const { height } = useWindowSize()
    return (
        // <Grid container spacing={2} >
        < TaPaper id={'paperUserApproval'}
            elevation={0} square={true}
            centerDiv={true}
            Content={
                <Grid container direction={'row'} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                        <TaImg url={ToolsPROv} inStyle={imgStyleLogin} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} sx={{
                        height: '70px',
                        fontFamily: 'Poppins',
                        fontSize: "18pt",
                        justifyContent: "center",
                        display: 'flex',
                        alignItems: 'center',
                        color: "var(--main-text-color1)",
                        backgroundColor: 'var(--main-bg-color1)'
                    }}>
                        {messageModal}
                    </Grid>
                </Grid>

            }
        />
        //     </Grid>
        // </Grid >

    )
}

export default EmailVerification