import { fnGetUrl, fnPostUrl } from "functions/Api"

export const getValidateUser = (token) => {
    const apiRequest = {
        endpoint: `/api/ProvWeb/Register/validate?token=${token}`
    }
    return fnGetUrl(apiRequest)
}

export const postLogin = (data) => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/login',
        body: data,
    }
    return fnPostUrl(apiRequest)

}
