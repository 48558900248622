const generalLanguages = {
    txtErrorsRequiredLang: {
        ES: 'Campo requerido',
        EN: 'Required field',
        CN: '必填項目'
    },
    currencyListLang: {
        label: {
            ES: 'Moneda',
            EN: 'Currency',
            CN: '貨幣'
        },
    },
    welcomeTitleLang: {
        ES: 'Bienvenido',
        EN: 'Welcome',
        CN: '歡迎'
    },
    linkReturnLoginLang: {
        ES: "🡠 Volver al Inicio",
        EN: "🡠 Back to login",
        CN: "🡠 回到登入"
    },
    textPasswordLang: {
        label: {
            ES: 'Contraseña',
            EN: 'Password',
            CN: '密碼'
        },
        placeholder: {
            ES: 'Ingrese la contraseña',
            EN: 'Enter a password',
            CN: '輸入密碼',
        }
    },
    textVerifyPasswordLang: {
        label: {
            ES: "Verificar contraseña",
            EN: "Verify password",
            CN: "驗證密碼"
        },
        validate: {
            ES: "Las contraseñas deben coincidir",
            EN: "Passwords must match",
            CN: "密碼必須匹配"

        }
    },
    listUserLvlLang: {
        label: {
            ES: 'Seleccione el nivel de usuario',
            EN: 'Select an user level',
            CN: '選擇用戶級別'
        },
    },
    textNameLang: {
        label: {
            ES: "Nombre",
            EN: "Name",
            CN: "姓名"
        },
    },
    txtFiLang: {
        label: {
            ES: "Rif*",
            EN: "Fiscal id*",
            CN: "會計編號*"
        },
        helpertext: {
            ES: "Ejm.J001424536",
            EN: "Exp.J001424536",
            CN: "例子.J001424536"
        }
    },
    textUserLang: {
        label: {
            ES: 'Usuario',
            EN: 'User',
            CN: '用户',
        },
        placeholder: {
            ES: 'Ingrese el usuario',
            EN: 'Enter an user',
            CN: '輸入用戶',
        },
        helperText: {
            ES: 'Ejemplo: Usuario@Alias',
            EN: 'Example: User@Alias',
            CN: '示例：用戶@別名',
        }
    },
    txtDateLang: {
        label: {
            ES: 'Fecha',
            EN: 'Date',
            CN: '截止日期',

        },
    },
    txtDateIniLang: {
        label: {
            ES: 'Fecha desde',
            EN: 'Since date',
            CN: '自日期',

        },
    },
    txtDateEndLang: {
        label: {
            ES: 'Fecha hasta',
            EN: 'Until date',
            CN: '截止日期',

        },
    },
    txtIniTimeLang: {
        label: {
            ES: 'Hora de inicio',
            EN: 'Start time',
            CN: '开始时间',

        },
    },
    txtEndTimeLang: {
        label: {
            ES: 'Hora final',
            EN: 'Final hour',
            CN: '最后一小时',

        },
    },
    txtCustomerLang: {
        label: {
            ES: 'Cliente',
            EN: 'Customer',
            CN: '顧客',
        },
        placeholder: {
            ES: 'Ingrese un cliente',
            EN: 'Enter a customer',
            CN: '輸入客戶',
        },
    },
    txtDepositLang: {
        label: {
            ES: 'Deposito',
            EN: 'Storage',
            CN: '訂金'
        },
        placeholder: {
            ES: 'Ingrese un deposito',
            EN: 'Enter a storage',
            CN: '输入一个存储',
        }
    },
    txtSellerLang: {
        label: {
            ES: 'Vendedor',
            EN: 'Seller',
            CN: '卖方'
        },
        placeholder: {
            ES: 'Ingrese un vendedor',
            EN: 'Enter a seller',
            CN: '输入卖家',
        }
    },
    txtOperationTypeLang: {
        label: {
            ES: 'Operación',
            EN: 'Operation',
            CN: '手術'
        },
        placeholder: {
            ES: 'Ingrese un codigo',
            EN: 'Enter an operation id',
            CN: '输入操作 ID',
        }
    },
    authorizeReturnHomeLang: {
        ES: '¿Desea regresar al Home?',
        EN: 'Want to return to Home?',
        CN: '想回家吗？',
    },
    authorizeReturnSalesLang: {
        ES: '¿Desea regresar a Ventas?',
        EN: 'Do you want to return to Sales?',
        CN: '您想返回銷售嗎',
    },
    printerLang: {
        ES: 'Impresora',
        EN: 'Printer',
        CN: '打印機'
    },
    formatLang: {
        ES: 'Formatos',
        EN: 'Formats',
        CN: '版式'
    },
    errorCatchApiLang: {
        ES: 'Error no controlado obteniendo información del servicio',
        EN: 'Unhandled error getting information from service',
        CN: '從服務獲取信息的未處理錯誤'
    },
    lblActiveLang: {
        ES: 'Activo',
        EN: 'Active',
        CN: '積極的'
    },
    ipListLang: {
        ES: 'Seleccione su conexión',
        EN: 'Select your connection',
        CN: '選擇你的連接'
    },
    selectAllLang: {
        ES: 'Seleccionar todos',
        EN: 'Select all',
        CN: '全选'
    },
    titleModalVerifyLang: {
        ES: "Verificación",
        EN: "Verification",
        CN: "確認"
    },
    searchLang: {
        ES: 'Buscar',
        EN: 'Search',
        CN: '搜索'
    },
    itemSearchLang: {
        ES: 'Buscar productos',
        EN: 'Search items',
        CN: '搜索项目'
    },
    txtSearchUserLang: {
        label: {
            ES: 'Buscar usuario',
            EN: 'Search user',
            CN: '搜索用戶'
        },
    },
    dropZoneLang: {
        dropAreaLang: {
            ES: 'Arrastre su archivo aquí ...',
            EN: 'Drag your file here...',
            CN: '将您的文件拖到此处...'
        },
        dropSquareLang: {
            ES: 'Arraste y suelte el archivo aqui o clic aqui para seleccionarlo.',
            EN: 'Drag and drop the file here or click here to select it.',
            CN: '将文件拖放到此处或单击此处将其选中。',
        },
        dropZoneErrorsOutputLang: {
            header: {
                ES: 'Los siguiente valores ingresados son inválidos:',
                EN: 'The following entered values are invalid:',
                CN: '以下输入的值无效：',
            },
            excelSheet: {
                ES: 'Hoja:',
                EN: 'Sheet:',
                CN: '薄板:',
            },
            value: {
                ES: 'Valor:',
                EN: 'Value:',
                CN: '价值：',
            },
            column: {
                ES: 'en la columna',
                EN: 'in the column',
                CN: '在列',
            },
            line: {
                ES: 'en la fila',
                EN: 'in line',
                CN: '排队',
            },
            errorType: {
                ES: 'no coincide con el tipo de dato especificado',
                EN: 'does not match the specified data type',
                CN: '与指定的数据类型不匹配',
            }
        }
    },
    txtTypeDocLang: {
        ES: "Tipo",
        EN: "Type",
        CN: "類型"
    },
    typeDocsListLang: {
        A: {
            ES: 'Factura',
            EN: 'Invoice',
            CN: '發票'
        },
        B: {
            ES: 'Devolución',
            EN: 'Return',
            CN: '返回'
        },
        C: {
            ES: 'Nota de entrega',
            EN: 'Delivery note',
            CN: '送貨單'

        },
        D: {
            ES: 'Devolucion nota de entrega',
            EN: 'Return delivery note',
            CN: '退貨發貨單'
        },
        E: {
            ES: 'Pedido',
            EN: 'Order',
            CN: '命令'

        },
        F: {
            ES: 'Presupuesto',
            EN: 'Budget',
            CN: '預算'
        },
        G: {
            ES: 'Ficha',
            EN: 'Index Card',
            CN: '索引卡'
        }
    },
    txtQuantityLang: {
        label: {
            ES: 'Cantidad',
            EN: 'Quantity',
            CN: '数量',
        },
        errorMessage: {
            ES: 'La cantidad no puede ser inferior a cero',
            EN: 'The amount cannot be less than zero',
            CN: '金额不能小于零',
        }
    },
    txtExistLang: {
        label: {
            ES: 'Existencias',
            EN: 'stock',
            CN: '股票',
        },
        errorMessage: {
            ES: 'No permite superar el límite de existencia',
            EN: 'Does not allow to exceed the limit of existence',
            CN: '不允许超过存在的限度',
        }
    },
    txtPriceLang: {
        label: {
            ES: 'Precio',
            EN: 'Price',
            CN: '价钱'
        },
        errorMessage: {
            ES: 'No se permite agregar números negativos',
            EN: 'Adding negative numbers is not allowed',
            CN: '不允许添加负数',
        }
    },
    txtStationListLang: {
        label: {
            ES: "Estaciones",
            EN: "Work Stations",
            CN: "工作站",
        },
        helperText: {
            ES: "Seleccione una estación",
            EN: "Select a work station",
            CN: "選擇工作站",
        }
    },

}
export default generalLanguages
