import { makeStyles } from '@mui/styles';

export const textfieldStyles = makeStyles({
    input:
    {
        fontFamily: "Poppins",
        fontSize: "12pt",
        color: props => props.color ?? 'var(--main-text-color0)',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    underline: {
        '&$focused': {
            borderBottom: '1px solid var(--main-hover-color0)'
        },
        // '&:hover': {
        //     borderBottom: '1px solid var(--main-hover-color0)',
        // },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid var(--main-hover-color0)',
        },
        '&:before': {
            borderBottom:'2px solid var(--main-bg-color1)',
        },
        '&:after': {
            borderBottom: '2px solid var(--main-hover-color0)',
        }
    },
});

export const textfieldLabelStyles = makeStyles({
    root: {
        fontFamily: "Poppins",
        fontSize: "12pt",
        color: props => props.color ?? 'var(--main-text-color0)',
        '&:hover': {
            backgroundColor: 'transparent !important',
            color: props => props.color ?? 'var(--main-bg-color1)',
        },
        '&$focused': {
            color: 'var(--main-bg-color1)',
            margin: '0px !important',
        },
        '&$.Mui-error': {
            color: 'var(--main-bg-color9)'
        },
        textAlign: props => props.textAlign ?? 'left'
    },
    focused: {
        color: 'var(--main-bg-color9)',
        fontFamily: "Poppins",
        fontSize: "12pt",
        backgroundColor: 'transparent !important',
        '&$focused': {
            backgroundColor: 'transparent !important',
            color: props => props.color ?? 'var(--main-bg-color1)',
        },
        textAlign: props => props.textAlign ?? 'left'
    }
});

export const helperTextStyles = makeStyles({
    root: {
        color: 'var(--main-text-color2)',
        fontFamily: 'PoppinsMedium',
        fontWeight: 400,
        fontSize: "8pt",
        lineHeight: 1,
        letterSpacing: "0.03333em",
        textAlign: "left",
        marginTop: "3px",
        marginRight: "0",
        marginBottom: "0",
        marginLeft: "0"
    },
    disabled: {
        color: 'var(--main-text-color2) !important',
        fontFamily: 'PoppinsMedium',
        fontWeight: 400,
        fontSize: "8pt",
        lineHeight: 1,
        letterSpacing: "0.03333em",
        textAlign: "left",
        marginTop: "3px",
        marginRight: "0",
        marginBottom: "0",
        marginLeft: "0"
    },
})