import { useState, useEffect } from 'react';
// Hook
const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: false,
        isTablet: false,
        isPortrait: window.innerHeight > window.innerWidth,
    });

    useEffect(() => {
        const handleOnResize = () => {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                isMobile: window.innerWidth < 500,
                isTablet: (window.innerHeight >= 800 && window.innerHeight < 400) && (window.innerWidth > 500),
                isPortrait: window.innerHeight > window.innerWidth,
            });
        }

        // isPortrait: (window.innerHeight < window.innerWidth)

        // Add event listener
        window.addEventListener("resize", handleOnResize);

        // Call handler right away so state gets updated with initial window size
        handleOnResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleOnResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export default useWindowSize;
