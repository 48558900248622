import generalLanguages from "lang/Lang";
import { registerLanguages } from "projectComponent/Register/RegisterLanguages";

export const homeLanguages = {
    ...generalLanguages,
    ...registerLanguages,
    optArcLang: {
        ES: "ARCV",
        EN: "ARCV",
        CN: "ARCV",
    },
    optTransactionsLang: {
        ES: "Transacciones",
        EN: "Transactions",
        CN: "交易",
    },
    optUserManagement: {
        ES: "Gestión de usuarios",
        EN: "User Management",
        CN: "用戶管理",
    },
    titleUserApprovalLang: {
        ES: "Aprobación de usuario",
        EN: "User approval",
        CN: "用戶認可",
    },
    titlePendingUserValLang: {
        ES: "Usuario pendiente por validar",
        EN: "Pending user to validate",
        CN: "等待用戶驗證",
    },
    txtSearchUserLang: {
        ES: "Buscar usuario",
        EN: "Search user",
        CN: "搜索用戶",
    },
    txtNumDocLang: {
        ES: "Nro. Documento",
        EN: "Document number",
        CN: "文件號碼"
    },
    txtDescriptLang: {
        ES: "Descripción",
        EN: "Description",
        CN: "描述"
    },
    listFiscalPeriodLang: {
        label: {
            ES: "Periodo",
            EN: "Period",
            CN: "時期"
        },
    },
    titleArcvModalLang: {
        ES: "Imprimir Report ARC-V",
        EN: "Print ARC-V Report",
        CN: "打印 ARC-V 報告"
    },
    titleReportTransactionsLang: {
        ES: "Reporte de transacciones",
        EN: "Transaction report",
        CN: "交易報告"
    },
}
