import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const OneTileFilter = () => {
    return (
        <SvgIcon viewBox="0 0 6.1 6.1">
            <path 
                className="st0" 
                d="M6,6H0.1V0.1H6V6z M1.9,1.9v2.4h2.4V1.9H1.9z"/>
        </SvgIcon>
    )
}

export default OneTileFilter