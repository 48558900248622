export const defaultValueRegisterForm = {
    "fi": "",
    "bussinessName": "",
    "direction": "",
    "email": "",
    "phone": "",
    "contact": "",
    "password": "",
    "verifyEmail": "",
    "verifyPass": "",
    "codRif": ""
}


export const mockDataRegister = [
    {
        "fi": "234567891",
        "bussinessName": "verde",
        "direction": "Caracas",
        "email": "guaidotk@gmail.com",
        "phone": "2497960",
        "contact": "Katherin",
        "password": "Katy2496",
        "verifyEmail": "guaidotk@gmail.com",
        "verifyPass": "Katy2496",
        "codRif": "J"
    },
    {
        "fi": "765432189",
        "bussinessName": "naranja",
        "direction": "Caracas",
        "email": "guaidotk@gmail.com",
        "phone": "2497960",
        "contact": "Katherin",
        "password": "Katy2496",
        "verifyEmail": "guaidotk@gmail.com",
        "verifyPass": "Katy2496",
        "codRif": "J"
    },
    {
        "fi": "345634789",
        "bussinessName": "rojo",
        "direction": "Caracas",
        "email": "guaidotk@gmail.com",
        "phone": "2497960",
        "contact": "Katherin",
        "password": "Katy2496",
        "verifyEmail": "guaidotk@gmail.com",
        "verifyPass": "Katy2496",
        "codRif": "G"
    },
    {
        "fi": "187654328",
        "bussinessName": "azul",
        "direction": "Caracas",
        "email": "guaidotk@gmail.com",
        "phone": "2497960",
        "contact": "Katherin",
        "password": "Katy2496",
        "verifyEmail": "guaidotk@gmail.com",
        "verifyPass": "Katy2496",
        "codRif": "V"
    },
]

export const codRifModel = [
    {
        id: 'V',
        name: 'V'
    },
    {
        id: 'J',
        name: 'J'
    },
    {
        id: 'G',
        name: 'G'
    },
    {
        id: 'E',
        name: 'E'
    },
]


export const stateListModel = [
    {
        id: '1',
        name: 'Amazonas',
        codPhone: [{ id: '248', name: 'Amazonas' }],
    },
    {
        id: '2',
        name: 'Anzoátegui',
        codPhone: [
            {
                id: '282',
                name: '282',
            },
            {
                id: '283',
                name: '283'
            },
            {
                id: '285',
                name: '285'
            },
            {
                id: '292',
                name: '292',

            },
        ],
    },
    {
        id: '3',
        name: 'Apure',
        codPhone: [
            {
                id: '240',
                name: '240'
            },
            {
                id: '247',
                name: '247'
            },
            {
                id: '278',
                name: '278'
            },

        ],
    },
    {
        id: '4',
        name: 'Aragua',
        codPhone: [
            {
                id: '243',
                name: '243'
            },
            {
                id: '244',
                name: '244',

            },
            {
                id: '246',
                name: '246',

            },
        ]
    },
    {
        id: '5',
        name: 'Barinas',
        codPhone: [
            {
                id: '273',
                name: '273'
            },
            {
                id: '278',
                name: '278',

            },
        ]
    },
    {
        id: '6',
        name: 'Bolívar',
        codPhone: [
            {
                id: '284',
                name: '284'
            },
            {
                id: '285',
                name: '285',

            },
            {
                id: '288',
                name: '288',

            },
            {
                id: '289',
                name: '289',

            },
        ]
    },
    {
        id: '7',
        name: 'Carabobo',
        codPhone: [
            {
                id: '241',
                name: '241'
            },
            {
                id: '242',
                name: '242'

            },
            {
                id: '243',
                name: '243',
            },
            {
                id: '245',
                name: '245',
            },
            {
                id: '249',
                name: '249',
            },
        ]
    },
    {
        id: '8',
        name: 'Cojedes',
        codPhone: [{ id: '258', name: '258' }],
    },
    {
        id: '9',
        name: 'Delta Amacuro',
        codPhone: [{ id: '287', name: '287' }],
    },
    {
        id: '10',
        name: 'Distrito Capital',
        codPhone: [{ id: '212', name: '212' }],
    },
    {
        id: '11',
        name: 'Falcón',
        codPhone: [
            {
                id: '259',
                name: '259'
            },
            {
                id: '268',
                name: '268'
            },
            {
                id: '269',
                name: '269',
            },
            {
                id: '279',
                name: '279',

            },
        ],
    },
    {
        id: '12',
        name: 'Guárico',
        codPhone: [
            {
                id: '235',
                name: '235',
            },
            {
                id: '238',
                name: '238',
            },
            {
                id: '246',
                name: '246',
            },
            {
                id: '247',
                name: '247',

            },
        ]
    },
    {
        id: '13',
        name: 'Lara',
        codPhone: [
            {
                id: '251',
                name: '251',
            },
            {
                id: '252',
                name: '252',

            },
            {
                id: '253',
                name: '253',

            },
        ]

    },
    {
        id: '14',
        name: 'Mérida',
        codPhone: [
            {
                id: '271',
                name: '271',
            },
            {
                id: '274',
                name: '274',

            },
            {
                id: '275',
                name: '275',

            },
        ]
    },
    {
        id: '15',
        name: 'Miranda',
        codPhone: [
            {
                id: '212',
                name: '212',
            },
            {
                id: '234',
                name: '234',
            },
            {
                id: '236',
                name: '236',
            },
            {
                id: '239',
                name: '239',

            },
            // {
            //     id: '243',
            //     name: ''
            // },
        ],
    },
    {
        id: '16',
        name: 'Monagas',
        codPhone: [
            {
                id: '287',
                name: '287',
            },
            {
                id: '291',
                name: '291',
            },
            {
                id: '292',
                name: '292',

            },
        ],

    },
    {
        id: '17',
        name: 'Nueva Esparta',
        codPhone: [
            {
                id: '295',
                name: '295',
            }
        ]
    },
    {
        id: '18',
        name: 'Portuguesa',
        codPhone: [
            {
                id: '255',
                name: '255',
            },
            {
                id: '256',
                name: '256',
            },
            {
                id: '257',
                name: '257',

            },
            {
                id: '272',
                name: '272',

            },
        ],
    },
    {
        id: '19',
        name: 'Sucre',
        codPhone: [
            {
                id: '293',
                name: '293',
            },
            {
                id: '294',
                name: '294',
            },
        ],
    },
    {
        id: '20',
        name: 'Táchira',
        codPhone: [
            {
                id: '275',
                name: '275',
            },
            {
                id: '276',
                name: '276',
            },
            {
                id: '277',
                name: '277',
            },
            {
                id: '278',
                name: '278',
            },
        ],
    },
    {
        id: '21',
        name: 'Trujillo',
        codPhone: [
            {
                id: '271',
                name: '271',
            },
            {
                id: '272',
                name: '272',
            },
        ],
    },
    {
        id: '22',
        name: 'La Guaira',
        codPhone: [{ id: '212', name: '212', }],
    },
    {
        id: '23',
        name: 'Yaracuy',
        codPhone: [
            {
                id: '251',
                name: '251',
            },
            {
                id: '253',
                name: '253',
            },
            {
                id: '254',
                name: '254',
            },
        ],
    },
    {
        id: '24',
        name: 'Zulia',
        codPhone: [
            {
                id: '261',
                name: '261',
            },
            {
                id: '262',
                name: '262',
            },
            {
                id: '263',
                name: '263',
            },
            {
                id: '264',
                name: '264',
            },
            {
                id: '265',
                name: '265',
            },
            {
                id: '266',
                name: '266',
            },
            {
                id: '267',
                name: '267',
            },
            {
                id: '271',
                name: '271',
            },
            {
                id: '275',
                name: '275',
            },
        ],
    },

]
