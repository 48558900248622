import { fnPostUrl } from 'functions/Api'


export const postCommercialAlly = (allyCode) => {
    const apiRequest = {
        endpoint: `/api/ProvWeb/Login/CommercialAlly`,
        body: {allyCode:allyCode},
        useBasic: true,
    }
    return fnPostUrl(apiRequest);
}

export const postValidateAllyCode = (keyCode, allyCode) => {
    let requestData = {
        keyCode: keyCode,
        allyCode: allyCode,
    }
    const apiRequest = {
        endpoint: `/api/ProvWeb/Login/ValidateAllyCode`,
        body: requestData,
        useBasic: true,
    }
    return fnPostUrl(apiRequest);
}