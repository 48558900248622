import { Grid } from '@mui/material'
import { useForm } from "react-hook-form"
import TaTextField from '../../components/textfield/TaTextField'
import TaTypography from '../../components/typography/TaTypography'
import TaButton from '../../components/button/TaButton'
import TaAdornedTextField from '../../components/textfield/TaAdornedTextField'
import { useContext, useEffect, useState } from 'react'
import Loader from '../../components/loader/Loader'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link, useHistory } from 'react-router-dom'
import { EMAIL_VERIFICATION, HOME, RECOVER_PASSWORD, REGISTER } from '../../constants/appRoutes'
import { defaultValuesLogin } from './LoginModel'
import { StringParam, useQueryParam } from "use-query-params"
import { SnackbarContext } from 'context/Snackbar/SnackbarContext'
import { getValidateUser, postLogin } from './ApiLogin'
import { LanguageContext } from 'context/Language/LanguageContext'
import loginLanguages from './LoginLanguages'
import { LoginContext } from 'context/Login/LoginContext'
import { regexCedula, regexPass, regexRif } from 'helper/RegExp'
import { setBasicAuth } from 'functions/Api'
import { LoaderContext } from 'context/Loader/LoaderContext'
import CommercialAlly from 'projectComponent/CommercialAlly/CommercialAlly'

const { txtErrorsRequiredLang, txtFiLang, linkRecoverPasswordLang,
    txtRegisterLang, linkJoinNowLang, btnLoginLang, textPasswordLang, titleLanguageSelectLang } = loginLanguages
const LoginForm = () => {
    const { handleSubmit, register, control, formState: { errors }, setValue, getValues, trigger, watch } = useForm({
        defaultValues: defaultValuesLogin
    })
    const history = useHistory()
    const [token] = useQueryParam('token', StringParam)
    const [fi] = useQueryParam('fi', StringParam)

    const { loginDispatch: { setLogin } } = useContext(LoginContext)
    const { sbDispatch } = useContext(SnackbarContext)
    const { languageState } = useContext(LanguageContext)
    const { isLoading, setIsLoading } = useContext(LoaderContext)

    const [isOpenCommercialAlly, setIsOpenCommercialAlly] = useState(false)
    const fnSetIsOpenCommercialAlly = (value) => setIsOpenCommercialAlly(preVal => (value ?? !preVal))

    const [isVisibility, setIsVisibility] = useState(false)
    const fnIsVisibility = () => setIsVisibility(preVal => !preVal)

    const fnPostLogin = async (data) => {
        trigger()
        setIsLoading(true)
        try {
            const response = await postLogin(data)
            if (response.status === 200 && response.data.errorCode === 0) {
                setBasicAuth(response.data.data.tokenBasicAuth)
                setLogin(response.data.data)
                setIsLoading(false)
                return history.push(HOME)
            } else if (response.status === 200 && response.data.errorCode === -3) {
                fnSetIsOpenCommercialAlly(true)
            }
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) { console.error(' error en el fnPostLogin', error) }
        setIsLoading(false)
    }

    const fnGetValidateToken = async () => {
        setIsLoading(true)
        try {
            const response = await getValidateUser(token)
            if (response.data.data?.module === 0 && response.data.errorCode === 0) {
                setIsLoading(false)
                sbDispatch.apiV2(response.status, response.data)
                return history.push(RECOVER_PASSWORD, token)
            } else if (response.data.data?.module === 1) {
                return history.push(EMAIL_VERIFICATION, response.data.message)
            }
            sbDispatch.apiV2(response.status, response.data)
        } catch (error) { console.error('Error FnTokenValidate', error) }
        setIsLoading(false)
    }

    useEffect(() => {
        if (token !== undefined) { fnGetValidateToken() } else if (fi !== undefined) setValue('fi', fi)
    }, [])

    return (<>
        <form key={'WebLoginForm'} onSubmit={handleSubmit(fnPostLogin)} >
            <Grid container spacing={1} justifyContent="center" alignItems="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'} >
                    <TaTypography text={'Inicio de Sesión'} fontSize={'18pt'} fontFamily={'PoppinsMedium'} />
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9} xl={9} align={'center'} sx={{ marginTop: '20px' }}>
                    <TaTextField
                        id={'txtUserId'}
                        label={txtFiLang.label[languageState]}
                        register={register('fi', {
                            required: txtErrorsRequiredLang[languageState],
                            pattern: watch('fi')[0] === 'V' ? regexCedula : regexRif
                        })}
                        error={errors['fi']?.message}
                        disabled={isLoading}
                        helperText={'Ejm. J00000000'}
                    />
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9} xl={9} align={'center'}>
                    <TaAdornedTextField
                        id={'txtUserPassword'}
                        name={'pass'}
                        label={textPasswordLang.label[languageState]}
                        control={control}
                        type={(!isVisibility) ? 'password' : 'text'}
                        helperText={' '}
                        disabled={isLoading}
                        rules={{
                            required: txtErrorsRequiredLang[languageState],
                            pattern: regexPass
                        }}
                        error={errors['pass']?.message}
                        adornmentPosition={'end'}
                        adornment={(!isVisibility)
                            ? <VisibilityOffIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => !isLoading ? fnIsVisibility() : ''} />
                            : <VisibilityIcon sx={{ color: 'var(--main-bg-color1)', marginRight: '5px' }} onClick={() => !isLoading ? fnIsVisibility() : ''} />
                        } />
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5} xl={5} align={'center'}>
                    <TaTypography fontSize={'9pt'}>{`${txtRegisterLang[languageState]} `}
                        <Link style={{color: 'var(--main-text-color2)'}} to={isLoading ? null : REGISTER} underline="hover" > {linkJoinNowLang[languageState]} </Link>
                    </TaTypography>
                </Grid>
                <Grid item xs={7} sm={7} md={7} lg={7} xl={7} align={'center'}>
                    <TaTypography fontSize={'9pt'}>
                        <Link  style={{color: 'var(--main-text-color2)'}} to={isLoading ? null : RECOVER_PASSWORD} underline="hover" > {linkRecoverPasswordLang[languageState]} </Link>
                    </TaTypography>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} />
                <Grid item xs={9} sm={9} md={9} lg={9} xl={9} align={'center'} >
                    <TaButton label={btnLoginLang[languageState]} type={'submit'} size={'small'} fontSize={'10pt'} disabled={isLoading} />
                </Grid>
                <Loader isLoading={isLoading} />
            </Grid>
        </form >
        {isOpenCommercialAlly && <CommercialAlly onClose={fnSetIsOpenCommercialAlly} />}
    </>
    )
}

export default LoginForm