import { memo } from 'react'
import { List, Paper } from '@mui/material'

const TaList = memo(({ id, children, elevation, height, backgroundColor }) => {

  return (
    <Paper id={`paper:${id}`} elevation={elevation} square={true} sx={{ height: height && height, backgroundColor: backgroundColor && backgroundColor, padding: '5px, 15px, 5px, 5px', overflow: 'auto', overflowX: 'hidden' }}>
      <List id={id} component='nav'>
        {children}
      </List>
    </Paper>
  )
})

export default TaList