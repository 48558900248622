import { memo } from "react";
import PropTypes from 'prop-types'

//MUI
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'

const buttonStyles = makeStyles({
    root: {
        margin: props=> props.topMargin ? '12px' : '5px',
        minWidth:props=> props.minWidth?  '100px' :'125px',
        background: props=> props.background??'var(--main-bg-color3)',
        color: 'white',
        '&:hover': {
            background: props=> props.color ? props.color : 'var(--main-bg-color3)',
        },
        borderRadius: props=> props.borderRadius?? "5px",
        fontFamily: "Poppins",
        fontSize: props=> props.fontSize ? props.fontSize : '12pt',
    }
})

const TaButton = memo(({
    id, label, disabled, onClick,
    size, variant, topMargin, index,
    uniqueKey, color, fontSize, type, href, target,minWidth, background, borderRadius
}) => {
    const fnOnClick = () => (onClick) && onClick(id, uniqueKey)
    const buttonClasses = buttonStyles({ topMargin, minWidth, color, fontSize, background, borderRadius })
    return (
        <Button
            id={id}
            variant={variant}
            disabled={disabled}
            onClick={fnOnClick}
            size={size}
            classes={buttonClasses}
            data-id={index}
            type={type ?? type}
            href={href}
            target={target}>
            {label}
        </Button>
    )
})

TaButton.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    size: PropTypes.string,
    loading: PropTypes.bool,
    topMargin: PropTypes.bool,
    minWidth: PropTypes.bool,
    setRef: PropTypes.func,
    hidden: PropTypes.bool,
    hotkey: PropTypes.string,
}

TaButton.defaultProps = {
    disabled: false,
    variant: "contained",
    size: "medium",
    loading: false,
    topMargin: true,
    minWidth: true,
    hidden: false,
    hotkey: "",
    onClick: () => { },
}

export default TaButton