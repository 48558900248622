import { Grid } from '@mui/material'
import TaListItem from 'components/list/TaListItem'

const ConfigUserApprovalListItem = ({ x, isSelectedUser, onClickItem }) => {
    return (
        <TaListItem selected={isSelectedUser} onClickItem={() => onClickItem(x)}>
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>{`${x.fi} - ${x.bussinessName}`}</Grid>
            </Grid>
        </TaListItem>
    )
}

export default ConfigUserApprovalListItem