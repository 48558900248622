import { Grid } from '@mui/material'
import TaDialog from 'components/dialog/TaDialog'
import TaFab from 'components/fab/TaFab'
import TaTextField from 'components/textfield/TaTextField'
import { useState } from 'react'


const ModalCommentReject = ({ onClose, onCallback }) => {
    const [commentReject, setCommentReject] = useState('')

    const fnOnChangeComment = (name, value) => {
        setCommentReject(value)
    }

    const fnOnCallback = () => {
        onCallback(commentReject)
        onClose('isOpenModalComment', false)
    }

    return (
        <TaDialog
            isOpen={true}
            maxWidth={"xs"}
            title={'Comentario de Rechazo'}
            content={
                <Grid container alignItems='center' alignContent='flex-start' spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TaTextField
                            name
                            label={`Motivo del rechazo`}
                            value={commentReject}
                            helperText={' '}
                            onChange={fnOnChangeComment}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                        <TaFab
                            id='btnCloseModalComment'
                            color='red'
                            icon="exit"
                            onClick={() => onClose('isOpenModalComment')}
                            />
                        <TaFab
                            id='btnSaveCommentReject'
                            icon="save"
                            onClick={fnOnCallback}
                        />
                    </Grid>
                </Grid>
            }
        />

    )
}

export default ModalCommentReject