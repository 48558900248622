export const initialStateModel = {
    id: "",
    fi:"",
    bussinessName: "",
    direction: "",
    email: "",
    contact: "",
    phone: "",
    validate: 0,
    codSupplierSaint: "",
    isAdmin: 1,
    comment: ""
}