import { ExpandLess } from '@mui/icons-material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Collapse, Popover } from '@mui/material'
import TaBadge from 'components/badge/TaBadge'
import TaFab from 'components/fab/TaFab'
import TaList from 'components/list/TaList'
import TaListButton from 'components/list/TaListButton'
import { memo } from 'react'
import { useState, Fragment, useEffect } from 'react'
import { v4 as uuid } from 'uuid'

const TaMenuItem = memo(({
  id, options = [], onClickOption, isDisabled = false,
  invisibleHotkey=true, onClickConfig,
}) => {

  const [ anchorEl, setAnchorEl ] = useState(null)
  const [ menuOptions, setMenuOptions ] = useState([])
  const [ selectedId, setSelectedId ] = useState('')
  const fnOnClose = () => {
    setAnchorEl(null)
    setSelectedId('')
    setMenuOptions(preVal => preVal.map(x => ({ ...x, isExpanded: false })))
  }

  const fnOnClickMenu = (elementId, uniqueKey, event) => {
    if (invisibleHotkey) {
      let currentTarget = event === undefined ? document.getElementById(id) : event.currentTarget
      return setAnchorEl(currentTarget)
    }
    if (onClickConfig !== undefined) {
      onClickConfig()
    }
  }

  const fnOnClickItem = (data, event, isExpandable) => {
    setSelectedId(data.uniqueKey)
    setMenuOptions(preVal => preVal.map(x => x.uniqueKey === data.uniqueKey ? ({ ...x, isExpanded: !x.isExpanded }) : ({ ...x, isExpanded: false })))
    if (onClickOption !== undefined && !isExpandable) {
      setAnchorEl(null)
      onClickOption(data)
    }
  }

  useEffect(() => (options.length > 0) && setMenuOptions(options.map(x => ({ ...x, uniqueKey: uuid() })).filter(x => x.visible === true)), [ options ])

  return <>
    <TaBadge invisible={invisibleHotkey}   >
      <TaFab
        id={id}
        icon={'menu'}
        color={'lightBlue'}
        aria-controls={`list${id}`}
        aria-haspopup={'true'}
        disabled={isDisabled}
        onClick={fnOnClickMenu} />
    </TaBadge>
    <Popover
      id={`listMenu${id} `}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={fnOnClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <TaList id={'menuItemList'} elevation={0} backgroundColor={'var(--main-list-color0)'} >
        {menuOptions.map(x => (
          <Fragment key={x.uniqueKey}>
            <TaListButton
              key={x.uniqueKey}
              fnOnClickItem={(e) => fnOnClickItem(x, e, (x.expand && x.expand === true))}
              selected={(selectedId === x.uniqueKey)}
              customColor={'var(--main-list-color0)'}>
              {x.name}
              {(x.expand) ? (x.isExpanded) ? <ExpandLess /> : <ExpandMore /> : ''}
            </TaListButton>
            {(x.isExpanded) && <Collapse key={`expan${x.uniqueKey}`} in={x.isExpanded} timeout={'auto'}>
              {x.subMenu && <TaList key={`list${x.uniqueKey}`} id={'subMenuItemId'} elevation={0} backgroundColor={'var(--main-list-color0)'} >
                {x.subMenu.map(s => (<TaListButton
                  key={s.id}
                  data={s}
                  fnOnClickItem={(e) => fnOnClickItem(s, e, false)}
                  subMenu={true}
                  customColor={'var(--main-list-color0)'}>
                  {s.name}
                </TaListButton>))}
              </TaList>}
            </Collapse>}
          </Fragment>
        ))}
      </TaList>
    </Popover>
  </>
})

export default TaMenuItem