// /api/ProvWeb/Supplier/CurrencyList

import { fnGetUrl } from "functions/Api"

export const getCurrencyList = () => {
    const apiRequest = {
        endpoint: '/api/ProvWeb/Supplier/CurrencyList',
        useBasic: true
    }
    return fnGetUrl(apiRequest)
}
