import { saveAs } from 'file-saver'
import { fnGetBaseUrl } from './Api'

export const fnDownloadFile = (url, name,) => {
    try {
        const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
        const isIOs = ((/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (platform === 'MacIntel' && navigator.maxTouchPoints > 1))
        if (isIOs === false) saveAs(`${fnGetBaseUrl()}${url}`, name)
    } catch (error) {
        console.error('fnDownloadFile => ', error)
    }
}

export default fnDownloadFile