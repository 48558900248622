import { fnCalculateHeights } from "helper/fnCalculateHeights";

export const loginStylesRegisterUser = (height) => {

    return {
        registerPaperStyles: {
            height: fnCalculateHeights([height, 82]),
            // overflow: 'auto'
        },
        registerContainerStyles:{
            height: height,
            // backgroundImage: url("C:/Users/DESARROLLO07/toolsprovwebapp/src/projectImages/ToolsPROVweb/background")
        }

    }
}